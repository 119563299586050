import {call, put, takeLatest} from "redux-saga/effects";
import {request} from "../../utils/api";
import {
    createDocumentFailure, createDocumentSuccess,
    deleteCompanyMemberFailure,
    deleteCompanyMemberSuccess,
    getCompanyMemberDataFailure,
    getCompanyMemberDataSuccess,
    getCompanyMembersFailure,
    getCompanyMembersSuccess,
    postCompanyMemberFailure,
    postCompanyMemberSuccess, registerOwnerFailure, registerOwnerSuccess,
    updateCompanyMemberFailure,
    updateCompanyMemberSuccess,
} from "../actions";
import {
    CREATE_DOCUMENT_PENDING,
    DELETE_COMPANY_MEMBER_PENDING,
    GET_COMPANY_MEMBER_DATA_PENDING,
    GET_COMPANY_MEMBERS_PENDING, POST_COMPANY_MEMBER_PENDING, REGISTER_OWNER_PENDING,
    UPDATE_COMPANY_MEMBER_PENDING
} from "../actionTypes";
import {getCompany} from "utils/auth";



function* getCompanyMembersWorker() {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;
    try {
        const {
            data,
        } = yield call(
            request().get,
             `/business-information/company-member/${company_id}`,
        );
        yield put(getCompanyMembersSuccess(data))
    } catch (error) {
        yield put(getCompanyMembersFailure(error));
    }
}

export function* getCompanyMembers(){
    yield takeLatest(GET_COMPANY_MEMBERS_PENDING, getCompanyMembersWorker);
}


function* getCompanyMemberDataWorker({id}) {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;
    try {
        const {
            data,
        } = yield call(
            request().get,
            `/business-information/company-member/${company_id}/${id}`,
        );
        const item = {...data.item?.residential_address?.[0], ...data.item}
        delete item?.residential_address
        yield put(getCompanyMemberDataSuccess(item))
    } catch (error) {
        yield put(getCompanyMemberDataFailure(error));
    }
}

export function* getCompanyMemberData() {
    yield takeLatest(GET_COMPANY_MEMBER_DATA_PENDING, getCompanyMemberDataWorker);
}


function* updateCompanyMemberWorker({option}) {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;

    const {role, id, company_member_id, ...other} = option;
    const params = other?.first_name ? {...other, role} : {role}

    try {
        const {
            data,
        } = yield call(
            request().patch,
            `/business-information/company-member/${company_id}/${company_member_id}`,
            params
        );
        yield put(updateCompanyMemberSuccess(data))
    } catch (error) {
        yield put(updateCompanyMemberFailure(error));
    }
}

export function* updateCompanyMember(){
    yield takeLatest(UPDATE_COMPANY_MEMBER_PENDING, updateCompanyMemberWorker);
}


function* postCompanyMemberWorker({option}) {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;

    try {
        const {
            data,
        } = yield call(
            request().post,
            `/business-information/company-member`,
            {...option, company_id}
        );
        yield put(postCompanyMemberSuccess(data))
    } catch (error) {
        yield put(postCompanyMemberFailure(error));
    }
}

export function* postCompanyMember(){
    yield takeLatest(POST_COMPANY_MEMBER_PENDING, postCompanyMemberWorker);
}


function* deleteCompanyMemberWorker({id, role}) {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;

    try {
        const {
            data,
        } = yield call(
            request().delete,
            `/business-information/company-member/${company_id}/${id}`,
            { data: role }
        );
        yield put(deleteCompanyMemberSuccess({...data, id, ...role}))
    } catch (error) {
        yield put(deleteCompanyMemberFailure(error));
    }
}

export function* deleteCompanyMember(){
    yield takeLatest(DELETE_COMPANY_MEMBER_PENDING, deleteCompanyMemberWorker);
}

function* registerOwnerWorker({option}) {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;
    try {
        const {
            data,
        } = yield call(
            request().post,
            `auth/register-owner`,
            {...option, company_id}
        );
        yield put(registerOwnerSuccess(data))
    } catch (error) {
        const {data, status} = error?.response;
        yield put(registerOwnerFailure(data?.message, status));
    }
}

export function* registerOwner(){
    yield takeLatest(REGISTER_OWNER_PENDING, registerOwnerWorker);
}

function* createDocumentWorker({option}) {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;
    try {
        const {
            data,
        } = yield call(
            request().post,
            `business-information/company/document`,
            {...option, company_id}
        );
        yield put(createDocumentSuccess(data))
    } catch (error) {
        const {data:{message}} = error?.response;
        yield put(createDocumentFailure(message));
    }
}

export function* createDocument(){
    yield takeLatest(CREATE_DOCUMENT_PENDING, createDocumentWorker);
}

import {call,  put, select, takeLatest } from 'redux-saga/effects';
import {actions} from "./slice";
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import moment from "moment";
import { getCurrencyData } from 'store/selectors/currency';

const { accounts: {accounts}, transactions: {transaction} } = Endpoints;


export function* getTransactions(action) {
    const {account_type, page, size, queryParams} = action.payload;
    const searchParameters = new URLSearchParams(queryParams);
    const from_date = searchParameters.get('from_date');
    const to_date = searchParameters.get('to_date');

    if(from_date){
        searchParameters.set('from_date', moment(from_date+'T00:00:00').format('X'))
    }
    if(to_date){
        searchParameters.set('to_date', moment(to_date + 'T23:59:59').format('X'))

    }

    try {
        const {currency} = yield select(getCurrencyData);
        const {data} = yield call(request().get, transaction.url(account_type, page, size, searchParameters.toString(), currency));
        const groups = data?.items?.reduce((groups, transaction) => {
            const groupedDate = transaction.created_at;
            const date = moment(groupedDate).format('YYYY-MM-DD');
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(transaction);
            return groups;
        }, {});
       data.items = Object.keys(groups).map((date) => ({date, transactions: groups[date]}));
       if(data) {
        yield put(actions.getTransactionsSuccess(data));
       }
    } catch (err) {
        yield put(actions.getTransactionsError(err.message));
    }
}


export function* getDefaultAccount({payload}) {
    try {
        const {currency} = yield select(getCurrencyData);

        const {data} = yield call(request().get, accounts.url(payload.type, currency));
        if(data) {
            yield put(actions.getAccountSuccess(data.items));
        }
    } catch (err) {
        yield put(actions.getAccountError(err.message));
    }
}

export function* transactionSaga() {
    yield takeLatest(actions.getTransactions.type, getTransactions);
    yield takeLatest(actions.getAccount.type, getDefaultAccount);
}



import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas';
import reducer from "./reducers";

const initialState = {};
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
        reducer: persistedReducer,
        devTools: process.env.NODE_ENV !== 'production',
        initialState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
        }).concat(sagaMiddleware),
    }
);

sagaMiddleware.run(sagas);

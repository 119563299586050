import {
    CLEAR_MEMBERS_DATA, CLEAR_OWNER_DATA, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_PENDING, CREATE_DOCUMENT_SUCCESS,
    DELETE_COMPANY_MEMBER_FAILURE, DELETE_COMPANY_MEMBER_PENDING, DELETE_COMPANY_MEMBER_SUCCESS,
    GET_COMPANY_MEMBER_DATA_FAILURE, GET_COMPANY_MEMBER_DATA_PENDING, GET_COMPANY_MEMBER_DATA_SUCCESS,
    GET_COMPANY_MEMBERS_FAILURE, GET_COMPANY_MEMBERS_PENDING, GET_COMPANY_MEMBERS_SUCCESS,
    POST_COMPANY_MEMBER_FAILURE, POST_COMPANY_MEMBER_PENDING, POST_COMPANY_MEMBER_SUCCESS,
    REGISTER_OWNER_FAILURE, REGISTER_OWNER_PENDING, REGISTER_OWNER_SUCCESS,
    UPDATE_COMPANY_MEMBER_FAILURE, UPDATE_COMPANY_MEMBER_PENDING, UPDATE_COMPANY_MEMBER_SUCCESS
} from "../actionTypes";

//get company members
export const getCompanyMembersPending = () => ({
    type: GET_COMPANY_MEMBERS_PENDING
})

export const getCompanyMembersSuccess = (data) => ({
    type: GET_COMPANY_MEMBERS_SUCCESS,
    data
})

export const getCompanyMembersFailure = (error) => ({
    type: GET_COMPANY_MEMBERS_FAILURE,
    error
})

//get member data
export const getCompanyMemberDataPending = (id) => ({
    type: GET_COMPANY_MEMBER_DATA_PENDING,
    id
})

export const getCompanyMemberDataSuccess = (data) => ({
    type: GET_COMPANY_MEMBER_DATA_SUCCESS,
    data
})

export const getCompanyMemberDataFailure = (error) => ({
    type: GET_COMPANY_MEMBER_DATA_FAILURE,
    error
})

//update member
export const updateCompanyMemberPending = (option) => ({
    type: UPDATE_COMPANY_MEMBER_PENDING,
    option
})

export const updateCompanyMemberSuccess = (data) => ({
    type: UPDATE_COMPANY_MEMBER_SUCCESS,
    data
})

export const updateCompanyMemberFailure = (error) => ({
    type: UPDATE_COMPANY_MEMBER_FAILURE,
    error
})

//post member
export const postCompanyMemberPending = (option) => ({
    type: POST_COMPANY_MEMBER_PENDING,
    option
})

export const postCompanyMemberSuccess = (data) => ({
    type: POST_COMPANY_MEMBER_SUCCESS,
    data
})

export const postCompanyMemberFailure = (error) => ({
    type: POST_COMPANY_MEMBER_FAILURE,
    error
})

//delete member
export const deleteCompanyMemberPending = (id, role) => ({
    type: DELETE_COMPANY_MEMBER_PENDING,
    id,
    role
})

export const deleteCompanyMemberSuccess = (data) => ({
    type: DELETE_COMPANY_MEMBER_SUCCESS,
    data
})

export const deleteCompanyMemberFailure = (error) => ({
    type: DELETE_COMPANY_MEMBER_FAILURE,
    error
})

//register owner
export const registerOwnerPending = (option) => ({
    type: REGISTER_OWNER_PENDING,
    option
})

export const registerOwnerSuccess = (data) => ({
    type: REGISTER_OWNER_SUCCESS,
    data
})

export const registerOwnerFailure = (message, status) => ({
    type: REGISTER_OWNER_FAILURE,
    message,
    status
})

export const clearOwnerData = () => ({
    type: CLEAR_OWNER_DATA,
})


export const clearMembersData = () => ({
    type: CLEAR_MEMBERS_DATA
})

//create company document
export const createDocumentPending = (option) => ({
    type: CREATE_DOCUMENT_PENDING,
    option
})

export const createDocumentSuccess = (data) => ({
    type: CREATE_DOCUMENT_SUCCESS,
    data
})

export const createDocumentFailure = (error) => ({
    type: CREATE_DOCUMENT_FAILURE,
    error
})

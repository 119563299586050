import React, { useEffect, useState } from 'react';
import {Grid, List} from "@mui/material";
import cx from "classnames";
import styles from "../../../transactions/transaction.module.scss"
import {CustomList, Icon} from "../../../../common";
import moment from "moment";
import CustomTooltip from "../../../../common/CustomTooltip";
import {toCapitalize} from "../../../../../utils/helpers";
import { useSelector } from 'react-redux';
import { getCurrencyData } from 'store/selectors/currency';

const TransactionList = ({transactions: {items}}) => {
    const {currency} = useSelector(getCurrencyData);
    const [currencySymbol, setCurrencySymbol] = useState('£');

    useEffect(() => {
        setCurrencySymbol(currency === 'GBP'? '£' : '€');
    }, [currency]);

    const getSubtitle = (transaction) => {
        var subtitle = "";
        if (transaction.type === 'funds_in') {
            if (transaction.source_sort_code) {
                subtitle += `Sort code: ${transaction.source_sort_code.replace(/\B(?=(\d{2})+(?!\d))/g, "-")}, `;
            }
            if (transaction.source_account_number) {
                subtitle += `Acc number: ${transaction.source_account_number} `;
            }
            if (transaction.source_iban) {
                subtitle += `IBAN: ${transaction.source_iban}, `;
            }
            if (transaction.source_bic) {
                subtitle += `BIC: ${transaction.source_bic} `;
            }
        } else {
            if (transaction.destination_sort_code) {
                subtitle += `Sort code: ${transaction.destination_sort_code.replace(/\B(?=(\d{2})+(?!\d))/g, "-")}, `;
            }
            if (transaction.destination_account_number) {
                subtitle += `Acc number: ${transaction.destination_account_number} `;
            }
            if (transaction.destination_iban) {
                subtitle += `IBAN: ${transaction.destination_iban}, `;
            }
            if (transaction.destination_bic) {
                subtitle += `BIC: ${transaction.destination_bic} `;
            }
        }
        return subtitle;
    };

    const getTransactionStatusIcon = (transaction) => {
        switch (transaction.status) {
            case 'processing':
                return 'pending-circle';
            case 'successful':
                return 'success-circle';
            case 'failed':
                return 'failed-circle';
            default:
                break;
        }
    }

    return (
        <Grid item md={12} xs={12} className={cx(styles.list)}>
            {items?.length > 0 && items.map((item) => (
                <List key={item.day}>
                    <h1 className={cx(styles.createdDate)}>{moment(item.date).format('DD MMM YYYY')}</h1>
                    {item.transactions?.length > 0 && item.transactions.map((transaction) => (
                        <React.Fragment key={transaction.transaction_id}>
                            <CustomList
                                id={transaction.transaction_id}
                                title={transaction.type === 'funds_in' ?  transaction.source_account_name : transaction.destination_name}
                                avatar={"true"}
                                subtitle={getSubtitle(transaction)}
                                customClass={cx(styles.item)}
                                avatarClass={cx(styles.avatar)}
                                actions={[
                                    <span key={"reference"} className={cx(styles.subStatusRef, ((transaction.type === 'funds_in' && transaction.source_account_name) || ( transaction.type === 'funds_out' && transaction.destination_name)) && styles.subStatusRefNoData)}>
                                        Ref: {transaction.reference?.substring(0, 75)} {transaction.reference?.length >= 75 && '...'}
                                    </span>,
                                    <span key={"txn_status"} className={cx(styles.subStatusIcon)}>
                                        <CustomTooltip info={toCapitalize(transaction.status)}>
                                            <Icon  name={getTransactionStatusIcon(transaction)}/>
                                        </CustomTooltip>
                                    </span>,
                                    <span key={"currency_symbol"} className={styles.subType} style={{color: transaction.type === 'funds_out' ? '#111326' : '#57AD9E'}}>
                                        {transaction.type === 'funds_in' ? `+ ${currencySymbol}${transaction.amount}` : `- ${currencySymbol}${transaction.amount}`}
                                    </span>
                                ]}
                            />
                        </React.Fragment>
                    ))}
                </List>
            ))}
        </Grid>
    );
};
export default TransactionList;
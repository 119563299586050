import React, {useCallback, useEffect} from 'react';
import { Grid } from "@mui/material";
import { Button, Dialog, Icon } from "components/common";
import cx from "classnames";
import Otp from 'components/common/Otp';
import { useDispatch, useSelector } from "react-redux";
import {actions} from "../slice";
import selectState from '../selectors';
import styles from "../send-funds.module.scss";
import NumberFormat from "react-number-format";
import styled from "styled-components/macro";
import insufficientFundsPng from 'assets/images/pages/send-funds/insufficient-funds.png';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import { Loading } from "components/common";
import {getAccessToken} from "utils/auth";
import { getCurrencyData } from 'store/selectors/currency';


const StyledTransaction = styled.div`
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #111326;
    padding: 0 70px;
    
    @media (max-width: 992px) {
      padding: 0 20px;
    }
  }
`;


const Review = () => {
    const dispatch = useDispatch();
    const {
        fromAccount,
        transaction_details,
        selectedRecipient,
        transaction_fee,
        otp,
        error,
        otpCount,
        loading,
        token,
        transactionStatus,
    } = selectState();
    const authorizeToken = getAccessToken()
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [OTP, setOTP] = React.useState("");
    const [isError, setIsError] = React.useState(false);
    const {currency} = useSelector(getCurrencyData);
    const [fromAccountName, setFromAccountName] = React.useState('');

    useEffect(() => {
        if (transactionStatus === 'error') {
            setIsError(true);
        }
        return () => {
            setIsError(false);
        }
    }, [isError, transactionStatus]);


    useEffect(() => {
        dispatch(actions.resetOtp());
        if (otp) {
            setIsModalOpen(true);
        }
    }, [dispatch, otp]);

    useEffect(() => {
        setFromAccountName(fromAccount && (fromAccount.account_holder_name || fromAccount.account_name || fromAccount.client_account_name));
    }, [fromAccount]);

    const submitOTP = useCallback(() => {
        if (OTP.length === 6) {
            dispatch(actions.createTransaction({ OTP, token, fromAccount, transaction_details }))
        } else {
            setIsError(true);
        }
    }, [transaction_details, OTP, token, fromAccount, dispatch ]);

    const handleResend = useCallback(() => {
        setOTP('');
        dispatch(actions.getOtp({token: authorizeToken, transaction: transaction_details}));
        dispatch(actions.resetTransactionStatus());
    }, [dispatch, authorizeToken, transaction_details]);

    const getOTP = useCallback(() => {
        dispatch(actions.getOtp({token: authorizeToken, transaction: transaction_details}));
    }, [dispatch, authorizeToken, transaction_details]);


    return (
        <Grid item xs={12} md={12} lg={12} sm={12} position={'relative'}>
            {loading && <Loading className={cx(styles.fundsLoading)} />}
            <Grid container mb={4}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                    <h2 className={cx(styles['stepper-title'])}>
                        Review
                    </h2>
                </Grid>
            </Grid>
                <Grid container>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Transfer details</div>
                            <div className={cx(styles['review-edit-icon'])} onClick={() => {
                                dispatch(actions.setIsEditTransaction(true))
                                dispatch(actions.setCurrentFundStep(1))
                            }}>
                                Edit <Icon name="edit" color="#00A09B" />
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>You send:</div>
                            <span className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                  {transaction_details && transaction_fee &&
                                      <NumberFormat
                                          value={parseFloat(transaction_details.amount).toFixed(2)}
                                          thousandSeparator={true}
                                          displayType={'text'} />
                                  } {currency}
                            </span>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Recipient receives:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {transaction_details &&
                                    <NumberFormat
                                        value={parseFloat(transaction_details.amount).toFixed(2)}
                                        thousandSeparator={true}
                                        displayType={'text'} />
                                } {currency}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Reference:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {transaction_details && transaction_details.reference}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Balance after transfer:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {(transaction_details && fromAccount) && (
                                    <NumberFormat
                                        value={parseFloat(parseFloat(fromAccount.balance) - parseFloat(transaction_details.amount)).toFixed(2)}
                                        thousandSeparator={true}
                                        displayType={'text'} />
                                    )} {currency}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Recipient details</div>
                            <div className={cx(styles['review-edit-icon'])} onClick={() => dispatch(actions.setCurrentFundStep(0))}>
                                Change
                                <Icon name="edit" color="#00A09B" />
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Name:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.account_holder_name}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div style={{minWidth: 150}}>Email Address:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient ? selectedRecipient.email : "-"}
                            </div>
                        </div>
                        { selectedRecipient?.sort_code && <div className={cx(styles['review-detail-label'])}>
                            <div>Sort code:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient ? selectedRecipient.sort_code : "-"}
                            </div>
                        </div>}
                        {selectedRecipient?.account_number && <div className={cx(styles['review-detail-label'])}>
                            <div>Account Number:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.account_number}
                            </div>
                        </div>}
                        {selectedRecipient?.iban && <div className={cx(styles['review-detail-label'])}>
                            <div>IBAN:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.iban}
                            </div>
                        </div>}
                        {selectedRecipient?.bic && <div className={cx(styles['review-detail-label'])}>
                            <div>BIC:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.bic}
                            </div>
                        </div>}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Remitter details</div>
                            <div className={cx(styles['review-edit-icon'])} onClick={() => dispatch(actions.setCurrentFundStep(0))}>
                                Change
                                <Icon name="edit" color="#00A09B" />
                            </div>
                        </div>
                        {fromAccountName && <div className={cx(styles['review-detail-label'])}>
                            <div>Name:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                            {fromAccountName}
                            </div>
                        </div>}
                        { fromAccount?.sort_code && <div className={cx(styles['review-detail-label'])}>
                            <div>Sort code:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount ? fromAccount.sort_code : "-"}
                            </div>
                        </div>}
                        {fromAccount?.account_number && <div className={cx(styles['review-detail-label'])}>
                            <div>Account Number:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount && fromAccount.account_number}
                            </div>
                        </div>}
                        {fromAccount?.iban && <div className={cx(styles['review-detail-label'])}>
                            <div>IBAN:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount && fromAccount.iban}
                            </div>
                        </div>}
                        {fromAccount?.bic && <div className={cx(styles['review-detail-label'])}>
                            <div>BIC:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount && fromAccount.bic}
                            </div>
                        </div>}
                    </Grid>
                </Grid>
                <Grid container mt={5}>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                        <Button
                            className={cx(styles['review-button'], styles['submitButton'])}
                            onClick={getOTP}
                            size="lg"
                            variant="gradient"
                            text="Confirm Transaction"
                            disabled={loading}
                        />
                    </Grid>
                </Grid>
            <Dialog
                fullWidth
                open={isModalOpen}
                isSingleButton={false}
                className={'recipients-modal'}
                setOpen={() => setIsModalOpen(!isModalOpen)}
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)'
                    }
                }}
            >
                <StyledTransaction>
                    {!isError ?
                        <Otp
                            heading={'Enter the code you received via SMS to approve payment'}
                            otp={OTP}
                            onChange={setOTP}
                            disabled={OTP.length !== 6 || transactionStatus === 'pending'}
                            length={6}
                            onSubmit={() => submitOTP()}
                            onResend={handleResend}
                            error={error}
                            buttonText={"Confirm and send funds"}
                            otpCount={otpCount}
                            maxTime={45}
                        />
                        :
                        <>
                            <h2>Insufficient funds. Please select another amount and try again</h2>
                            <img src={insufficientFundsPng} alt={"insufficient-funds"} style={{ maxWidth: '100%', width: '164px', height: '164px', margin: "20px 0" }} />
                            <StyledOtpButtonWrapper>
                                <Button
                                    onClick={() => {
                                        dispatch(actions.resetTransactionStatus());
                                        setIsModalOpen(!isModalOpen)
                                    }}
                                    size="lg"
                                    variant="gradient"
                                    text={'Try Again'}
                                />
                            </StyledOtpButtonWrapper>
                        </>
                    }
                </StyledTransaction>
            </Dialog>
        </Grid>
    );
};

export default Review;
import React, {useEffect, useState} from "react"

import {Navigate, Outlet, useLocation, useNavigate, useParams} from "react-router-dom"
import { getAccessToken, getOtp } from "../../utils/auth";


const ProtectedRoutes = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isOtpVerified = getOtp();
    const [auth, setAuth] = useState(getAccessToken());
    let { userId, token } = useParams();

    useEffect(() => {
        if (token && location.pathname.includes('/kyc')) {
            setAuth(true);
             // localStorage.setItem('token', token);
             navigate(`/kyc/${userId}/${token}`);
        }
        if (token && location.pathname.includes('/set-password')) {
            setAuth(true);
            localStorage.setItem('token', token);
            navigate(`/set-password/${userId}/${token}`);
        }
        if(auth) {
            if(location.pathname === "/") {
                navigate("/dashboard/home");
            }
        }

    }, [auth, userId, token, location.pathname, navigate]);

    return (auth && isOtpVerified) ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes
import React from 'react';
import {useNavigate} from "react-router-dom";
import {Icon} from "../../common";

import styles from "../../../assets/styles/business.module.scss";
import cx from "classnames";

const MemberCard = (props) => {
    const {name, roles, id, onDelete, param} = props;
    const navigate = useNavigate();
    const role = roles?.[0]?.role.charAt(0).toUpperCase() + roles?.[0]?.role.slice(1);

    return (
        <div className={cx(styles.memberCard)}>
            <div className={cx(styles.info)}>
                <h4>{name}</h4>
                <p>{roles?.length > 1 ? 'UBO & Director' : role === 'Ubo' ? role.toUpperCase() : role}</p>
            </div>
            <div className={cx(styles.actions)}>
                <Icon name={'remove'} onClick={() => onDelete(id, roles.length)}/>
                <Icon name={'edit'} onClick={() => navigate(`/business/${param}/${id}`)}/>
            </div>
        </div>
    );
};

export default MemberCard;
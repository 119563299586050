import {
    GET_COMPANIES_FAILURE, GET_COMPANIES_PENDING, GET_COMPANIES_SUCCESS,
    GET_COMPANY_TYPES_PENDING, GET_COMPANY_TYPES_SUCCESS, GET_COMPANY_TYPES_FAILURE,
    POST_MEMBERS_FAILURE, POST_MEMBERS_PENDING, POST_MEMBERS_SUCCESS,
    GET_COMPANY_DATA_PENDING, GET_COMPANY_DATA_SUCCESS, GET_COMPANY_DATA_FAILURE,
    UPDATE_COMPANY_PENDING, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE,
    GET_INDUSTRY_TYPE_PENDING, GET_INDUSTRY_TYPE_SUCCESS, GET_INDUSTRY_TYPE_FAILURE,
    GET_FUND_SOURCES_PENDING, GET_FUND_SOURCES_SUCCESS, GET_FUND_SOURCES_FAILURE,
    CHECK_REGISTRATION_NUMBER_PENDING, CHECK_REGISTRATION_NUMBER_SUCCESS, CHECK_REGISTRATION_NUMBER_FAILURE,
    CLEAR_MESSAGE, CLEAR_COMPANY_NUMBER, CLEAR_COMPANIES,
} from "../actionTypes";


//get companies default member
export const postMembersPending = (company_number) => ({
    type: POST_MEMBERS_PENDING,
    company_number
})

export const postMembersSuccess = (data) => ({
    type: POST_MEMBERS_SUCCESS,
    data
})

export const postMembersFailure = (message, status) => ({
    type: POST_MEMBERS_FAILURE,
    message,
    status
})


//get companies
export const getCompaniesPending = (option) => ({
    type: GET_COMPANIES_PENDING,
    option
})

export const getCompaniesSuccess = (data) => ({
    type: GET_COMPANIES_SUCCESS,
    data
})

export const getCompaniesFailure = (error) => ({
    type: GET_COMPANIES_FAILURE,
    error
})


//get company types
export const getCompanyTypesPending = () => ({
    type: GET_COMPANY_TYPES_PENDING
})

export const getCompanyTypesSuccess = (data) => ({
    type: GET_COMPANY_TYPES_SUCCESS,
    data
})

export const getCompanyTypesFailure = (error) => ({
    type: GET_COMPANY_TYPES_FAILURE,
    error
})


//get company data
export const getCompanyDataPending = () => ({
    type: GET_COMPANY_DATA_PENDING
})

export const getCompanyDataSuccess = (data) => ({
    type: GET_COMPANY_DATA_SUCCESS,
    data
})

export const getCompanyDataFailure = (message, status) => ({
    type: GET_COMPANY_DATA_FAILURE,
    message,
    status
})

//update company
export const updateCompanyPending = (option) => ({
    type: UPDATE_COMPANY_PENDING,
    option
})

export const updateCompanySuccess = (data) => ({
    type: UPDATE_COMPANY_SUCCESS,
    data
})

export const updateCompanyFailure = (message, status) => ({
    type: UPDATE_COMPANY_FAILURE,
    message,
    status
})


//clear message
export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
})


//get industry type
export const getIndustryTypePending = () => ({
    type: GET_INDUSTRY_TYPE_PENDING
})

export const getIndustryTypeSuccess = (data) => ({
    type: GET_INDUSTRY_TYPE_SUCCESS,
    data
})

export const getIndustryTypeFailure = (error) => ({
    type: GET_INDUSTRY_TYPE_FAILURE,
    error
})



//get fund Sources
export const getFundSourcesPending = () => ({
    type: GET_FUND_SOURCES_PENDING
})

export const getFundSourcesSuccess = (data) => ({
    type: GET_FUND_SOURCES_SUCCESS,
    data
})

export const getFundSourcesFailure = (error) => ({
    type: GET_FUND_SOURCES_FAILURE,
    error
})

//check registration number
export const checkRegistrationNumberPending = (option) => ({
    type: CHECK_REGISTRATION_NUMBER_PENDING,
    option
})

export const checkRegistrationNumberSuccess = (data) => ({
    type: CHECK_REGISTRATION_NUMBER_SUCCESS,
    data
})

export const checkRegistrationNumberFailure = (message, status) => ({
    type: CHECK_REGISTRATION_NUMBER_FAILURE,
    message,
    status
})

export const clearCompanyNumber = () => ({
    type: CLEAR_COMPANY_NUMBER
})

export const clearCompanies = () => ({
    type: CLEAR_COMPANIES
})
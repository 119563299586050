import React from 'react';
import {LinearProgress} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import zxcvbn from 'zxcvbn'

const PasswordStrength = ({password}) => {
    const result = zxcvbn(password)
    const score = result.score * 100/4;

    const theme = createTheme({
        palette: {
            primary: {
                main: score < 25 && score >=  0 ? '#FF8888' : score >= 25 && score < 75 ? '#FFB800' : '#00A09B'
            }
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <LinearProgress variant="determinate" color={'primary'} value={score} sx={{marginTop: '18px'}}/>
        </ThemeProvider>
    );
};

export default PasswordStrength
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import countryList from "react-select-country-list";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";

import {
    checkIsFormDirty, clearForms, clearMessage,
    updateCompanyPending, getCompaniesPending, getCompanyTypesPending,
    postMembersPending, checkRegistrationNumberPending, clearCompanyNumber,
    clearMembersData, getCompanyMembersPending, getCompanyDataPending, clearOwnerData,
    // clearCompanies
} from "../../store/actions";
import {getCompanies, getCompanyData, getCompanyTypes, postMembers,} from "../../store/selectors";

import useDebounce from "../../hooks/useDebounce";
import {toCapitalize} from "../../utils/helpers";
import {legalInfoFormValidation} from "./validation";

import {InputField, SelectField, Autocomplete, Loading, Messages, RadioGroup, Button} from "../common";

import {addresses} from "./mockData";
import styles from "../../assets/styles/business.module.scss";
import cx from 'classnames';

const LegalInfo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data, isLoading, message} = useSelector(getCompanyData)
    const companyTypes = useSelector(getCompanyTypes);
    const {companies: {items, start_index, total_results, page_number}, number, isLoading: loading} = useSelector(getCompanies)

    const [allowShowModal, setAllowShowModal] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [disableField, setDisableField] = useState(true)
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false)
    const [selected, setSelected] = useState(false)
    const [open, setOpen] = useState(false)
    const {error, data:members} = useSelector(postMembers)

    const options = useMemo(() =>countryList().getData().filter(item => item.value !== 'GB' && item.value !== 'FR'),[]);
    const filteredOption = [{value: "GB", label: 'United Kingdom'}, {value: "FR", label:"France"}, {value: "line"}, ...options];

    const [formDirty, setFormDirty] = useState(false);
    const [loadPage, setLoadPage] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState(null);
    const [companyData, setCompanyData] = useState([]);
    const [idx, setIdx] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [added, setAdded] = useState(false);


    useEffect(() => {
        dispatch(clearForms())
        // dispatch(clearCompanies())
        dispatch(clearMessage())
        dispatch(clearCompanyNumber())
    },[dispatch])

    useEffect(() => {
        if(companyTypes?.length === 0) {
            dispatch(getCompanyTypesPending())
        }
    }, [dispatch, companyTypes])

    useEffect(() => {
        const info = Object.keys(data);
        if(info?.length === 0 ){
            dispatch(getCompanyDataPending())
        }
    }, [dispatch, data])

    const formik = useFormik({
        initialValues: {
            legal_name: '',
            registration_number: '',
            country: '',
            company_type_id: '',
            address_line: '',
            trading_address: {
                city: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                country: 'GB',
            },
            registration_address: {
                city: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                country: 'GB',
            }
        },
        validationSchema: legalInfoFormValidation,
        onSubmit: (values) => {
            dispatch(clearMembersData())
            setAllowShowModal(false)
            dispatch(clearForms())
            dispatch(updateCompanyPending({
                ...values,
                trading_address: values.address_line === 'registered' ? values.registration_address : values.trading_address
            }))
            dispatch(postMembersPending(values.registration_number))
            setShowMessage(false)
        },
    })

    useEffect(() => {
        if(error === 'INVALID_RESPONSE') {
            dispatch(clearMembersData())
        }
    }, [error, dispatch])


    useEffect(() => {
        if(members?.message === 'ok'){
            if(message === 'Company updated'){
                dispatch(getCompanyMembersPending())
                navigate('/business/owners')
            }
        }
    }, [members?.message, message, navigate, dispatch])

    useEffect(() => {
        if(data?.id){
            const registration_address = {...data.registration_address?.[0], country: 'GB'};
            const trading_address = {...data.trading_address?.[0], country: 'GB'};
            delete registration_address?.id;
            delete trading_address?.id;
            let address_line = '';
            if(JSON.stringify(registration_address) === JSON.stringify(trading_address) && data.registration_address?.length > 0){
                setDefaultAddress('registered')
                address_line = 'registered'
            } else if(JSON.stringify(registration_address) !== JSON.stringify(trading_address) && data.trading_address?.length > 0){
                setDefaultAddress('traded')
                address_line = 'traded'
            } else {
                setDefaultAddress('')
            }
            formik.setValues({
                ...data,
                address_line,
                legal_name: toCapitalize(data.legal_name),
                registration_address,
                trading_address,
                country: 'GB'
            })
            setFormDirty(false)
            setDisableField(false)
        }
        // eslint-disable-next-line
    }, [data])

    const handleInputChange = useCallback((event) => {
        setAllowShowModal(true)
        if(event.target.name === 'address_line'){
            setDefaultAddress(event.target.value)
        }
        formik.setValues(prevValues => ({
            ...prevValues,
            [event.target.name]: event.target.value,
            trading_address: event.target.name === 'address_line'
                ? { city: '', address_line_1: '', address_line_2: '', post_code: '', country: 'GB'}
                : prevValues.trading_address,
        }))
        formik.setTouched({trading_address: { city: '', address_line_1: '', address_line_2: '', post_code: '', country: 'GB'} }, false)

    }, [formik])

    const handleAddressChange = useCallback((event) => {
        setAllowShowModal(true)
        const keys = event.target.name.split('.');
        formik.setValues(prevValues => ({...prevValues,
            [keys[0]]: {
                ...prevValues[keys[0]],
                [keys[1]]: event.target.value
            }
        }))
    }, [formik])


    const handleSelect = useCallback((name, value) => {
        setAllowShowModal(true)
        formik.setValues(prev => ({...prev, [name]: value}))
    }, [formik])



    const name = useDebounce(formik.values.legal_name, 200);
    const getCompaniesData = useCallback((query, index) => {
        if(query?.length >= 4) {
            const data = {
                query,
                start_index: index
            }
            setLoadingAutocomplete(formDirty && true)
            dispatch(getCompaniesPending(data))
            // setOpen(formDirty && true)
            if(selected) {
                setLoadingAutocomplete(false)
            }
        } else {
            setLoadingAutocomplete(false)
            dispatch(getCompaniesPending({query: ''}))
        }
    }, [dispatch, formDirty, selected])

    useEffect(() => {
        // console.log('IDZ', idx)
        if(name?.length >= 4 && idx !== null) {
            getCompaniesData(name, idx)
        }
    }, [getCompaniesData, name, idx])

    const getData = useCallback(() => {
        if(page_number * idx < total_results && items?.length > 0){
            setIsScrolling(true)
            setIdx(prevState => prevState + 20)
        }
    }, [total_results, page_number, idx, items?.length])

    useEffect(() => {
        if(companyData?.length === 0 && loading === false && name?.length >= 4 && formDirty) {
            setTimeout(() => {
                setLoadingAutocomplete(false)
            }, 2000)
        }
        if(companyData?.length > 0 && name?.length >= 4 && loading === false && formDirty) {
            setLoadingAutocomplete(false)
        }
    }, [companyData?.length, name?.length, loading, formDirty, selected])

    useEffect(() => {
        if(selected){
            setOpen(false)
            setLoadingAutocomplete(false)
        }
    },[selected])

    useEffect(() => {
        setShowMessage(number === 'already_registered')
    }, [number])

    useEffect(() => {
        if(formik.dirty && allowShowModal){
            dispatch(checkIsFormDirty('legalInfo'))
        }
        // eslint-disable-next-line 
    },[dispatch, allowShowModal])

    const handleDetailsClick = useCallback((val) => {
        if(val){
            dispatch(clearMembersData())
        }
        setDisableField(false)
        setSelected(true)
        setOpen(false)
        formik.setValues(prevState => ({
            ...prevState,
            registration_number: '',
            address_line: 'registered',
            registration_address: {
                city: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                country: 'GB'
            },
            trading_address: {
                city: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                country: 'GB'
            },
            company_type_id: '',
        }))
        setDefaultAddress('registered')
    }, [dispatch, formik])

    const handleAutocompleteChange = useCallback((value) => {
        setOpen(true)
        setIdx(0)
        setAdded(false)
        if(value.length >= 4) {
            setFormDirty(true)
        }
        // setIsScrolling(false)
        dispatch(clearCompanyNumber())
        formik.setValues(prev => ({...prev, legal_name: value, address_line: 'registered'}))
        setDefaultAddress('registered')
    }, [formik, dispatch])

    const handleClear = useCallback(() => {
        dispatch(clearCompanyNumber())
        setSelected(false)
        setShowMessage(false)
        setDisableField(true)
        // dispatch(getCompaniesPending({query: ''}))
        formik.setValues({
            legal_name: '',
            registration_number: '',
            country: 'GB',
            address_line: '',
            registration_address: {
                city: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                country: 'GB'
            },
            trading_address: {
                city: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                country: 'GB'
            },
            company_type_id: '',
        })
        setIdx(0)
        setCompanyData([])
        setDefaultAddress('')
    }, [formik, dispatch])

    const handleSelectItem = useCallback((value) => {
        if(value?.title){setSelected(true)}
        setFormDirty(false)
        setDisableField(false)
        dispatch(clearCompanyNumber())
        dispatch(clearOwnerData())
        const types = companyTypes.find(item => item.shortName === value?.company_type)
        formik.setValues(prev => ({
            ...prev,
            registration_number: value.company_number,
            registration_address: {
                city: value.address?.locality,
                address_line_1: `${value.address?.premises || ''} ${value.address?.address_line_1}`,
                address_line_2:  value.address?.address_line_2 || '',
                post_code: value.address?.postal_code,
                country: 'GB'
            },
            trading_address: {
                city: '',
                address_line_1: '',
                address_line_2: '',
                post_code: '',
                country: 'GB',
            },
            address_line: 'registered',
            company_type_id: types?.value,
            // company_type_specification: !types ? value?.company_type : '',
            legal_name: toCapitalize(value.title),
            country: 'GB'
        }))
        setDefaultAddress('registered')
        if(value.company_number !== data?.registration_number){
            dispatch(checkRegistrationNumberPending({
                registration_number: value.company_number
            }))
        }
        setOpen(false)
    }, [formik, companyTypes, dispatch, data])

    const handleBlur = useCallback((event) => {
        if(data?.registration_number !== event.target.value){
            dispatch(checkRegistrationNumberPending({
                registration_number: event.target.value
            }))
        }
    }, [dispatch, data])

    useEffect(() => {
        if(isLoading){
            setLoadPage(true)
            if(message === 'Company updated'){
                setLoadPage(false)
            }
        }
    }, [isLoading, message])

    useEffect(() => {
        if(items?.length){
            if(isScrolling && idx > 0){
                setCompanyData(pervState => ([...pervState, ...items]))
                setIsScrolling(false)
                setAdded(true)
            } else {
                if(!added){
                    setCompanyData(items)
                }
            }
        }

        if(items?.length === 0 ){
            // setOpen(false)
            setCompanyData(items)
        }

        // eslint-disable-next-line
    }, [items, isScrolling, added])

    useEffect(() => {
        setIdx(start_index)
    }, [start_index])

    return (
        <>
            {showMessage && <Messages text={'This company is already registered.'} variant={'error'}/>}
            {loadPage && <Loading  className={cx(styles.businessLoading)} />}
            {/*{console.log(companyData)}*/}
            <div className={cx(styles.legalInfo)}>
                <h3>Legal Information</h3>
                <form onSubmit={formik.handleSubmit}>
                    <SelectField
                        name={'country'}
                        label={'Country'}
                        value={formik.values.country}
                        required={true}
                        disable={true}
                        onChange={handleSelect}
                        options={filteredOption}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                        className={'selectField'}
                    />
                    <Autocomplete
                        name={'legal_name'}
                        label={'Company legal name'}
                        placeholder={'Enter at least 4 letters to start searching for a company'}
                        defaultValue={formik.values.legal_name || ''}
                        options={companyData}
                        required={true}
                        open={open}
                        loading={loadingAutocomplete || loading}
                        onChange={handleAutocompleteChange}
                        onSelect={handleSelectItem}
                        onDetailsClick={handleDetailsClick}
                        error={(formik.touched.legal_name && Boolean(formik.errors.legal_name))}
                        helperText={formik.touched.legal_name && formik.errors.legal_name}
                        clear={handleClear}
                        getData={getData}
                    />
                    <InputField
                        name={'registration_number'}
                        required={true}
                        disable={!formik.values.legal_name || disableField}
                        label={'Company registration number'}
                        value={formik.values.registration_number}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        error={!disableField && formik.touched.registration_number && Boolean(formik.errors.registration_number)}
                        helperText={!disableField && formik.touched.registration_number && formik.errors.registration_number}
                    />
                    <InputField
                        name={'registration_address.address_line_1'}
                        label={'Address line 1'}
                        required={true}
                        disable={!formik.values.legal_name || disableField}
                        value={formik.values.registration_address?.address_line_1 || ''}
                        onChange={handleAddressChange}
                        error={!disableField && formik.touched.registration_address?.address_line_1 && Boolean(formik.errors.registration_address?.address_line_1)}
                        helperText={!disableField && formik.touched.registration_address?.address_line_1 && formik.errors.registration_address?.address_line_1}
                    />
                    <InputField
                        name={'registration_address.address_line_2'}
                        label={'Address line 2 (optional)'}
                        disable={!formik.values.legal_name || disableField}
                        value={formik.values.registration_address?.address_line_2}
                        onChange={handleAddressChange}
                        error={!disableField && formik.touched.registration_address?.address_line_2 && Boolean(formik.errors.registration_address?.address_line_2)}
                        helperText={!disableField && formik.touched.registration_address?.address_line_2 && formik.errors.registration_address?.address_line_2}
                    />
                    <InputField
                        name={'registration_address.city'}
                        label={'City'}
                        required={true}
                        value={formik.values.registration_address?.city}
                        disable={!formik.values.legal_name || disableField}
                        onChange={handleAddressChange}
                        error={!disableField && formik.touched.registration_address?.city && Boolean(formik.errors.registration_address?.city)}
                        helperText={!disableField && formik.touched.registration_address?.city && formik.errors.registration_address?.city}
                    />
                    <InputField
                        name={'registration_address.post_code'}
                        label={'Post code'}
                        required={true}
                        disable={!formik.values.legal_name || disableField}
                        value={formik.values.registration_address?.post_code}
                        onChange={handleAddressChange}
                        error={!disableField && formik.touched.registration_address?.post_code && Boolean(formik.errors.registration_address?.post_code)}
                        helperText={!disableField && formik.touched.registration_address?.post_code && formik.errors.registration_address?.post_code}
                    />
                    {(defaultAddress || defaultAddress === '') && <RadioGroup
                        required={true}
                        className={'mt-32'}
                        name={'address_line'}
                        options={addresses}
                        label="Trading address:"
                        onChange={handleInputChange}
                        defaultValue={defaultAddress || ''}
                        disabled={!formik.values.legal_name || disableField}
                        error={!disableField && (formik.touched.address_line && Boolean(formik.errors.address_line))}
                        helperText={!disableField && formik.touched.address_line && formik.errors.address_line}
                    />}
                    {(formik.values.address_line === 'traded' || defaultAddress === 'traded') && <>
                        <InputField
                            name={'trading_address.address_line_1'}
                            label={'Address line 1'}
                            required={true}
                            disable={!formik.values.legal_name || disableField}
                            value={formik.values.trading_address?.address_line_1}
                            onChange={handleAddressChange}
                            error={!disableField && formik.touched.trading_address?.address_line_1 && Boolean(formik.errors.trading_address?.address_line_1)}
                            helperText={!disableField && formik.touched.trading_address?.address_line_1 && formik.errors.trading_address?.address_line_1}
                        />
                        <InputField
                            name={'trading_address.address_line_2'}
                            label={'Address line 2 (optional)'}
                            disable={!formik.values.legal_name || disableField}
                            value={formik.values.trading_address?.address_line_2}
                            onChange={handleAddressChange}
                            error={!disableField && formik.touched.trading_address?.address_line_2 && Boolean(formik.errors.trading_address?.address_line_2)}
                            helperText={!disableField && formik.touched.trading_address?.address_line_2 && formik.errors.trading_address?.address_line_2}
                        />
                        <InputField
                            name={'trading_address.city'}
                            label={'City'}
                            required={true}
                            value={formik.values.trading_address?.city}
                            disable={!formik.values.legal_name || disableField}
                            onChange={handleAddressChange}
                            error={!disableField && formik.touched.trading_address?.city && Boolean(formik.errors.trading_address?.city)}
                            helperText={!disableField && formik.touched.trading_address?.city && formik.errors.trading_address?.city}
                        />
                        <InputField
                            name={'trading_address.post_code'}
                            label={'Post code'}
                            required={true}
                            disable={!formik.values.legal_name || disableField}
                            value={formik.values.trading_address?.post_code}
                            onChange={handleAddressChange}
                            error={!disableField && formik.touched.trading_address?.post_code && Boolean(formik.errors.trading_address?.post_code)}
                            helperText={!disableField && formik.touched.trading_address?.post_code && formik.errors.trading_address?.post_code}
                        />
                    </>}
                    <SelectField
                        name={'company_type_id'}
                        label={'Type of company'}
                        value={formik.values.company_type_id}
                        required={true}
                        disable={!formik.values.legal_name || disableField}
                        onChange={handleSelect}
                        options={companyTypes}
                        error={!disableField && formik.touched.company_type_id && Boolean(formik.errors.company_type_id)}
                        helperText={!disableField && formik.touched.company_type_id && formik.errors.company_type_id}
                        className={'selectField'}
                    />
                    <Button
                        text={'Save and Continue'}
                        size={'lg'}
                        disabled={showMessage}
                        margin={{top: '40px'}}
                        onClick={formik.handleSubmit}
                    />
                    {/*<button className={cx(styles.submit, !showMessage? styles.submitActive : styles.submitDisable)} disabled={showMessage} type={'submit'}>*/}
                    {/*    Save and Continue*/}
                    {/*</button>*/}
                </form>
            </div>
        </>
    );
};

export default LegalInfo;
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import {getCompanyMembers} from "../../store/selectors";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
    clearForms,
    deleteCompanyMemberPending,
    getCompanyMembersPending,
    updateCompanyMemberPending,
    clearMessage,
    // createDocumentPending,
} from "../../store/actions";
import {Dialog, Icon, Button, Loading} from "../common";

import MemberCard from "./members/MemberCard";

import styles from "../../assets/styles/business.module.scss";
import cx from 'classnames'

const Owners = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data, isLoading, message} = useSelector(getCompanyMembers)
    const [deleteModal, setDeleteModal] = useState({open: false})
    const [addMember, setAddMember] = useState(false)
    const [files, setFiles] = useState([])

    useOnClickOutside(ref, () => setAddMember(false));

    useEffect(() => {
        dispatch(clearForms())
        dispatch(clearMessage())
    }, [dispatch]);

    useEffect(() => {
        if(data?.length === 0) {
            dispatch(getCompanyMembersPending())
        }
    }, [dispatch, data.length]);

    const addRole = useCallback((id) => {
        dispatch(updateCompanyMemberPending({company_member_id: id, role: 'ubo'}))
    }, [dispatch])

    const openDeleteModal = useCallback((id, roles) => {
        const role = roles === 2 ? {role: 'ubo'} : {};
        setDeleteModal(prevState => ({...prevState, id, role, open: !prevState.open}))
    }, [])

    const handleDeleteAction = useCallback(() => {
        if(deleteModal?.id){
            dispatch(deleteCompanyMemberPending(deleteModal.id, deleteModal.role))
        }
    }, [deleteModal, dispatch])

    useEffect(() => {
        if(message === "Company member deleted"){
            dispatch(getCompanyMembersPending())
            setDeleteModal({open:false})
        }
        if(message === 'Company member updated') {
            setAddMember(false)
        }
    }, [message, dispatch])

    const uploadInputRef = useRef(null);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleCapture = useCallback(async (event) => {
        const data = event.target.files[0];
        if(data?.type === 'image/jpg'  || data?.type === 'application/pdf' || data?.type === 'image/png') {
            const file = await convertBase64(data)
            if(file) {
                // dispatch(createDocumentPending({document_name: data.name, file}))
                setFiles(prevState => [...prevState, event.target.files[0]])
            }
        }
    },[])

    return (
        <>
            {isLoading && <Loading className={cx(styles.businessLoading)} />}
            <div className={cx(styles.owner)}>
                <h3>Business Owner</h3>
                <p>Regulatory guidelines require us to collect and verify information on anyone who has significant ownership of your business.</p>
                <p>Please add any individual who owns 25% or more of the company (an Ultimate Beneficial Owner or ‘UBO’).</p>
                {data?.map(owner => owner.roles.find(item => item.role === 'ubo') &&
                    <MemberCard
                        id={owner.id}
                        key={owner.id}
                        name={`${owner.first_name} ${owner.last_name}`}
                        roles={owner.roles}
                        param={'owners'}
                        onDelete={openDeleteModal}
                    />
                )}
                <div className={cx(styles.addItem)}>
                    <div className={cx(styles.buttons)}>
                        <Button
                            text={'+ Add business owner'}
                            variant='outlined'
                            size={'lg'}
                            margin={{top: '40px'}}
                            onClick={() => setAddMember(prevState => !prevState)}
                        />
                    </div>
                    {addMember && <div ref={ref} className={cx(styles.content)} >
                        <button className={cx(styles.add)} onClick={() => navigate('/business/owners/create')}>Add someone new +</button>
                        <h3>Select someone</h3>
                        {data?.map(director => director.roles.find(item => item.role === 'director') && director.roles?.length === 1 &&
                            <button
                                key={director.id}
                                className={cx(styles.fullName)}
                                onClick={()=> addRole(director.id)}
                            >
                                {`${director.first_name} ${director.last_name}`}
                            </button>
                        )}
                    </div>}
                </div>
            </div>
            <div className={cx(styles.container)}>
                <div className={cx(styles.message, files.length > 0 && styles.background )}>
                    <p>
                        {files?.length === 0 && <Icon name={'warning'}/>}
                        Please upload a document or diagram describing the people and companies involved in the ownership structure.
                    </p>
                    <ul>
                        <h3>Please include:</h3>
                        <li> Name(s) of the company(ies) that has an ownership in the business opening the account.</li>
                        <li> Name(s) of the individual(s) associated with that business</li>
                    </ul>
                </div>
            </div>
            <div className={cx(styles.upload)}>
                <h3>Upload documentation</h3>
                <p>File formats accepted: PDF, PNG, JPG. Max file size 10MB.</p>
                <>
                    <input ref={uploadInputRef} type="file" accept="image/jpg, image/png, application/pdf" hidden style={{ display: "hidden" }} onChange={handleCapture}/>
                    <button className={cx(styles.outlinedButton)} onClick={() => uploadInputRef.current && uploadInputRef.current.click()}>
                        <p><Icon name={'upload'}/>Upload document(s)</p>
                    </button>
                </>

                <TableContainer className={cx(styles.table)}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File name</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((row) => (
                                <TableRow key={row.lastModified}>
                                    <TableCell>
                                        <div className={cx(styles.name)}>
                                            <Icon name={'file'}/>
                                            <p>{row.name}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell><p>{moment(new Date()).format('DD.MM.YY')}</p></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={cx(styles.buttonGroups)}>
                    <Button
                        text={'Go back'}
                        variant={'outlined'}
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        disabled={data?.length === 0}
                        text={'Save & continue'}
                        onClick={() => navigate('/business/directors') }
                    />
                </div>
            </div>
            <Dialog
                open={deleteModal.open}
                setOpen={(val) => setDeleteModal({open: val})}
                className={'deleteModal'}
                cancelAction={() => setDeleteModal({open: false})}
                submitAction={handleDeleteAction}
            >
                <p>Are you sure you want to delete business owner?</p>
            </Dialog>
        </>
    );
};

export default Owners;
import React, {useCallback, useEffect, useState} from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import {ThemeProvider} from "@emotion/react";
import moment from "moment";

import styles from "../../../assets/styles/common.module.scss";
import cx from 'classnames';
// import useOnClickOutside from "../../hooks/useOnClickOutside";

const DatePickerField = (props) => {
    const {
        label, required, name, error, helperText,
        onChange, value, day, month, year
    } = props;
    const [open, setOpen] = useState(false)
    const maxDate = moment().subtract(21, 'year');
    const minDate = moment().subtract(121, 'year');
    const [date, setDate] = useState(null)
    const [format, setFormat] = useState('dd/MM/yyyy')

    useEffect(() => {
        if(value){
            setDate(value)
            setFormat('dd/MM/yyyy')
        } else {
            if(day === null && month === null && year === null) {
                setFormat('dd/MM/yyyy')
                setDate(null)
            }else {
                if(day === null && month !== null && year !== null) {
                    setFormat('MM/yyyy')
                }
                if(day !== null && month === null && year !== null) {
                    setFormat('dd/yyyy')
                }
                if(day !== null && month !== null && year === null) {
                    setFormat('dd/MM')
                }
                setDate(new Date(year, month, day))
            }
        }
    }, [day, month, year, value])


    const [invalidFormat, setInvalidFormat] = useState('')

    const materialTheme = createTheme({
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: '15px',
                        height: '380px'
                    },
                }
            }
        }
    });

    const handleInputChange = useCallback((val) => {
        if(val && isNaN(val?.getTime())){
            setInvalidFormat('Please enter valid data.')
        } else {
            onChange(val)
            setInvalidFormat('')
        }
        setOpen(false)
        setFormat('dd/MM/yyyy')
    }, [onChange]);


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={cx(styles.label)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
            </div>
            <ThemeProvider theme={materialTheme}>
                <DatePicker
                    inputFormat={format}
                    date={new Date(date || maxDate)}
                    rawValue={date}
                    value={date}
                    name={name}
                    open={open}
                    onChange={(newValue) => handleInputChange(newValue)}
                    maxDate={new Date(maxDate)}
                    minDate={new Date(minDate)}
                    OpenPickerButtonProps={{ style: { display: 'none' } }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            error={error || !!invalidFormat}
                            helperText={helperText || invalidFormat}
                            onChange={(event) => handleInputChange(new Date(event.target.value))}
                            onClick={() => setOpen(prev => !prev)}
                            className={cx(styles.pickerField, invalidFormat && styles.invalid )}
                        />
                    }
                />
            </ThemeProvider>
        </LocalizationProvider>
    )
};

export default DatePickerField;
import {
    POST_CHECK_EMAIL_FAILURE,
    POST_CHECK_EMAIL_PENDING, POST_CHECK_EMAIL_SUCCESS

} from '../actionTypes';

const initialState = {
    data: {},
    isLoading: false,
    error: null
}

const checkEmail = (state = initialState, action) => {
    switch (action.type) {
        case POST_CHECK_EMAIL_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case POST_CHECK_EMAIL_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data
            };

        case POST_CHECK_EMAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default checkEmail;

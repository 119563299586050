import {call,  put, takeLatest } from 'redux-saga/effects';
import {
    postLoginSuccess, postLoginFailure,
    postSignupSuccess, postSignupFailure,
    changePasswordSuccess, changePasswordFailure,
    postForgotPasswordSuccess, postForgotPasswordFailure,
    postCheckEmailSuccess, postCheckEmailFailure, getUserDataSuccess, postLoginOtpSuccess, postLoginOtpFailure
} from '../actions';
import {
    CHANGE_PASSWORD_PENDING, POST_CHECK_EMAIL_PENDING,
    POST_FORGOT_PASSWORD_PENDING, POST_LOGIN_PENDING, POST_LOGIN_OTP_PENDING, POST_SIGNUP_PENDING
} from '../actionTypes';
import { request } from '../../utils/api';


function* postLoginWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            '/auth/login',
            option
        );

        yield put(postLoginSuccess(data))
    } catch (error) {
        const {data, status } = error.response;
        yield put(postLoginFailure(data?.message, status));
    }
}

export function* postLogin(){
    yield takeLatest(POST_LOGIN_PENDING, postLoginWorker);
}

function* postLoginOtpWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            '/auth/2fa-verify',
            option
        );

        yield put(postLoginOtpSuccess(data))

        if(data) {
            const user = yield call(
                request().get,
                `user-management/user-profile/${data.user_id}`
            );
            yield put(getUserDataSuccess(user.data))
        }
    } catch (error) {
        const {data, status } = error.response;
        yield put(postLoginOtpFailure(data?.detail, status));
    }
}

export function* postLoginOtp(){
    yield takeLatest(POST_LOGIN_OTP_PENDING, postLoginOtpWorker);
}


function* postSignupWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            '/auth/register',
            option
        );
        yield put(postSignupSuccess(data))
    } catch (error) {
        const {data, status } = error.response;
        yield put(postSignupFailure(data?.message || data?.detail, status));
    }
}

export function* postSignup(){
    yield takeLatest(POST_SIGNUP_PENDING, postSignupWorker)
}

function* changePasswordWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            '/auth/change-password',
            option
        );
        yield put(changePasswordSuccess(data))
    } catch (error) {
        yield put(changePasswordFailure(error));
    }
}

export function* changePassword(){
    yield takeLatest(CHANGE_PASSWORD_PENDING, changePasswordWorker)
}


//forgot-password
function* forgotPasswordWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            '/auth/forgot-password',
            option
        );
        yield put(postForgotPasswordSuccess(data))
    } catch (error) {
        const {data, status } = error.response;
        yield put(postForgotPasswordFailure(data?.message || data?.detail, status));
    }
}

export function* forgotPassword(){
    yield takeLatest(POST_FORGOT_PASSWORD_PENDING, forgotPasswordWorker)
}



function* postCheckEmailWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            'auth/check-email',
            option
        );
        yield put(postCheckEmailSuccess(data))
    } catch (error) {
        const {data, status } = error.response;
        yield put(postCheckEmailFailure(data?.message || data?.detail, status));
    }
}

export function* postCheckEmail(){
    yield takeLatest(POST_CHECK_EMAIL_PENDING, postCheckEmailWorker)
}

import {CHECK_IS_FORM_DIRTY, CLEAR_FORMS, IS_COMPLETED} from "../actionTypes";

export const checkIsFormDirty = (option) => ({
    type: CHECK_IS_FORM_DIRTY,
    option
})

export const clearForms = () => ({
    type: CLEAR_FORMS
})

export const isCompleted = (option) => ({
    type: IS_COMPLETED,
    option
})
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import {Button, Dialog, Icon, InputField, Loading, Messages, SelectField} from "../common";

import {clearForms, clearMessage, registerOwnerPending} from "../../store/actions";
import {getCompanyData, getCompanyMembers, registerOwner} from "../../store/selectors";
import useDebounce from "../../hooks/useDebounce";

import styles from "../../assets/styles/business.module.scss";
import cx from "classnames";
import { getCurrentUser } from "utils/auth";

const Summary = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const {data:regOwner, isLoading: loading, error} = useSelector(registerOwner)
    const {data: companyMembers} = useSelector(getCompanyMembers)
    const {data, isLoading} = useSelector(getCompanyData)
    const currentUser = getCurrentUser();

    const [isApplicant, setIsApplicant] = useState({open: false, applicant: false, other: false})
    const [selectedDirectorEmail, setSelectedDirectorEmail] = useState('')
    const [selectedDirector, setSelectedDirector] = useState({})
    const [directors, setDirectors] = useState([])
    const [owners, setOwners] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [ownerRegistered, setOwnerRegistered] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const userId = currentUser?.user_id ? currentUser?.user_id : currentUser?.user.id;

    useEffect(() => {
        dispatch(clearForms())
        dispatch(clearMessage())
    }, [dispatch]);

    useEffect(() => {
        const directorList = [];
        const ownerList = [];
        companyMembers.forEach(item => item.roles.forEach(role => {
            if(role.role === 'director') directorList.push({...item, value: item.id, label: item.first_name + ' ' + item.last_name})
            if(role.role === 'ubo') ownerList.push({...item, value: item.id, label: item.first_name + ' ' + item.last_name})
        }))
        setDirectors(directorList)
        setOwners(ownerList)
    }, [companyMembers])


    const email = useDebounce(selectedDirectorEmail, 1500)
    useEffect(() => {
        const regex =/.+@.+\.[A-Za-z]+$/
        const isValidEmail = regex.test(email);
        if(!isValidEmail && email ) {
            setEmailErrorMessage('Invalid Email address.');
        }
    }, [email])

    useEffect(() => {
        if(regOwner?.item?.id && !loading && ownerRegistered) {
            navigate(isApplicant?.applicant ? `/kyc/${userId}/${regOwner?.item?.token}` : '/dashboard/home')
        }
    }, [regOwner, loading, navigate, isApplicant, userId, ownerRegistered])

    const handleSave= useCallback(() => {
        if(isApplicant?.applicant === false && !selectedDirectorEmail) {
            setEmailErrorMessage("Director's email is required.");
        }
        setOwnerRegistered(true)
        const birth_day = selectedDirector.date_of_birth ? moment(selectedDirector.date_of_birth).day() : null;
        const birth_month = selectedDirector.date_of_birth ? moment(selectedDirector.date_of_birth).month() : null;
        const birth_year = selectedDirector.date_of_birth ? moment(selectedDirector.date_of_birth).year() : null;
        const option = {
            first_name: selectedDirector.first_name,
            last_name: selectedDirector.last_name,
            address_line_1: selectedDirector.residential_address?.[0]?.address_line_1,
            address_line_2: selectedDirector.residential_address?.[0]?.address_line_2,
            city: selectedDirector.residential_address?.[0]?.city,
            country: selectedDirector.residential_address?.[0]?.country,
            post_code: selectedDirector.residential_address?.[0]?.post_code,
            date_of_birth: selectedDirector.date_of_birth ? selectedDirector.date_of_birth : null,
            birth_day: selectedDirector.birth_day || birth_day,
            birth_month:  selectedDirector.birth_month || birth_month,
            birth_year: selectedDirector.birth_month || birth_year,
            email: isApplicant?.applicant ? currentUser.email ? currentUser.email : currentUser?.user.email : selectedDirectorEmail
        }
        dispatch(registerOwnerPending({...option, company_name: data?.legal_name}))
    }, [selectedDirector, dispatch, selectedDirectorEmail, isApplicant, data?.legal_name, currentUser])

    useEffect(() => {
        setErrorMessage(error)
    }, [error])

    const isValid = useMemo(() =>
        data?.id && (directors.length === 0 || owners.length === 0 || !data?.company_type_id || !data?.legal_name || !data?.industry_id),
        [data, directors.length, owners.length])

    const openApplicantModal = useCallback(() => {
        setOpenModal(prevState => !prevState)
        setErrorMessage('')
    },[])

    const cancelApplicantModal = useCallback(() => {
        setIsApplicant({applicant: false, other: true, open: true})
        setOpenModal(false)
    }, [])

    const submitApplicantModal = useCallback(() => {
        setIsApplicant({applicant: true, other: false, open: true})
        setOpenModal(false)
    }, [])

    const handleGoBack = useCallback(() => {
        setOpenModal(true)
        setIsApplicant({open: false, applicant: false, other: false})
        setSelectedDirectorEmail('')
        setEmailErrorMessage('')
        setErrorMessage('')
        setSelectedDirector({})
    }, [])

    const handleDirectorChange = useCallback((name, val) => {
        const director = directors.find(item => item.value === val)
        setSelectedDirector(director)
    },[directors])

    const getCompanyAddress = useCallback((info) => {
        return `${info?.address_line_1} ${info?.address_line_2 || ''} ${info?.city} United Kingdom ${info?.post_code}`;
    }, [])

    const address = useMemo(() => {
        const registration_address = {...data.registration_address?.[0]};
        const trading_address = {...data.trading_address?.[0]};
        delete registration_address?.id;
        delete trading_address?.id;
        let address_line = '';
        if(JSON.stringify(registration_address) === JSON.stringify(trading_address)){
            address_line = 'registered'
        }
        if(JSON.stringify(registration_address) !== JSON.stringify(trading_address) && data.trading_address?.length > 0){
            address_line = 'traded'
        }
        return address_line;
    }, [data])

    return (
        <>
            {errorMessage && <Messages text={error} variant={'error'}/>}
            {isLoading && <Loading className={cx(styles.businessLoading)} />}
            <div className={cx(styles.businessInfo)}>
                <h3>Summary</h3>
                <div className={cx(styles.summary)}>
                    <div className={styles.sectionTitle}>
                        <h5>Legal information </h5>
                        <button onClick={() => navigate('/business/legal-info')}><span>Edit</span><Icon name={'edit'} /></button>
                    </div>
                    {data?.legal_name && <>
                        <div className={cx(styles.summaryInfo)}>Country: <span>United Kingdom</span></div>
                        <div className={cx(styles.summaryInfo)}>Company legal name: <span>{data?.legal_name || 'N/A'}</span></div>
                        <div className={cx(styles.summaryInfo)}>Company registration number: <span>{data?.registration_number || 'N/A'}</span></div>
                        <div className={cx(styles.summaryInfo)}>Company address: <span>{getCompanyAddress(data?.registration_address?.[0]) || 'N/A'}</span></div>
                        <div className={cx(styles.summaryInfo)}>Trading address: <span>{
                            address === 'traded'
                                    ? getCompanyAddress(data?.trading_address?.[0])
                                    : address === 'registered'
                                        ? 'Same as company address'
                                        : 'N/A'
                            }</span>
                        </div>
                        <div className={cx(styles.summaryInfo)}>Type of company: <span>{data?.company_type?.name || 'N/A'}</span></div>
                    </>}
                    {data?.id && !data?.legal_name && <p className={cx(styles.errorMessage)}>No information captured. Please provide information</p>}
                        <div className={styles.sectionTitle}>
                        <h5>Business Owner</h5>
                        <button onClick={() => navigate('/business/owners')}><span>Edit</span><Icon name={'edit'} /></button>
                    </div>
                    {owners.length > 0 && owners.map((owner, i) =>
                        <div key={owner.id} className={cx(styles.summaryInfo)}>Owner {i+ 1}: <span>{owner.first_name + ' ' + owner.last_name }</span></div>
                    )}
                    {data?.id && owners.length === 0 && <p className={cx(styles.errorMessage)}>No information captured. Please provide information</p>}

                    <div className={styles.sectionTitle}>
                        <h5>Business Director</h5>
                        <button onClick={() => navigate('/business/directors')}><span>Edit</span><Icon name={'edit'} /></button>
                    </div>
                    {directors.length > 0 && directors.map((director, i) =>
                        <div key={director.id} className={cx(styles.summaryInfo)}>Director {i+ 1}: <span>{director.first_name + ' ' + director.last_name }</span></div>
                    )}
                    {data?.id && directors.length === 0 && <p className={cx(styles.errorMessage)}>No information captured. Please provide information</p>}

                    <div className={styles.sectionTitle}>
                        <h5>Business information</h5>
                        <button onClick={() => navigate('/business/info')}><span>Edit</span><Icon name={'edit'} /></button>
                    </div>
                    {data?.industry?.id && <>
                        <div className={cx(styles.summaryInfo)}>Company website: <span>{data?.website || 'N/A'}</span></div>
                        <div className={cx(styles.summaryInfo)}>Industry type: <span>{data?.industry?.name || ''}</span></div>
                        <div className={cx(styles.summaryInfo)}>Regulatory permission: <span>{data?.regulatory_permission_type || 'N/A'}</span></div>
                        <div className={cx(styles.summaryInfo)}>Expected monthly volume: <span>{data?.expected_monthly_volume || 'N/A'}</span></div>
                        <div className={cx(styles.summaryInfo)}>Source of funds: <span>{data?.fund_source?.name || 'N/A'}</span></div>
                    </>}
                    {data?.id && !data?.industry?.id && <p className={cx(styles.errorMessage)}>No information captured. Please provide information</p>}

                    {isValid && <div className={cx(styles.errorSection)}>
                        <Icon name={'warning'} /> You will need to fill out all required sections to continue.
                        Please add missing information and complete onboarding.
                    </div>}
                </div>
                {/*<button type={'button'}*/}
                {/*        className={cx(styles.submit, !isValid? styles.submitActive : styles.submitDisable)}*/}
                {/*        onClick={openApplicantModal}*/}
                {/*        disabled={isValid}>*/}
                {/*    Continue*/}
                {/*</button>*/}
                <Button
                    text={'Continue'}
                    size={'lg'}
                    disabled={isValid}
                    margin={{top: '40px'}}
                    onClick={openApplicantModal}
                />
            </div>

            <Dialog
                open={openModal}
                setOpen={(val) => setOpenModal(val)}
                className={'isApplicant'}
                isSingleButton={true}
                cancelButton={'I am not an authorised representative'}
                cancelAction={cancelApplicantModal}
                submitButton={'I am an authorised representative'}
                submitAction={submitApplicantModal}
            >
                <h3>This account must be activated by an authorised representative of the company</h3>
                <p>If that is not you, you can select a director for whom the authorisation request will be sent</p>
            </Dialog>
            <Dialog
                open={isApplicant.open }
                setOpen={() => setIsApplicant({applicant: false, other: false, open: false})}
                className={'confirmIdentity'}
                cancelButton={'Go back'}
                cancelAction={handleGoBack}
                submitButton={isApplicant.other ? 'Send' : 'Submit'}
                submitAction={handleSave}
            >
                {isApplicant.other ? <div className={cx(styles.directorsList)}>
                        {loading &&  <Loading className={styles.load}/>}
                        <h3>Select a Director to Approve the Application</h3>
                        <SelectField
                            name={'director'}
                            label={'Select director'}
                            onChange={handleDirectorChange}
                            options={directors}
                            value={selectedDirector?.value || ''}
                            className={'selectField'}
                        />
                        {selectedDirector?.value && <InputField
                            name={'email'}
                            label={'Director’s email'}
                            required={true}
                            value={selectedDirectorEmail}
                            onChange={(event) => {
                                setSelectedDirectorEmail(event.target.value);
                                setEmailErrorMessage('')
                            }}
                            error={!!emailErrorMessage}
                            helperText={emailErrorMessage}
                        />}
                    </div> :
                    <div className={cx(styles.directorsList)}>
                        {loading && <Loading className={styles.load}/>}
                        <h3>Confirm Own Identity</h3>
                        <p>Select which person you are from then list of Directors. Once you confirm that this is you, you will be asked to verify your identity</p>
                        <SelectField
                            name={'director'}
                            label={'Select director'}
                            onChange={handleDirectorChange}
                            options={directors}
                            value={selectedDirector?.value || ''}
                            className={'selectField'}
                        />
                    </div>}
            </Dialog>

        </>
    );
};

export default Summary;
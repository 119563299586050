import React, {useCallback, useState} from 'react';
import TransactionFilter from "../../../transactions/components/TransactionFilter";
import {actions} from "../../slice";
import {useDispatch, useSelector} from "react-redux";
import selectState from "../../selectors";
import {Grid} from "@mui/material";
import cx from "classnames";
import styles from "../../../transactions/transaction.module.scss";
import {Loading, Pagination} from "../../../../common";
import TransactionList from "./TransactionList";
import AccountInfo from "../accounts/AccountInfo";
import {useNavigate, useParams} from "react-router-dom";
import {Icon} from "../../../../common";
import { useSearchParams } from 'react-router-dom';
import {getCompanyData} from "store/selectors";

const  SubTransactions = () => {
    const {transactions, loading} = selectState();
    const [searchParams] = useSearchParams();
    const [params, setParams] = useState('');
    const dispatch = useDispatch();
    const {accountId} = useParams()
    const navigate = useNavigate();
    const companyData = useSelector(getCompanyData);

    const handleFilter = useCallback((queryParams='', page=1, size=10) => {
        setParams(queryParams);
        dispatch(actions.getSubAccountTransactions({account_id: accountId, page, size, queryParams}));
    }, [dispatch, accountId]);

    const handlePerPageChange = useCallback(( value) => {
        handleFilter(params, 1, value);
    }, [params, handleFilter]);

    const handlePageChange = useCallback((page, perPage) => {
        handleFilter(params, page, perPage);
    }, [params, handleFilter]);

    const backToSubAccounts = () => {
        navigate('/dashboard/sub-accounts');
    }

    return (
        <Grid container className={cx(styles.container)}>
            <Grid md={12} item style={{width: '100%'}}>
                <h3 className={cx(styles.legalName)}>{`${companyData?.data?.legal_name || ''}`}</h3>
            </Grid>
            <Icon
                title={'Go back to Sub Accounts'}
                name={'arrow-left'}
                height={'30px'}
                width={'30px'}
                style={{'marginTop': '3px'}}
                onClick={() => backToSubAccounts()} />
            <h1 className={cx(styles.title)}>Sub accounts transactions</h1>
            <AccountInfo
                className={cx(styles.accountInfo)}
                accountId={accountId}
                onRefresh={() => handleFilter(searchParams.toString())}/>
            <TransactionFilter onFilter={handleFilter} loading={loading}/>
            <Grid container>
                {loading && <Loading className={cx(styles.loading)}/>}
                {!loading && transactions?.total === 0 && <h3 className={cx(styles.noData)}>No data found</h3>}
                {!loading && transactions?.items?.length > 0 && <TransactionList transactions={transactions} />}
                {!loading && transactions?.items?.length > 0 && <Pagination
                    data={{ items: transactions?.items, page: transactions?.page, size: transactions?.size, total: transactions?.total}}
                    pageChange={handlePageChange}
                    perPageChange={handlePerPageChange}
                />}
            </Grid>
        </Grid>
    );
};

export default SubTransactions;
// import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    language: 'en-US',
    isLoading: false,
    isError: false,
    errorMessage: '',
    companyLogos: [],
    featuresSection: [],
    transactionFeatures: [],
    developerSection: {},
    solutions: [],
    insights: {},
    team: [],
    ourTeamSection: {},
    footerSection: {},
    isContactSuccess: false,
    isDisplayModal: false,
    isApiDocRequest: false,
    isDemoRequest: false,
    isSubmitting: false,
    isDrawerOpen: false,
    currentSection: "",
    route: "",
    heroSection: {
        id: null,
        title: '',
        description: '',
        buttonText:'',
        buttonUrl: '',
        image: {
            alternativeText: "",
            id: null,
            url: "/uploads/hero_image_65025bd11b.svg",
        },
    },
}

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        openModalForm: (state) => {
            state.isDisplayModal = true;
        },
        closeModalForm: (state) => {
            state.isDisplayModal = false;
            state.isApiDocRequest = false;
            state.isDemoRequest = false;
            state.isContactSuccess = false;
            state.isSubmitting = false;

        },
        isApiDoc: (state) => {
            state.isApiDocRequest = true;
            state.isDemoRequest = false;
            state.isContactSuccess = false;
            state.isSubmitting = false;
        },
        isDemoBook: (state) => {
            state.isApiDocRequest = false;
            state.isContactSuccess = false;
            state.isDemoRequest = true;
            state.isSubmitting = false;
        },
        drawerOpen: (state) => {
            state.isDrawerOpen = true;
        },
        drawerClose: (state) => {
            state.isDrawerOpen = false;
        },
        sectionSet: (state, action) => {
            state.currentSection = action.payload;
        },
        sectionReset: (state) => {
            state.currentSection = "";
        },
        routeSet: (state, action) => {
            state.route = action.payload;
        },
        resetRoute: (state) => {
            state.route = "";
        }

    },
});

export const { actions, reducer, name } = contentSlice;

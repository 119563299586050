import React, {useCallback, useEffect, useState} from 'react';
// import countryList from "react-select-country-list"; // should implement after showing all countries
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import {useFormik} from "formik";
import * as yup from "yup";

import {SelectField, Icon, Loading, Button} from "../common";
import {getCompanyDataPending} from "../../store/actions";
import {getCompanyData} from "../../store/selectors";

import styles from "../../assets/styles/dashboard.module.scss";
import cx from "classnames";

const Country = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const options = useMemo(() =>countryList().getData().filter(item => item.value !== 'GB' && item.value !== 'FR'),[]);
    // const filteredOption = [{value: "GB", label: 'United Kingdom'}, {value: "FR", label:"France"}, {value: "line"}, ...options]
    const filteredOption = [
        {value: "GB", label: "United Kingdom"},
        {value: "FR", label: "France"},
        {value: "line"},
        {label: "Afghanistan", value: "AF"},
        {label: "Åland Islands", value: "AX"},
        {label: "Albania", value: "AL"}

    ]
    const {data, isLoading} = useSelector(getCompanyData)
    const [isGB, setIsGB] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState({})

    const validationSchema = yup.object({
        country: yup
            .string('Select country')
            .required('Country is required field.'),
        // email: yup
        //     .string('Email field')
        //     .email('Enter a valid email'),
    });

    const formik = useFormik({
        initialValues: {
            country: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(values.country === 'GB') {
                // dispatch(updateCompanyPending({country: values.country}))
                navigate('/business/legal-info')
            } else {
                //TODO:need to change some logic.
                const data = filteredOption.find(item => item.value === values.country);
                setSelectedCountry(data)
                setIsGB(false)
            }
        },
    })

    useEffect(() => {
        const info = Object.keys(data);
        if(info?.length === 0 ){
            dispatch(getCompanyDataPending())
        }
    }, [dispatch, data])

    useEffect(() => {
        if(data?.registration_address?.length > 0 ){
            formik.setValues({
                country: data.registration_address[0].country
            })
        }
        // eslint-disable-next-line
    }, [data?.registration_address])

    // const handleChange = useCallback((event) => {
    //     formik.setValues(data => ({...data, [event.target.name]: event.target.value }))
    // }, [formik])

    const handleSelect = useCallback((name, value) => {
        formik.setValues(prev => ({...prev, [name]: value}))
    }, [formik])

    return (
        <div className={cx(styles.country)}>
            {isLoading && <Loading className={cx(styles.countryLoading)} />}
            {isGB ? <div className={cx(styles.flex)}>
                <h3>Select your country</h3>
                <Icon name={'www'}/>
            </div> :
            <div className={cx(styles.otherCountry)}>
                <h3>Merge is not available in the {selectedCountry?.label} yet
                    <ReactCountryFlag countryCode={selectedCountry?.value.toLowerCase()} svg className={cx(styles.flag)} />
                </h3>
                <p>We strive to expand globally and will be soon available in your location. Please leave
                    your email if you want to be notified when Merge is delivered to your country.
                    If the meantime, you can still explore the functionality of <span>test dashboard.</span>
                </p>

            </div> }
            <div className={cx(styles.selection)}>
                <form onSubmit={formik.handleSubmit}>
                    {isGB && <SelectField
                        name={'country'}
                        label={'Country'}
                        value={formik.values.country}
                        required={true}
                        disableItems={true}
                        fontIcons={true}
                        onChange={handleSelect}
                        options={filteredOption}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                        className={'selectField'}
                    />
                    //     :
                    // <InputField
                    //     name={'email'}
                    //     label={'Email address'}
                    //     value={formik.values.email}
                    //     onChange={handleChange}
                    //     error={formik.touched.email && Boolean(formik.errors.email)}
                    //     helperText={formik.touched.email && formik.errors.email}
                    // />
                    }
                    {/*<button className={cx(styles.button)} type="submit">*/}
                    {/*    <p>{isGB ? 'Continue' : 'Notify me'}</p>*/}
                    {/*</button>*/}
                    <Button
                        text={isGB ? 'Continue' : 'Notify me'}
                        size={'lg'}
                        margin={{top: '40px'}}
                        onClick={formik.handleSubmit}
                    />
                </form>
            </div>
        </div>
    );
};

export default Country;
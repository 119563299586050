import {call,  put, takeLatest } from 'redux-saga/effects';

import {
    postAuthorizeFailure,
    postAuthorizeSuccess, postGenerateOtpFailure,
    postGenerateOtpSuccess,
    updateKycStatusFailure,
    updateKycStatusSuccess, verifyOtpFailure, verifyOtpSuccess
} from '../actions';
import {
    PATCH_KYC_STATUS_PENDING,
    POST_AUTHORIZE_PENDING,
    POST_GENERATE_OTP_PENDING,
    VERIFY_OTP_PENDING
} from '../actionTypes';
import { request } from '../../utils/api';

function* updateKycStatusWorker({option}) {
    const url = `kyc-vendor/kyc/status/${option.user_profile_id}`
    try {
        const {
            data,
        } = yield call(
            request().patch,
            url,
            option
        );
        yield put(updateKycStatusSuccess(data))
    } catch (error) {
        yield put(updateKycStatusFailure(error));
    }
}

export function* updateKycStatus(){
    yield takeLatest(PATCH_KYC_STATUS_PENDING, updateKycStatusWorker);
}


function* postAuthorizeWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            `auth/authorize`,
            option
        );
        yield put(postAuthorizeSuccess(data))
    } catch (error) {
        yield put(postAuthorizeFailure(error));
    }
}

export function* postAuthorize(){
    yield takeLatest(POST_AUTHORIZE_PENDING, postAuthorizeWorker);
}


function* postGenerateOtpWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            'auth/generate-otp',
            option
        );
        yield put(postGenerateOtpSuccess(data))
    } catch (error) {
        const { response: {data} } = error;
        yield put(postGenerateOtpFailure(data?.message));
    }
}

export function* postGenerateOtp(){
    yield takeLatest(POST_GENERATE_OTP_PENDING, postGenerateOtpWorker);
}

function* verifyOtpWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            'auth/verify-otp',
            option
        );
        const message = data?.verified === true ? 'otp_verified' : 'otp_not_verified'
        yield put(verifyOtpSuccess(message))
    } catch (error) {
        yield put(verifyOtpFailure(error));
    }
}

export function* verifyOtp(){
    yield takeLatest(VERIFY_OTP_PENDING, verifyOtpWorker);
}


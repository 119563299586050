import { CHANGE_CURRENCY_TYPE } from '../actionTypes';

const initialState = {
    currency: localStorage.getItem('currency') || 'GBP',
}

const currency = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CURRENCY_TYPE: 
            return {
                ...state,
                currency: action.currency,
            }
        default: return {
            ...state,
        };
    }
}

export default currency;

import {put, takeLatest, call, select} from 'redux-saga/effects';
import {actions} from "./slice";
import Endpoints from 'utils/endpoints';
import {request} from "../../../utils/api";
import moment from "moment";
import { getCurrencyData } from 'store/selectors/currency';
const { accounts: {accounts, accountDetails, transactions}} = Endpoints;

export function* getAccounts(action) {
    const {type, allCurrencies, page, size, status, search } = action.payload; //type,
    try {
        const {currency} = allCurrencies ? {} : yield select(getCurrencyData);
        const {data} = yield call(request().get, accounts.url(type, currency, page, size, status, search));
        if(data) {
            yield put(actions.getAccountsSuccess({type, data}));
        }
    } catch (err) {
        yield put(actions.getAccountsError(err.message));
    }
}

export function* getSelectedAccount(action) {
    const {accountId } = action.payload;
    try {
        const {data} = yield call(request().get, accountDetails.url(accountId));
        if(data) {
            yield put(actions.getSelectedAccountSuccess({account: data}));
        }
    } catch (err) {
        yield put(actions.getSelectedAccountError(err.message));
    }
}

export function* getSubAccountTransactions(action) {
    const {account_id, page, size, queryParams } = action.payload;
    const searchParameters = new URLSearchParams(queryParams);
    const from_date = searchParameters.get('from_date');
    const to_date = searchParameters.get('to_date');

    if(from_date){
        // searchParameters.set('from_date', moment(from_date).format("X"))
        searchParameters.set('from_date', moment(from_date).format('YYYY-MM-DD')+'T00:00:00')
    }
    if(to_date){
        // searchParameters.set('to_date', moment(to_date).format("X"))
        searchParameters.set('to_date', moment(to_date).format('YYYY-MM-DD')+'T23:59:59')
    }

    try {
        const {data} = yield call(request().get, transactions.url(account_id, page, size, searchParameters.toString()))
        const groups = data?.items?.reduce((groups, transaction) => {
            const date = transaction.created_at.split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(transaction);
            return groups;
        }, {});
        data.items = Object.keys(groups).map((date) => ({date, transactions: groups[date]}));
        if(data) {
            yield put(actions.getSubAccountTransactionsSuccess(data));
        }
    } catch (err) {
        yield put(actions.getSubAccountTransactionsError(err.message));
    }
}

export function* accountsSaga() {
    yield takeLatest(actions.getAccounts.type, getAccounts);
    yield takeLatest(actions.getSelectedAccount.type, getSelectedAccount);
    yield takeLatest(actions.getSubAccountTransactions.type, getSubAccountTransactions);
}



import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PrivacyPolicyPdf from 'pdfs/privacy-policy/Privacy_Policy_Merge.pdf'
import { useNavigate } from "react-router-dom";
import { PRIVACY_POLICY_SECTIONS } from './Types'
import selectState from './selectors';
import {
    StyledGrid,
    StyledHeaderPage,
    StyledSubHeading,
    StyledBackButton,
    StyledSubText,
    StyledHighlightedPanel,
    StyledParagrapgh
 } from './styles';
import {Container, Icon} from "../common";



const PrivacyPolicyPage = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });

   useEffect(() => {
       window.scrollTo(0, 0);
   })

    const {
        currentSection
    } = selectState()


    return  (
    <>
        <Box id={"top"}>
                    <Container
                        sx={{
                            margin: 'unset',
                            paddingLeft: ['16px', '16px', '45px'],
                            paddingTop: ['0','70px','80px']
                        }}
                    >
                        <Grid container>
                            <Grid
                                item
                                md={7}
                            >
                                <StyledHeaderPage>
                                    Merge Privacy policy
                                </StyledHeaderPage>
                            </Grid>
                            {isMd &&
                                <Grid
                                    item
                                    md={5}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {isLg &&

                                        <div style={{
                                            marginRight: '20px'
                                        }}>
                                            <p style={{
                                                color: '#00A09B',
                                                marginTop: '10px'
                                            }}
                                            >
                                                <a
                                                    href={PrivacyPolicyPdf}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{color:'#00A09B',textDecoration: 'none'}}
                                                >
                                                    Download PDF
                                                    {' '}
                                                    <span style={{margin: '0px 10px', verticalAlign: 'text-top'}}>
                                                        <Icon name={'file-download'} />
                                                    </span>
                                                </a>
                                            </p>

                                        </div>

                                    }
                                    <div>
                                        <StyledBackButton onClick={() => navigate("/")}>
                                            Go back
                                        </StyledBackButton>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                        <Grid  container>
                            <Grid item md={12}
                                  sx={{
                                      margin: ['0 auto']
                                  }}
                            >
                                <Box
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <StyledSubHeading>
                                        Privacy Policy
                                    </StyledSubHeading>
                                </Box>
                            </Grid>
                        </Grid>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.PRIVACY_POLICY_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.PRIVACY_POLICY_SECTION)}>
                            <Grid item>
                                <p>
                                    <StyledSubText>
                                        Last updated: 27 May 2022
                                    </StyledSubText>
                                </p>
                                <StyledParagrapgh><i>Please note that this privacy policy will regularly be updated to reflect any changes in the way we handle your personal data or any changes in applicable laws.</i></StyledParagrapgh>

                                <StyledParagrapgh>This Privacy Policy describes how Merge (&ldquo;we&rdquo;,&ldquo;us&rdquo;, &ldquo;our&rdquo;, &ldquo;ourselves&rdquo;) collects and processes your personal information (&ldquo;you&rdquo;, &ldquo;your&rdquo;) through its website (www.mergedup.com) and applications that reference this Privacy Policy. By using the services provided by Merge, you are consenting to the collection, storage, processing, and transfer of your personal information as described in this Privacy Policy. The services are offered by Merge Money Ltd., the Merge Group companies and/or all its subsidiaries and affiliates. Merge Money Ltd. is incorporated in the United Kingdom with Companies House number 13463502 and registered office at 7 Bell Yard, London W2CA 2JR, United Kingdom. It is the Data Processor for personal information collected and processed in connection with the provision of the services. Merge Money Ltd. is registered with the UK&rsquo;s Information Commissioner&rsquo;s Office under reference number ZB283262.</StyledParagrapgh>

                                <StyledParagrapgh>If you have any specific questions about this Privacy Policy, you&rsquo;re welcome to contact us at <a href={"mailto:support@mergedup.com"}>support@mergedup.com</a>.</StyledParagrapgh>
                            </Grid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.PERSONAL_INFORMATION_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.PERSONAL_INFORMATION_SECTION)}>
                            <StyledGrid container>

                            <Grid item>
                                    <p className={'sub-heading'}>1. What personal information does Merge collect? Why does Merge process my personal information? What is the legal basis for the processing activities?
                                    </p>
                                </Grid>
                            </StyledGrid>
                            <StyledGrid container>
                                <Grid item>
                                    <StyledParagrapgh>This Privacy Policy outlines what data we collect from you, how we use this information, and how we take care in disclosing this information, in compliance with data protection laws, including the UK Data Protection Act 2018 and the General Data Protection Regulation (EU 2016/679).</StyledParagrapgh>

                                    <StyledParagrapgh>By accessing and reading our Privacy Policy, you acknowledge you have been informed about the processing of your personal data that we may process from you.</StyledParagrapgh>

                                    <StyledParagrapgh>In this statement we have used certain terms which are set out in the regulations:</StyledParagrapgh>

                                    <ul>
                                        <li style={{marginBottom: 0}}>personal data means: any information relating to an identified or identifiable natural person (data subject).</li>
                                        <li style={{marginBottom: 0}}>an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</li>
                                        <li style={{marginBottom: 0}}>controller means: the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.</li>
                                        <li style={{marginBottom: 0}}>processor means: a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller. </li>
                                        <li style={{marginBottom: 0}}>processing means: any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</li>
                                    </ul>

                                </Grid>
                            </StyledGrid>

                            <StyledGrid container mt={5}>
                                <Grid item lg={4} md={12} xs={12} sm={12} sx={{
                                    border: "1px solid #777777",
                                    borderRight: ['1px solid #777', '1px solid #777', '1px solid #777', '0']
                                }}>
                                    <div className={"heading"}>
                                        <StyledParagrapgh>
                                            What information and personal data does Merge collect and process?
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            Information related to company Directors, UBOs and Authorised persons, including:
                                        </StyledParagrapgh>
                                        <ul>
                                            <li>Email address</li>
                                            <li>Name</li>
                                            <li>Date of birth</li>
                                            <li>Home address</li>
                                            <li>Phone number</li>
                                            <li>Nationality</li>
                                            <li>Device ID</li>
                                            <li>A video recording</li>
                                            <li>Passport or ID</li>
                                        </ul>
                                    </div>
                                    <div className={"content-table"}>
                                        <ul>
                                            <li>
                                                The Internet protocol (IP) address used to connect your computer to the Internet
                                            </li>
                                            <li>
                                                Login, e-mail address, password and location of your device or computer
                                            </li>
                                            <li>
                                                Merge metrics (e.g., the occurrences of technical errors, your interactions with service features and content, and your settings preferences)
                                            </li>
                                            <li>
                                                Version and time zone settings
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={"content-table"}>
                                        <ul>
                                            <li>Transaction behaviour and history</li>
                                            <li>Information from other sources: we may receive information about you from other sources, such as PEP and sanctions lists providers or credit reference agencies</li>
                                        </ul>
                                    </div>
                                    <div className={"content-table"}>
                                        <ul>
                                            <li>Information about your behaviour: we may process information about you on your behaviour and your activity for marketing and advertising purposes</li>
                                        </ul>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            Information about your payees and payers
                                        </StyledParagrapgh>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={12} xs={12} sm={12} sx={{
                                    border: ["1px solid #777777"],
                                    borderTop: ['0','0','0','1px solid #777777' ]

                                }}>
                                    <div className={"heading"}>
                                        <StyledParagrapgh>
                                            Why does Merge process my personal information?
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <ul style={{marginTop: '16px'}}>
                                            <li>Communicate with you: We use your personal information to communicate with you in relation to Merge.</li>
                                            <li>We collect and process identity information and Sensitive Personal Data (as detailed in section 1) to comply with our Know Your Customer (“KYC”) obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations</li>
                                            <li>To administer your wallet and relationship with us</li>
                                            <li>To notify you about our services and changes thereto</li>
                                        </ul>
                                    </div>
                                    <div className={"content-table"}>
                                        <ul>
                                            <li>
                                                Provide, troubleshoot, and improve Merge: We use your personal information to provide functionality, analyse performance, fix errors, and improve the usability and effectiveness of our Services
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            Fraud prevention, money laundering and terrorist financing prevention and credit risks. We process personal information to prevent and detect fraud and abuse in order to protect the security of our users, Merge and others. We may also use scoring methods to assess and manage risks.
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <ul>
                                            <li>Improve our services: We process personal information to improve our services and for you to have a better user experience</li>
                                            <li>Recommendations and personalisation: We use your personal information to recommend features and services that might be of interest to you, identify your preferences, and personalise your experience with Merge.</li>
                                        </ul>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            When you use our services, we will record and collect information regarding any payments you receive or make, including their recipients or senders
                                        </StyledParagrapgh>
                                    </div>

                                </Grid>
                                <Grid item lg={4} md={12} xs={12} sm={12} sx={{
                                    border: "1px solid #777777",
                                    borderLeft:  ['1px solid #777', '1px solid #777', '1px solid #777', '0'],
                                    borderTop: ['0','0','0','1px solid #777777' ]

                                }}>
                                    <div className={"heading"}>
                                        <StyledParagrapgh>
                                            Legal base of our use of personal information (EU and UK GDPR)
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            <strong>Performance of a contract</strong><br /> when we provide you with products or services or communicate with you about them. This includes when we use your personal information to take and handle orders, and process payments.
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            <strong>Legal requirements</strong> that we need to comply with concerning identifying customers, preventing fraud and/or abuse and avoiding non-compliance.
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            <strong>Our legitimate interests</strong> and the interests of our users when, for example, we detect and prevent fraud, money laundering, terrorist financing and abuse in order to protect the security of our users, ourselves, or others.
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            <strong>Your consent</strong> when we ask for your consent to process your personal information for a specific purpose that we communicate to you. When you consent to processing your personal information for a specified purpose, you may withdraw your consent at any time and we will stop processing your data for that purpose.
                                        </StyledParagrapgh>
                                    </div>
                                    <div className={"content-table"}>
                                        <StyledParagrapgh>
                                            <strong>Performance of a contract</strong> when we provide you with products or services or communicate with you about them. This includes when we use your personal information to take and handle orders, and process payments.
                                        </StyledParagrapgh>
                                    </div>

                                </Grid>
                            </StyledGrid>
                            <Grid container>
                                <Grid item>
                                    <StyledParagrapgh>
                                        We firmly believe that where the above table states that we rely on our legitimate interests for a given purpose, this is not overridden by your interests, rights or freedoms.
                                        We will not use your personal data for purposes that are incompatible with the purposes of which you have been informed, unless it is required or authorised by law, or it is in your own vital interest to do so.
                                    </StyledParagrapgh>
                                </Grid>
                            </Grid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.SENSITIVE_DATA_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.SENSITIVE_DATA_SECTION)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                       2. Sensitive data
                                    </p>
                                    <StyledParagrapgh>
                                        The term Sensitive Data refers to the various categories of personal data identified by data privacy laws or regulations as requiring special treatment, including in some circumstances the need to obtain explicit consent from you. These categories include racial or ethnic origin, political opinions, religious, philosophical or other similar beliefs, membership of a trade union, physical or mental health, biometric or generic data, sexual life or orientation, or criminal convictions and offences (including information about suspected criminal activities).
                                        Due to the type of services provided we might need to process certain types of sensitive data. In the limited cases where we do collect such data, we will do this in accordance with data privacy laws and regulations and/or ask for your consent.

                                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.COOKIES_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.COOKIES_SECTION)}>

                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                       3. What about cookies and other identifiers?
                                    </p>
                                    <StyledParagrapgh>
                                        We use cookies and similar tools to enhance your user experience, provide our services, and understand how customers use our services so we can make improvements, for more information about how we use cookies please visit our Cookie Policy.
                                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.SHARE_PERSONAL_INFORMATION_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.SHARE_PERSONAL_INFORMATION_SECTION)}>

                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                      4. Does Merge share my personal information?
                                    </p>
                                    <StyledParagrapgh>
                                        Information about our users is an important part of our business and we are not in the business of selling our users' personal information to others. Merge shares users' personal information and Sensitive Personal Data for the Merge services only as described below and with the subsidiaries or affiliates of Merge that are either subject to this Privacy Policy or follow practices at least as protective as those described in this Privacy Policy.
                                    </StyledParagrapgh>
                                    <ul>
                                        <li><u>Third party service providers:</u> We employ other companies and individuals to perform functions on our behalf. Examples include analysing data, providing marketing assistance, processing payments, transmitting content, assessing and managing risk, fraud prevention, transaction monitoring and screening, IT and cloud storage, performing identity verification and other KYC/KYB functions. These third-party service providers have access to personal information and Sensitive Personal Data needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with this Privacy Policy and as only permitted by applicable data protection laws.</li>

                                        <li><u>Business transfers:</u> As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, user information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Policy . Also, in the unlikely event that Merge or substantially all of its assets are acquired, user information will be one of the transferred assets.</li>

                                        <li><u>Protection of Merge and others:</u> We release accounts and other personal information when we believe such release is appropriate to comply with the law or with our regulatory obligations; enforce or apply our Terms of Use and other agreements; or protect the rights, property or safety of Merge, our users or others. This includes exchanging information with other companies and organisations for fraud protection and credit risk reduction, and with regulatory agencies and law enforcement to comply with lawful requests.</li>
                                    </ul>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.INFORMATION_TRANSFER_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.INFORMATION_TRANSFER_SECTION)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                       5. International transfers of personal data
                                    </p>
                                    <StyledParagrapgh>
                                        Merge may transfer your data outside of the European Economic Area (“EEA”). Merge puts in place suitable safeguards to ensure that such transfers are carried out in compliance with applicable data protection rules, except where the country to which the data is transferred has already been determined by the European Commission to provide an adequate level of protection.
                                        Where Merge  transfers personal data to affiliates or service providers, Merge relies on the latest standard contractual clauses of the European Commission.
                                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.SECURE_INFORMATION_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.SECURE_INFORMATION_SECTION)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                       6. How secure is my information?
                                    </p>
                                    <StyledParagrapgh>
                                        We design our systems with your security and privacy in mind. We work to protect the security of your personal information during transmission by using encryption protocols and software.
                                        We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of your personal information. Our security procedures mean that we may ask you to verify your identity before we disclose personal information to you.
                                        It is important for you to protect against unauthorised access to your account password. We recommend using a unique password for your Merge account that is not used for other online accounts. Be sure to sign off when you finish using a shared computer.

                                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.ABOUT_ADVERTISING} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.ABOUT_ADVERTISING)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                      7.  What about advertising?
                                    </p>
                                    <StyledParagrapgh>
                                        We may share your personal information with our marketing partners for the purposes of targeting, modelling, and/or analytics as well as marketing and advertising. You may opt out of receiving directly from the emails you receive.
                                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.INFORMATION_ACCESS_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.INFORMATION_ACCESS_SECTION)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                      8.  What information can I access?
                                    </p>
                                    <StyledParagrapgh>
                                        You can access your information, including your name, address, payment options, profile information, and transaction history in the "Your Account" section of the website.                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.RIGHTS_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.RIGHTS_SECTION)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                      9.  What rights do I have?
                                    </p>
                                    <StyledParagrapgh>
                                        If you have any questions or objections as to how we collect and process your personal information, please contact <a href={"mailto:support@mergedup.com"}>support@mergedup.com</a>.

                                        When you consent to our processing your personal information for a specified purpose, you may withdraw your consent at any time and we will stop any further processing of your data for that purpose.

                                        In addition, subject to applicable law, you have the right to request access to, correct, and delete your personal data, and to ask for data portability. You may also object to our processing of your personal data or ask that we restrict the processing of your personal data in certain instances. If you wish to do any of these things, please contact <a href={"mailto:support@mergedup.com"}>support@mergedup.com</a>.

                                        You also have the right to make a complaint if you feel that we have not handled your personal data properly. You may submit a complaint to the relevant supervisory data protection authority, in the UK this is the Information Commissioner’s Office (<a href={"https://ico.org.uk/"} target={"_blank"} rel="noreferrer">https://ico.org.uk/</a>) and in the EEA country where you reside. For a list of all authorities per country, please check here: <a href={"https://edpb.europa.eu/about-edpb/about-edpb/members_en"} target={"_blank"} rel="noreferrer">https://edpb.europa.eu/about-edpb/about-edpb/members_en</a>
                                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.MY_PERSONAL_INFORMATION_SECTION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.MY_PERSONAL_INFORMATION_SECTION)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                      10. How long does Merge keep my personal information?
                                    </p>
                                    <StyledParagrapgh>
                                        We keep your personal information to enable your continued use of Merge Services, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Policy, as may be required by law such as for tax and accounting purposes, or as otherwise communicated to you. Additionally, identity information, transactional information and Sensitive Personal Data may be retained for up to eight years to comply with Anti-Money Laundering laws.</StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.CONTACT_INFORMATION} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.CONTACT_INFORMATION)}>
                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                       11. Contact information
                                    </p>
                                    <StyledParagrapgh>
                                        Our data protection officer can be contacted at <a href={"mailto:support@mergedup.com"}>support@mergedup.com</a> , and will work to address any questions or issues that you have with respect to the collection and processing of your personal information.</StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                        <StyledHighlightedPanel container id={PRIVACY_POLICY_SECTIONS.CONDITIONS_OF_USE} isSelected={currentSection && currentSection.includes(PRIVACY_POLICY_SECTIONS.CONDITIONS_OF_USE)}>

                            <StyledGrid container>
                                <Grid item>
                                    <p className={'sub-heading'}>
                                      12. Conditions of use, notices and revisions
                                    </p>
                                    <StyledParagrapgh>
                                        If you choose to use Merge Services, your use and any dispute over privacy is subject to this Privacy Policy and our Terms of Use. If you have any concerns about privacy at Merge , please contact us with a thorough description, and we will try to resolve it. You also have the right to contact your local Data Protection Authority. In the UK this is the Information Commissioner’s Office (<a href={"https://ico.org.uk/"} target={"_blank"} rel="noreferrer">https://ico.org.uk/</a>).

                                        Our business changes constantly, and our Privacy Policy will change also. You should check our websites frequently to see recent changes and  we will notify our clients of any material changes. If you do not agree with the revised content, you shall stop accessing Merge Services immediately. When an updated version of the Privacy Policy is released, your continued access to Merge Services means that you agree to the updated content and agree to abide by the updated Privacy Policy. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and your account.
                                    </StyledParagrapgh>
                                </Grid>
                            </StyledGrid>
                        </StyledHighlightedPanel>
                    </Container>

                </Box>
    </>
    )
}

export default PrivacyPolicyPage;
import {
    CLEAR_MEMBERS_DATA,
    DELETE_COMPANY_MEMBER_FAILURE, DELETE_COMPANY_MEMBER_PENDING, DELETE_COMPANY_MEMBER_SUCCESS,
    GET_COMPANY_MEMBER_DATA_FAILURE, GET_COMPANY_MEMBER_DATA_PENDING, GET_COMPANY_MEMBER_DATA_SUCCESS,
    GET_COMPANY_MEMBERS_FAILURE, GET_COMPANY_MEMBERS_PENDING, GET_COMPANY_MEMBERS_SUCCESS,
    POST_COMPANY_MEMBER_FAILURE, POST_COMPANY_MEMBER_PENDING, POST_COMPANY_MEMBER_SUCCESS,
    UPDATE_COMPANY_MEMBER_FAILURE, UPDATE_COMPANY_MEMBER_PENDING, UPDATE_COMPANY_MEMBER_SUCCESS
} from '../actionTypes';

const initialState = {
    data: [],
    isLoading: false,
    error: null,
    message: '',
    member: {}
}

const companyMembers = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_MEMBERS_PENDING:
        case GET_COMPANY_MEMBER_DATA_PENDING:
            return {
                ...state,
                error: null,
                message: '',
                isLoading: true,
                member: {}
            };

        case CLEAR_MEMBERS_DATA:
            return {
                data: [],
                isLoading: false,
                error: null,
                message: '',
                member: {}
            }

        case DELETE_COMPANY_MEMBER_PENDING:
        case UPDATE_COMPANY_MEMBER_PENDING:
        case POST_COMPANY_MEMBER_PENDING:
            return {
                ...state,
                message: '',
                isLoading: false,
                error: null,
                member: {}
            }

        case GET_COMPANY_MEMBERS_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data.items
            };

        case GET_COMPANY_MEMBER_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                member: action.data
            }

        case UPDATE_COMPANY_MEMBER_SUCCESS:
            const prevState = state.data.filter(item => item.id !== action.data.item.id )
            return {
                ...state,
                error: null,
                isLoading: false,
                message: action.data.message,
                member: {},
                data: [...prevState, action.data.item],
            }

        case POST_COMPANY_MEMBER_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                member: {},
                data: [...state.data, action.data.item],
            }

        case DELETE_COMPANY_MEMBER_SUCCESS:
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.data.id ),
                message: action.data.message,
                isLoading: false

            }

        case GET_COMPANY_MEMBERS_FAILURE:
        case GET_COMPANY_MEMBER_DATA_FAILURE:
        case POST_COMPANY_MEMBER_FAILURE:
        case DELETE_COMPANY_MEMBER_FAILURE:
        case UPDATE_COMPANY_MEMBER_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: '',
                error: action.error
            };

        default:
            return state;
    }
};

export default companyMembers;

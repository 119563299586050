import * as React from 'react';
import {
    Radio,
    RadioGroup as MuiRadioGroup,
    FormControlLabel,
    FormControl,
} from '@mui/material';
import cx from 'classnames';
import styles from './radio-group.module.scss';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import styled from "styled-components";


const StyledLabel = styled.div`
  margin-bottom: 10px;
`;

const StyledRequired = styled.span`
  color: #FF8888;
  margin-bottom: 10px;
`;

const RadioGroup = (props) => {
    const {
        label,
        onChange,
        options,
        defaultValue,
        isRow,
        required,
        error,
        helperText,
        className='',
        disabled = false,
    } = props;

    const useStyles = makeStyles(() => ({
        selected: {
            color: '#00A09B !important',
            opacity: disabled ? 0.5 : 1,
        },
    }));
    const classes = useStyles();


    return (
        <div className={cx(styles['radio-group'], styles[className])}>
            <FormControl className={disabled ? styles.disabled : '' }>
                <StyledLabel>{label}{' '} {required && <StyledRequired>*</StyledRequired>}</StyledLabel>
                <MuiRadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    value={defaultValue}
                    // defaultValue={defaultValue}
                    row={isRow}
                    name="radio-buttons-group"
                >
                    {options.map((option, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                value={option.value}
                                control={<Radio classes={{
                                    checked: classes.selected,
                                }} />}
                                className={disabled ? styles.disabled : ''}
                                disabled={disabled}
                                label={option.label}
                                onChange={onChange}
                                name={option.type}
                            />
                        );
                    })}
                </MuiRadioGroup>
                {error && required && <span className={cx(styles['error'])}>{helperText}</span>}
            </FormControl>
        </div>
    );
}

export default RadioGroup;

RadioGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    defaultValue: PropTypes.string,
    isRow: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
}
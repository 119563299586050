import {
    CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_PENDING, CHANGE_PASSWORD_SUCCESS, CLEAR_SET_PASSWORD
} from "../actionTypes";

const initialState ={
    password: {},
    loading: false,
    error: null
}

const password = (state=initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_PENDING:
            return {
                ...state,
                loading: true,
            }

        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                password: action.data
            }

        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                password: {},
                loading: false,
                error: action.error
            }
        case CLEAR_SET_PASSWORD:
            return {
                ...state,
                password: {},
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}

export default password;
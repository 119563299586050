import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {changePasswordPending, postAuthorizePending} from "../../store/actions";
import {getAuthorizeToken, getPasswordData} from "../../store/selectors";

import {InputField, PasswordStrength, Loading, Icon} from "../common";
import styles from "../../assets/styles/auth.module.scss";
import cx from 'classnames'

const ResetPassword = () => {
    const {userId, token} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const {password, loading} = useSelector(getPasswordData)
    const {authorizeToken} = useSelector(getAuthorizeToken)

    const [toggleIcon, setToggleIcon] = useState('show-password')
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        dispatch(postAuthorizePending({token: token, user_id: userId}))
    }, [dispatch, userId, token])


    const validationSchema = yup.object({
        password: yup
            .string('Enter your password')
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                "Password should contain at least 8 characters with 1 upper case letter and 1 number"
            ),
        confirmPassword: yup
            .string('Confirm your password')
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required')
    });

    const formik = useFormik({
        initialValues: {
            // email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(changePasswordPending({password: values.password, token:authorizeToken, user_id: userId}))
            // setTimeout(() => localStorage.removeItem('authorizeToken') , 5000)
        },
    })

    // const [resetForm] = useState(() => formik.resetForm)

    const handleChange = useCallback((event) => {
        formik.setValues(values => ({...values, [event.target.name]: event.target.value }))
        setErrorMessage('')
    }, [formik])

    const toggleShowPassword = useCallback(() => {
        setToggleIcon(toggleIcon === 'show-password' ? 'hide-password': 'show-password')
    }, [toggleIcon])

    const handleNavigate = useCallback(() => {
        navigate('/login');
        localStorage.clear()
    },[navigate]);

    return (
        <div className={cx(styles.resetPassword)}>
            {loading && <Loading className={cx(styles.loading)} />}
            {password?.message === 'CHANGED' ?
                <>
                    <h3 className={cx(styles.title)}>Your password was changed successfully <Icon name={'checkmark'}/></h3>
                    <button className={cx(styles.submitBtn)} type="button" onClick={handleNavigate}>
                        <p>Back to log in</p>
                    </button>
                </>
                : <>
                    <h3 className={cx(styles.title)}>Set password</h3>
                    <form onSubmit={formik.handleSubmit} className={cx(styles.form)}>
                        <InputField
                            type={toggleIcon === 'show-password' ? 'password' : 'text'}
                            name={'password'}
                            label={'New password'}
                            required={true}
                            value={formik.values.password}
                            onChange={handleChange}
                            handleIconClick={toggleShowPassword}
                            hasIcon={true}
                            iconPosition="end"
                            iconName={toggleIcon}
                            error={(formik.touched.password && Boolean(formik.errors.password)) || errorMessage}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <InputField
                            type={toggleIcon === 'show-password' ? 'password' : 'text'}
                            name={'confirmPassword'}
                            label={'Repeat password'}
                            required={true}
                            value={formik.values.confirmPassword}
                            onChange={handleChange}
                            handleIconClick={toggleShowPassword}
                            iconName={toggleIcon}
                            error={(formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)) || errorMessage}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                        {formik.values.password && <PasswordStrength password={formik.values.password}/>}
                        <button className={cx(styles.submitBtn)} type="submit">
                            <p>Set password</p>
                        </button>
                    </form>
                </>
            }
        </div>
    );
};

export default ResetPassword;
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { useSelector } from 'react-redux';

export const selectDomain = (state) =>
    state.recipients || initialState;

export const select = () => createSelector([selectDomain], state => state);

/* eslint-disable */
export default () => useSelector(select());

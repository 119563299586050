import * as yup from "yup";

export const legalInfoFormValidation = yup.object({
    country: yup.string().required('Country is required.'),
    legal_name: yup.string().nullable().required('Company name is required.'),
    registration_number: yup.string().nullable().required('Company registration number is required.'),
    company_type_id: yup.string().nullable().required('Country type is required.'),
    address_line: yup.string().nullable().required('Trading address is required.'),
    registration_address: yup.object({
        city: yup.string().required('City is required.'),
        address_line_1: yup.string().required('Address first line is required.'),
        address_line_2: yup.string(),
        post_code: yup.string().required('Post code is required.')
    }),
    trading_address: yup.object().when('address_line', {
        is: 'traded',
        then: yup.object({
            city: yup.string().required('City is required.'),
            address_line_1: yup.string().required('Address first line is required.'),
            address_line_2: yup.string(),
            post_code: yup.string().required('Post code is required.')
        })
    })
});

export const businessInfoFormValidation = yup.object({
    website: yup
        .string('Enter company website')
        .matches(/\S+.\S+\.\S+/, 'URL is not valid.'),
    industry_id: yup
        .string('Select industry')
        .required('Industry type is required.'),
    industry_specification: yup
        .string('Enter your industry specification')
        .when('industry_id', {
            is: val => val === '817c645e-7779-4c58-81ba-1925149abf50',
            then: yup.string().required('Industry specification is required.')
        }),
    regulatory_permission_type: yup
        .string('Select regulatory permission')
        .required('Regulatory Permission is required.'),
    license_type: yup
        .string('Enter license type'),
    license_number: yup
        .string('Enter license number'),
    name_of_authority: yup
        .string('Enter name of authority'),
    expected_monthly_volume: yup
        .string('Select monthly values'),
    fund_source_id: yup
        .string('Select source of funds'),
    fund_source_specification: yup
        .string('Enter fund source specification'),
});

export const createMemberValidation = yup.object({
    first_name: yup
        .string('Enter first name')
        .required('First name is required.'),
    last_name: yup
        .string('Enter last name')
        .required('Last name is required.'),
    date_of_birth: yup
        .string('Enter user birth date')
        .nullable(),
    city: yup
        .string('Enter city name')
        .required('City name is required.'),
    address_line_1: yup
        .string('Enter address line 1')
        .required('Address line is required.'),
    address_line_2: yup
        .string('Enter address line 2')
        .nullable(),
    post_code: yup
        .string('Enter post code.')
        .required('Post code is required.'),
    country: yup
        .string('Select country name')
        .nullable(),
    // .required('Country name is required.'),
})

import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as yup from "yup";

import Background from "../layouts/Background";
import {InputField, Loading} from "../common";

import {clearPasswordMessage, postForgotPasswordPending} from "../../store/actions";
import {getForgotPasswordData} from "../../store/selectors";

import styles from "../../assets/styles/auth.module.scss";
import cx from 'classnames'

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {data, loading, error} = useSelector(getForgotPasswordData)
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState({header: '', title: '', button: ''});

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Please enter a valid email address')
    });
    useEffect(() => {
        dispatch(clearPasswordMessage())
    }, [dispatch])

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(postForgotPasswordPending(values))
            // dispatch(clearPasswordMessage())
        },
    })

    useEffect(() => {
        if(error === 'USER_NOT_FOUND') {
            setErrorMessage(`We couldn't find this email. Please try again`)
        }
    }, [error])

    useEffect(() => {
        if(data?.message === 'FORGOT_PASSWORD') {
            setSuccessMessage({
                title: 'Check your email for instructions to reset your password',
                paragraph: 'If you have not received an email in 5 minutes, check your spam folder or click resend.',
                button: 'Resend password'
            })
        }
    }, [data])

    // const [resetForm] = useState(() => formik.resetForm)

    const handleChange = useCallback((event) => {
        formik.setValues(data => ({...data, [event.target.name]: event.target.value }))
        setErrorMessage('')
        dispatch(clearPasswordMessage())
    }, [formik, dispatch])

    const navigateToLogin = useCallback(() => {
        dispatch(clearPasswordMessage())
        navigate('/login')
    }, [navigate, dispatch])

    return (
        <>
            <Background>
                <div className={cx(styles.auth)}>
                    {loading && <Loading className={cx(styles.authLoading)} />}
                    <div className={cx(styles.authForm)}>
                        <h3 className={cx(styles.title)}>{data.message ? successMessage?.title: 'Reset your password'}</h3>
                        <p className={cx(styles.paragraph)}>{data?.message ? successMessage?.paragraph : 'Enter email associated with your account and we’ll send the link to reset your password.'}</p>
                        <form className={cx(styles.form)} onSubmit={formik.handleSubmit}>
                            {!data?.message && <InputField
                                name={'email'}
                                label={'Email address'}
                                value={formik.values.email}
                                onChange={handleChange}
                                error={(formik.touched.email && Boolean(formik.errors.email)) || errorMessage}
                                helperText={(formik.touched.email && formik.errors.email) || errorMessage}
                            />}
                            <button className={cx(styles.submitBtn)} type="submit">
                                <p>{data?.message ? successMessage?.button : 'Reset password'}</p>
                            </button>
                        </form>
                        <p className={cx(styles.haveAccount)}><button onClick={navigateToLogin}>Return to sign in</button></p>
                    </div>
                </div>
            </Background>
            {/*{errorMessage && <Messages text={errorMessage} variant={'error'}/>}*/}
        </>
    );
};

export default ForgotPassword;
export const PATCH_KYC_STATUS_PENDING = '@KYC/PATCH_KYC_STATUS_PENDING';
export const PATCH_KYC_STATUS_SUCCESS = '@KYC/PATCH_KYC_STATUS_SUCCESS';
export const PATCH_KYC_STATUS_FAILURE = '@KYC/PATCH_KYC_STATUS_FAILURE';

export const POST_GENERATE_OTP_PENDING = '@KYC/POST_GENERATE_OTP_PENDING';
export const POST_GENERATE_OTP_SUCCESS = '@KYC/POST_GENERATE_OTP_SUCCESS';
export const POST_GENERATE_OTP_FAILURE = '@KYC/POST_GENERATE_OTP_FAILURE';

export const VERIFY_OTP_PENDING = '@KYC/VERIFY_OTP_PENDING';
export const VERIFY_OTP_SUCCESS = '@KYC/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = '@KYC/VERIFY_OTP_FAILURE';

export const CLEAR_OTP_CODE = '@KYC/CLEAR_OTP_CODE';
export const RESET_PHONE_NUMBER_STATE = '@KYC/RESET_PHONE_NUMBER_STATE';
import React, {useCallback, useRef, useState} from 'react';
import {MenuItem, Select} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@emotion/react";
import ReactCountryFlag from "react-country-flag";


import CustomTooltip from "./CustomTooltip";
import Icon from "./Icon";

import styles from "../../assets/styles/common.module.scss";
import cx from 'classnames';

const SelectField = (props) => {
    const ref = useRef(null);
    const {className, name, options, required, label, value, onChange, helperText, error, disable, disableItems, fontIcons, info, placeHolderText} = props;
    const [focus, setFocus] = useState(false)

    const handleChange = useCallback(event => {
        onChange(event.target.name, event.target.value)
    }, [onChange])

    const materialTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: '100%'
                    }
                }
            }
        }
    });

    return (
        <div style={{height: error ? '110px' : '80px'}}>
            <div className={cx(disable ? styles.disable : styles.label)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
                {info && <CustomTooltip info={info}><Icon name={'info'}/></CustomTooltip>}
            </div>
            <ThemeProvider theme={materialTheme} >
                <div className={cx([error ? styles.errorBorder : styles.default, focus && styles.focus, styles[className]])}>
                    <Select
                        ref={ref}
                        name={name}
                        disabled={disable}
                        id="demo-controlled-open-select"
                        value={value || ''}
                        defaultValue={value || ''}
                        error={error}
                        onFocus={() => setFocus(prevState => !prevState)}
                        onChange={handleChange}
                    >
                    {placeHolderText &&  
                        <MenuItem disabled value="">
                            <em>{placeHolderText}</em>
                        </MenuItem>
                    }
                    {options?.map(option =>
                        <MenuItem
                            style={{maxWidth: ref?.current?.offsetWidth , marginLeft: 0}}
                            value={option.value || ''}
                            defaultValue={option.value || ''}
                            key={option.value}
                            disabled={name === 'country' && disableItems && option.value !== 'GB' }
                            className={cx([styles.selectMenuItem, option.value === value && styles.selectMenuItemActive])}
                        >
                            {fontIcons && <ReactCountryFlag countryCode={option.value.toLowerCase()} svg className={cx(styles.flag)} />
                            }
                            <p style={{whiteSpace: 'break-spaces'}}>{option.label}</p>
                            {option.value === value && <Icon name={'checkmark'} className={cx(styles.checkmark)}/>}
                            {option.value === 'line' && <div className={cx(styles.divider)}/>}
                        </MenuItem>
                    )}
                    </Select>
                    {helperText && <p className={cx(styles.asterisk)}>{helperText}</p>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default SelectField;
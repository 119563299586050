import {
    CLEAR_PASSWORD_MESSAGE,
    POST_FORGOT_PASSWORD_FAILURE,
    POST_FORGOT_PASSWORD_PENDING, POST_FORGOT_PASSWORD_SUCCESS
} from "../actionTypes";

const initialState ={
    data: {},
    loading: false,
    error: null
}

const forgotPassword = (state=initialState, action) => {
    switch (action.type) {
        case POST_FORGOT_PASSWORD_PENDING:
            return {
                ...state,
                loading: true,
            }

        case POST_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data
            }

        case POST_FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                data: {},
                loading: false,
                error: action.message
            }

        case CLEAR_PASSWORD_MESSAGE:
            return {
                data: {},
                loading: false,
                error: null
            }

        default:
            return state;
    }
}

export default forgotPassword;
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";

import {
    checkIsFormDirty, clearForms, clearMessage,
    getFundSourcesPending, getIndustryTypePending,
    updateCompanyPending, getCompanyMembersPending
} from "../../store/actions";

import {businessInfoFormValidation} from "./validation";
import {monthlyValues, permissions} from "./mockData";
import {getCompanyData, getFundSources, getIndustryType,} from "../../store/selectors";

import {InputField, SelectField, Loading, Button} from "../common";
import styles from "../../assets/styles/business.module.scss";
import cx from 'classnames';

export const BusinessInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {data, isLoading, message} = useSelector(getCompanyData)
    const industryTypes = useSelector(getIndustryType)

    const fundSources = useSelector(getFundSources)
    const [allowShowModal, setAllowShowModal] = useState(false)

    useEffect(() => {
        dispatch(clearForms())
        dispatch(clearMessage())
        dispatch(getCompanyMembersPending())
    },[dispatch])


    useEffect(() => {
        if(industryTypes?.length === 0) {
            dispatch(getIndustryTypePending())
        }
    }, [dispatch, industryTypes])

    useEffect(() => {
        if(fundSources?.length === 0) {
            dispatch(getFundSourcesPending())
        }
    }, [dispatch, fundSources])

    const formik = useFormik({
        initialValues: {
            website: '',
            industry_id: '',
            industry_specification:'',
            regulatory_permission_type:'',
            license_type:'',
            license_number:'',
            name_of_authority:'',
            expected_monthly_volume:'',
            fund_source_id: '',
            fund_source_specification:''
        },
        validationSchema: businessInfoFormValidation,
        onSubmit: (values) => {
            setAllowShowModal(false)
            dispatch(clearForms())
            const options = Object.entries(values).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
            dispatch(updateCompanyPending({
                ...options,
                expected_monthly_volume: monthlyValues.find(val => val.value === values.expected_monthly_volume)?.label,
                regulatory_permission_type: permissions.find(val => val.value === values.regulatory_permission_type)?.label,
            }))
        }
    })

    useEffect(() => {
        if(data?.id){
            const permissionType = permissions.find(val => val.label === data.regulatory_permission_type)?.value
            formik.setValues({
                website: data.website || "",
                industry_id: data.industry_id || "",
                industry_specification: data.industry_specification || "",
                regulatory_permission_type: permissionType || "",
                license_type: data.license_type || "",
                license_number: data.license_number || "",
                name_of_authority: data.name_of_authority || "",
                expected_monthly_volume: monthlyValues.find(val => val.label === data.expected_monthly_volume)?.value || "",
                fund_source_id: data.fund_source_id || "",
                fund_source_specification: data.fund_source_specification || ""
            })
        }

        // eslint-disable-next-line
    }, [data, monthlyValues])

    const handleChange = useCallback((event) => {
        setAllowShowModal(true)
        formik.setValues((prevValue => ({...prevValue, [event.target.name]: event.target.value})))
    }, [formik])

    const handleSelect = useCallback((name, value) => {
        setAllowShowModal(true)
        formik.setValues(prev => ({...prev, [name]: value}))
    }, [formik])

    useEffect(() => {
        if(formik.dirty && allowShowModal){
            dispatch(checkIsFormDirty('businessInfo'))
        }
        // eslint-disable-next-line
    },[dispatch, allowShowModal])

    useEffect(() => {
        if(message === 'Company updated') {
            navigate('/business/summary')
        }
    },[message, navigate])

    return (
        <>
            {isLoading && <Loading className={cx(styles.businessLoading)} />}
            <div className={cx(styles.businessInfo)}>
                <h3>Business Information</h3>
                <form onSubmit={formik.handleSubmit}>
                    <InputField
                        name={'website'}
                        label={'Company website'}
                        value={formik.values.website}
                        onChange={handleChange}
                        error={formik.touched.website && Boolean(formik.errors.website)}
                        helperText={formik.touched.website && formik.errors.website}
                    />
                    <SelectField
                        name={'industry_id'}
                        label={'Industry type'}
                        value={formik.values.industry_id}
                        required={true}
                        onChange={handleSelect}
                        options={industryTypes}
                        error={formik.touched.industry_id && Boolean(formik.errors.industry_id)}
                        helperText={formik.touched.industry_id && formik.errors.industry_id}
                        className={'selectField'}
                    />
                    {formik.values.industry_id === '817c645e-7779-4c58-81ba-1925149abf50' && <InputField
                        name={'industry_specification'}
                        label={'Specify industry type'}
                        required={true}
                        value={formik.values.industry_specification}
                        onChange={handleChange}
                        error={formik.touched.industry_specification && Boolean(formik.errors.industry_specification)}
                        helperText={formik.touched.industry_specification && formik.errors.industry_specification}
                    />}
                    <SelectField
                        name={'regulatory_permission_type'}
                        label={'Regulatory Permission'}
                        required={true}
                        options={permissions}
                        onChange={handleSelect}
                        info={'Some businesses require regulatory permission. Please provide details if this applies to your business.'}
                        value={formik.values.regulatory_permission_type}
                        error={formik.touched.regulatory_permission_type && Boolean(formik.errors.regulatory_permission_type)}
                        helperText={formik.touched.regulatory_permission_type && formik.errors.regulatory_permission_type}
                        className={'selectField'}
                    />
                    {(formik.values.regulatory_permission_type === 'yes'
                        || formik.values.regulatory_permission_type === 'in_process_of_authorisation')
                    && <>
                        <InputField
                            name={'license_type'}
                            label={'License type'}
                            value={formik.values.license_type}
                            onChange={handleChange}
                            error={formik.touched.license_type && Boolean(formik.errors.license_type)}
                            helperText={formik.touched.license_type && formik.errors.license_type}
                        />
                        <InputField
                            name={'license_number'}
                            label={'License number (if available)'}
                            value={formik.values.license_number}
                            onChange={handleChange}
                            error={formik.touched.license_number && Boolean(formik.errors.license_number)}
                            helperText={formik.touched.license_number && formik.errors.license_number}
                        />
                        <InputField
                            name={'name_of_authority'}
                            label={'Name of Financial or Regulatory Authority'}
                            value={formik.values.name_of_authority}
                            onChange={handleChange}
                            error={formik.touched.name_of_authority && Boolean(formik.errors.name_of_authority)}
                            helperText={formik.touched.name_of_authority && formik.errors.name_of_authority}
                        />
                    </>}
                    <SelectField
                        name={'expected_monthly_volume'}
                        label={'Expected monthly volume (deposits) with Merge'}
                        info={'Approximately how much do you expect to be depositing in your Merge account each month?'}
                        onChange={handleSelect}
                        options={monthlyValues}
                        value={formik.values.expected_monthly_volume}
                        error={formik.touched.expected_monthly_volume && Boolean(formik.errors.expected_monthly_volume)}
                        helperText={formik.touched.expected_monthly_volume && formik.errors.expected_monthly_volume}
                        className={'selectField'}
                    />
                    <SelectField
                        name={'fund_source_id'}
                        label={'Source of funds'}
                        info={'Where do you expect the funds deposited in your account to mostly have originated from?'}
                        onChange={handleSelect}
                        options={fundSources}
                        value={formik.values.fund_source_id}
                        error={formik.touched.fund_source_id && Boolean(formik.errors.fund_source_id)}
                        helperText={formik.touched.fund_source_id && formik.errors.fund_source_id}
                        className={'selectField'}
                    />
                    {formik.values.fund_source_id === '216a3d2f-405d-4f20-a289-f1039a942d70' && <InputField
                        name={'fund_source_specification'}
                        label={'Specify source of funds'}
                        value={formik.values.fund_source_specification}
                        onChange={handleChange}
                        error={formik.touched.fund_source_specification && Boolean(formik.errors.fund_source_specification)}
                        helperText={formik.touched.fund_source_specification && formik.errors.fund_source_specification}
                        className={'mt-24'}
                    />}
                    {/*<button className={cx(styles.legalButton)} type={'submit'}>*/}
                    {/*    <p>Save and Continue</p>*/}
                    {/*</button>*/}
                    <Button
                        text={'Save and Continue'}
                        size={'lg'}
                        margin={{top: '40px'}}
                        onClick={formik.handleSubmit}
                    />
                </form>
            </div>
        </>
    )
};

export default BusinessInfo;
import React from "react";
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import PropTypes from "prop-types";
import {getInitials } from "utils/helpers";
import Icon from "./Icon";
import {createTheme, ThemeProvider} from "@mui/material/styles";

//Needs to be wrapped by a parent List component,when used in a parent component
const CustomList = (props) => {
    const {
        title,
        subtitle,
        avatar,
        actions,
        customClass,
        avatarClass,
        collapsibleContent,
        onClick,
        icon,
        disabled = false
    } = props;

    const materialTheme = createTheme({
        components: {
            MuiListItemText: {
               styleOverrides: {
                   primary: {
                       fontSize: '16px'
                   },
                   secondary: {
                       fontSize: '14px',
                       width: '33%'
                   }
               }
           },
            MuiListItemSecondaryAction: {
                styleOverrides: {
                    root: {
                        fontSize: '18px',
                        fontWeight: 500
                    }
                }
            }
        }
    });

    return (
        <React.Fragment>
            <ThemeProvider theme={materialTheme}>
                <ListItem
                    key = {title}
                    // secondaryAction={
                    //     actions && actions
                    // }
                    className={`${customClass} ${disabled ? 'Disabled' : ""}`}
                    onClick={onClick}
                >
                    {avatar &&
                        <ListItemAvatar>
                            <Avatar src={avatar} className={`${avatarClass}`} style={{
                                background: disabled ? 'rgba(196, 196, 196, 0.5)' : 'rgb(0, 160, 155, 0.3)'
                            }}>
                                {icon ? <Icon name={icon}/> : title ? getInitials(title) ? getInitials(title): 'N/A': "N/A"}
                            </Avatar>
                        </ListItemAvatar>
                    }
                    <ListItemText
                        primary={title}
                        secondary={subtitle ? subtitle : null}
                    />
                    {actions }
                </ListItem>
                {collapsibleContent && collapsibleContent}
            </ThemeProvider>
        </React.Fragment>
    )
};

export default CustomList;

CustomList.propTypes = {
    title: PropTypes.string, //.isRequired,
    subtitle: PropTypes.string,
    avatar: PropTypes.string,
    actions: PropTypes.node,
    customClass: PropTypes.string,
    avatarClass: PropTypes.string,
    collapsibleContent: PropTypes.node,
    onClick: PropTypes.func,
    icon: PropTypes.string,
};
import {
    CLEAR_OWNER_DATA,
    REGISTER_OWNER_FAILURE, REGISTER_OWNER_PENDING, REGISTER_OWNER_SUCCESS
} from '../actionTypes';

const initialState = {
    data: {},
    isLoading: false,
    error: null
}

const registerOwner = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_OWNER_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case REGISTER_OWNER_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data
            };

        case REGISTER_OWNER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.message
            };

        case CLEAR_OWNER_DATA:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: {}
            };

        default:
            return state;
    }
};

export default registerOwner;

export const GET_COMPANY_MEMBERS_PENDING = '@COMPANY_MEMBERS/GET_COMPANY_MEMBERS_PENDING';
export const GET_COMPANY_MEMBERS_SUCCESS = '@COMPANY_MEMBERS/GET_COMPANY_MEMBERS_SUCCESS';
export const GET_COMPANY_MEMBERS_FAILURE = '@COMPANY_MEMBERS/GET_COMPANY_MEMBERS_FAILURE';

export const GET_COMPANY_MEMBER_DATA_PENDING = '@COMPANY_MEMBERS/GET_COMPANY_MEMBER_DATA_PENDING';
export const GET_COMPANY_MEMBER_DATA_SUCCESS = '@COMPANY_MEMBERS/GET_COMPANY_MEMBER_DATA_SUCCESS';
export const GET_COMPANY_MEMBER_DATA_FAILURE = '@COMPANY_MEMBERS/GET_COMPANY_MEMBER_DATA_FAILURE';

export const UPDATE_COMPANY_MEMBER_PENDING = '@COMPANY_MEMBERS/UPDATE_COMPANY_MEMBER_PENDING';
export const UPDATE_COMPANY_MEMBER_SUCCESS = '@COMPANY_MEMBERS/UPDATE_COMPANY_MEMBER_SUCCESS';
export const UPDATE_COMPANY_MEMBER_FAILURE = '@COMPANY_MEMBERS/UPDATE_COMPANY_MEMBER_FAILURE';

export const POST_COMPANY_MEMBER_PENDING = '@COMPANY_MEMBERS/POST_COMPANY_MEMBER_PENDING';
export const POST_COMPANY_MEMBER_SUCCESS = '@COMPANY_MEMBERS/POST_COMPANY_MEMBER_SUCCESS';
export const POST_COMPANY_MEMBER_FAILURE = '@COMPANY_MEMBERS/POST_COMPANY_MEMBER_FAILURE';

export const DELETE_COMPANY_MEMBER_PENDING = '@COMPANY_MEMBERS/DELETE_COMPANY_MEMBER_PENDING';
export const DELETE_COMPANY_MEMBER_SUCCESS = '@COMPANY_MEMBERS/DELETE_COMPANY_MEMBER_SUCCESS';
export const DELETE_COMPANY_MEMBER_FAILURE = '@COMPANY_MEMBERS/DELETE_COMPANY_MEMBER_FAILURE';

export const REGISTER_OWNER_PENDING = '@COMPANY_MEMBERS/REGISTER_OWNER_PENDING';
export const REGISTER_OWNER_SUCCESS = '@COMPANY_MEMBERS/REGISTER_OWNER_SUCCESS';
export const REGISTER_OWNER_FAILURE = '@COMPANY_MEMBERS/REGISTER_OWNER_FAILURE';

export const CLEAR_MEMBERS_DATA = '@COMPANY_MEMBERS/CLEAR_MEMBERS_DATA';
export const CLEAR_OWNER_DATA = '@COMPANY_MEMBERS/CLEAR_OWNER_DATA';


export const CREATE_DOCUMENT_PENDING = '@COMPANY_MEMBERS/CREATE_DOCUMENT_PENDING';
export const CREATE_DOCUMENT_SUCCESS = '@COMPANY_MEMBERS/CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = '@COMPANY_MEMBERS/CREATE_DOCUMENT_FAILURE';

import {takeLatest } from 'redux-saga/effects';
import { CHANGE_CURRENCY_TYPE } from 'store/actionTypes';
import { restartApp } from 'store/reducers';

function* changeCurrencyTypeWorker({currency}) {
    localStorage.setItem('currency', currency);
    yield restartApp();
}

export function* changeCurrencyType(){
    yield takeLatest(CHANGE_CURRENCY_TYPE, changeCurrencyTypeWorker)
}

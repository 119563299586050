import {
    POST_GENERATE_OTP_PENDING, POST_GENERATE_OTP_SUCCESS, POST_GENERATE_OTP_FAILURE,
    VERIFY_OTP_PENDING, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE, CLEAR_OTP_CODE,
} from "../actionTypes";

const initialState ={
    data: {},
    isLoading: false,
    error: null,
    message: '',
}

const otp = (state=initialState, action) => {
    switch (action.type) {
        case POST_GENERATE_OTP_PENDING:
        case VERIFY_OTP_PENDING:
            return {
                ...state,
                isLoading: true,
            }

        case CLEAR_OTP_CODE:
            return {
                data: {},
                isLoading: false,
                error: null,
                message: '',
            }

        case POST_GENERATE_OTP_SUCCESS:
            return {
                ...state,
                message: '',
                isLoading: false,
                data: action.data,
            }


        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.data,
            }

        case POST_GENERATE_OTP_FAILURE:
        case VERIFY_OTP_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: '',
                error: action.error
            }

        default:
            return state;
    }
}

export default otp;
import React from "react";
import PropTypes from "prop-types";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomAlert = (props) => {
    const { open, onClose, message, severity, type } = props;

    if(type === 'notification') {
        return (<Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar> );
    }

    return (<Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
            {message}
        </Alert>);
}

export default CustomAlert;

CustomAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.string.isRequired
}
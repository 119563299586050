import { createSlice } from '@reduxjs/toolkit'

/*
 *  @desc The transactions slice manages the state of the transactions
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: {
        status: false,
        message: ''
    },
    transactions: {
        items: [],
        total: null,
        page: null,
        size: null
    },
    account: null,
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        getTransactions(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        getTransactionsSuccess(state, action) {
            state.loading = false;
            state.transactions = action.payload;
            state.error = {
                status: false,
            };
        },
        getTransactionsError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true
            };
        },
        getAccount: (state, action) => {
            state.account = action.payload ? action.payload : state.account;
            // state.transactions = [];
            state.error = {
                status: false,
                message: ''
            }
        },
        getAccountSuccess: (state, action) => {
            state.account = action.payload ? action.payload : state.account
        },
        getAccountError: (state) => {
            state.account = null
        },

    }
})

export const { actions, reducer, name } = transactionsSlice;

import { createSlice } from '@reduxjs/toolkit'


/*
 *  @desc The recipients slice manages the state of the recipients
 *  slice
 *  reducer
 *  actions
 */
export const initialState = {
    loading: false,
    error: false,
    recipients: {
        items: [],
        total: null,
        page: null,
        size: null
    },
    addRecipientSuccess: false,
    isAddRecipientPage: false,
    isNickNameEdit: false,
    isFromSendFundsPage: false,
    fromAccount: null,
    addedRecipient: null,
}

export const recipientsSlice = createSlice({
    name: 'recipients',
    initialState,
    reducers: {
        getRecipients(state) {
            state.loading = true;
            state.error = false;
        },
        getRecipientsSuccess(state, action) {
            state.loading = false;
            state.recipients = action.payload;
            state.error = false;
        },
        getRecipientsError(state, action) {
            state.loading = false;
            state.error = true;
        },

        createRecipients(state) {
            state.loading = true;
            state.addRecipientSuccess = false;
            state.error = false;
        },
        createRecipientsSuccess(state, action) {
            state.loading = false;
            state.error = false;
            state.addRecipientSuccess = true;
            state.addedRecipient = action.payload;

        },
        createRecipientsError(state) {
            state.loading = false;
            state.addRecipientSuccess = false;
            state.error = true;
        },
        resetAddRecipientSuccess(state) {
            state.addRecipientSuccess = false;
        },
        updateRecipients(state) {
            state.loading = true;
            state.error = false;
        },
        updateRecipientsSuccess(state) {
            state.loading = false;
            state.error = false;
        },
        updateRecipientsError(state) {
            state.loading = false;
            state.error = true;
        },
        deleteRecipients(state) {
            state.loading = true;
            state.error = false;
        },
        deleteRecipientsSuccess(state) {
            state.loading = false;
            state.error = false
        },
        deleteRecipientsError(state) {
            state.loading = false;
            state.error = true;
        },
        addRecipientPage(state) {
            state.isAddRecipientPage = true;
            state.error = false;
        },
        addRecipientPageReset(state) {
            state.isAddRecipientPage = false;
            state.error = false;
        },
        editNickName(state) {
            state.isNickNameEdit = true;
            state.error = false;
        },
        resetEditNickName(state) {
            state.isNickNameEdit = false;
            state.error = false;
        },
        addRecipientFromSendFundsPage(state, action) {
            state.isFromSendFundsPage = true;
            // while dispatching it from add recipients page, payload would be undefined 
            state.fromAccount = action.payload?.fromAccount?.id;
        },
        resetRecipients: () => initialState,
    },
})

export const { actions, reducer, name } = recipientsSlice;

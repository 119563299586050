import styled from "styled-components";
import Grid from "@mui/material/Grid";


export const StyledGrid = styled(Grid)`
    color: #111326;
    .heading, .content-table {
      border-bottom: 1px solid #777;
      
      
      &:last-child {
        border-bottom: 0;
      }
    }
  .heading {
    height: 150px;
    padding: 32px 40px;

    @media (min-width: 1200px) {
      border-top: 0;
    }

    @media (min-width: 1281px) {
      //border-top: 1px solid #777;
    }
    p {
      font-weight: 600;
    }
  }
  .content-table {
   
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 32px 40px;
    
    @media (min-width: 1200px) {
      height:600px;
    }

    @media (min-width: 1281px) {
      height:650px;
    }
    
    ul {
      padding-left: 20px;
      margin-top: 16px;
      li {
        color: #111326;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    
    &:last-child {
      @media (min-width: 1200px) {
        height: 350px;
        border-bottom: 0;
      }
      @media (min-width: 1281px) {
        height: 250px;
        border-bottom: 1px solid #777;

      }
    }
  }
  .content {
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }
  .sub-heading {
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 32px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  ul {
    padding-left: 20px;
    li {
      color: #111326;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

    ul {
      padding-left: 20px;
      li {
        color: #111326;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

`;

export const StyledHeaderPage = styled.h1`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  margin-top: 0;
`;

export const StyledSubHeading = styled.h3`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 36px auto;
  color: #00A09B;

`;

export const StyledBackButton = styled.div`
  /* pure-white-100 */
  color: #00A09B;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  background: #FFFFFF;
  /* merge-turquiose-100 */

  border: 1px solid #00A09B;
  border-radius: 15px;
  padding: 7px 40px;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    background: rgba(0, 160, 155, 0.19);
  }
`;

export const StyledSubText = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  margin-top: 16px;
  margin-bottom: 16px;
  /* identical to box height, or 225% */
  
  /* dark-grey-100 */

  color: #777777;
`;

export const StyledHighlightedPanel = styled(Grid)`
  background: ${props => props.isSelected ? 'rgba(0,160,155,0.08)' : ''};
  margin-left: -45px;
  padding: 16px 35px 18px 45px;
  width: 110%;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111326;
  
  @media (max-width: 800px) {
    margin-left: -25px;
    padding-left: 25px;
  }
  
  @media (min-width: 1200px) {
    width: 105%;
  }
  ul {
    padding-left: 20px;
    li {
      color: #111326;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
      u {
        text-decoration: none;
        border-bottom: 1px solid #111326;
      }
    }
  }
`;

export const StyledParagrapgh = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111326;
  margin-top: 16px;
  margin-bottom: 16px;
  a {
    color: #00A09B;
  }
`;
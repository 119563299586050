import {
    CHECK_IS_FORM_DIRTY, CLEAR_FORMS, IS_COMPLETED
} from "../actionTypes";

const initialState = {
    legalInfo: false,
    businessOwner: false,
    businessDirector: false,
    businessInfo: false,

    legalInfoCompleted: false,
    businessOwnerCompleted: false,
    businessDirectorCompleted: false,
    businessInfoCompleted: false,
    summaryCompleted: false
}

const layout = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_FORMS:
            return {
                ...state,
                legalInfo: false,
                businessOwner: false,
                businessDirector: false,
                businessInfo: false
            };

        case CHECK_IS_FORM_DIRTY:
            return {
                ...state,
                [action.option]: true
            };

        case IS_COMPLETED:
            return {
                ...state,
                ...action.option
            }

        default:
            return state;
    }
};

export default layout;
import React, {useState, useEffect} from 'react';
import { Grid } from '@mui/material';
import styles from "./recipients.module.scss";
import cx from "classnames";
import RecipientsList from "./components/RecipientsList";
import RecipientsAdd from "./components/RecipientsAdd";
import { useDispatch, useSelector } from 'react-redux';
import {actions as recipientsActions, actions} from './slice';
import selectState from './selectors';
import CustomAlert  from 'components/common/CustomAlert';
import { Dialog } from "components/common";
import {
    useNavigate
} from "react-router-dom";
import {
    actions as fundActions,
} from 'components/dashboard/funds/slice';
import {Icon} from "../../common";
import { isProd } from "utils/helpers";
import { getCurrencyData } from 'store/selectors/currency';

import {getCompanyData} from "store/selectors";

/**
 * @description Recipients page loads the list of recipients and the add new recipient form
 * @param {object} props
 * @returns {JSX}
 */

const Recipients = () => {
    const dispatch = useDispatch();

    const [isError, setIsError] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {currency} = useSelector(getCurrencyData);
    const companyData = useSelector(getCompanyData);

    let navigate = useNavigate();

    const {
        loading,
        error,
        recipients,
        addRecipientSuccess,
        isAddRecipientPage,
        isFromSendFundsPage,
        fromAccount,
        addedRecipient,
    } = selectState();

    const handleBeneficiaryClick = (index) => {
        setSelectedIndex(index);
    }
    const handleAddRecipient = () => {
        isAddRecipientPage ?  dispatch(actions.addRecipientPageReset()) : dispatch(actions.addRecipientPage());
    };
    useEffect(() => {
        if(!isAddRecipientPage) {
            dispatch(actions.getRecipients());
        }
    }, [dispatch, isAddRecipientPage, currency]);

    useEffect(() => {
        if(addRecipientSuccess && !isModalOpen && !isFromSendFundsPage) {
            setIsModalOpen(true);
        }
        if(isFromSendFundsPage && addRecipientSuccess && addedRecipient) {
            dispatch(fundActions.setSelectedRecipient(addedRecipient));
            dispatch(fundActions.setCurrentFundStep(1));
            const fundsUrl = "/dashboard/funds" + (fromAccount ? "?from="+fromAccount: "");
            navigate(fundsUrl, { replace: true });
        }
    }, [addRecipientSuccess, fromAccount, isModalOpen, addedRecipient, dispatch, navigate, isFromSendFundsPage]);


    return (
        <Grid container className={cx(styles.container)}>
            <CustomAlert
                open={isError}
                message={"Something went wrong. Please try again later."}
                severity='error'
                type='notification'
                onClose={() => setIsError(false)}
            />
            <Grid md={12} item style={{width: '100%'}}>
                <h3 className={cx(styles.legalName)}>{`${companyData?.data?.legal_name || ''}`}</h3>
            </Grid>
            <Grid item xs={12} className={cx(styles.titleWrapper)}>
                <h1 className={cx(styles.title)}>Recipients</h1>
            </Grid>
            <Grid container>
                {!isAddRecipientPage &&
                    <RecipientsList
                        recipients={recipients}
                        handleBeneficiaryClick={handleBeneficiaryClick}
                        selectedIndex={selectedIndex}
                        isEmpty={recipients?.items.length === 0}
                        isError={error}
                        isLoading={loading}
                        handleAddRecipient={handleAddRecipient}
                />}
                {isAddRecipientPage &&
                    <>
                        <RecipientsAdd
                            isError={error}
                            isLoading={loading}
                            cancelAdd={handleAddRecipient}
                        />
                    </>
                }
            </Grid>
                <Dialog
                    fullWidth
                    maxWidth={'xl'}
                    open={addRecipientSuccess && !isFromSendFundsPage}
                    isSingleButton={true}
                    className={'recipients-modal'}
                    setOpen={() => {
                        dispatch(actions.resetAddRecipientSuccess());
                        handleAddRecipient();
                    }}
                    onClose={() => {
                        dispatch(actions.resetAddRecipientSuccess());
                        handleAddRecipient();
                    }}
                    cancelButton="Back to list"
                    submitButton={!isProd ? '+ Send funds' : null}
                    submitAction={() => {
                        if(!isProd) {
                            dispatch(recipientsActions.addRecipientFromSendFundsPage(undefined));
                            dispatch(fundActions.setSelectedRecipient(addedRecipient));
                            dispatch(fundActions.setCurrentFundStep(1));
                            const fundsUrl = "/dashboard/funds" + (fromAccount ? "?from="+fromAccount: "");
                            navigate(fundsUrl);                            
                        }
                    }}
                    cancelAction={() => {
                        handleAddRecipient();
                        dispatch(actions.resetAddRecipientSuccess())
                    }}
                    backdropProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255, 0.8)'
                        }
                    }}
                >
                  <div className={cx(styles['modal-content'])}>
                      <h2>
                          All set! Your recipient has been successfully added
                      </h2>
                      <Icon name={'modal-image'}/>
                      {/*<img src={successModalImage} alt="Success" />*/}
                  </div>
                </Dialog>

        </Grid>
    );
};

export default Recipients;
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';

// Components
import Background from "../layouts/Background";
import { PasswordStrength, InputField, Messages, Loading } from "../common";

// Actions
import useDebounce from "../../hooks/useDebounce";
import {
  clearErrors,
  getCompanyDataPending,
  postCheckEmailPending,
  postSignupPending,
  setReCaptchaToken
} from "../../store/actions";
import { checkEmailExists, getAuthData } from "../../store/selectors";

import styles from "../../assets/styles/auth.module.scss";
import cx from 'classnames'
import { clearState } from "../../store/reducers";
import ReCAPTCHA from "react-google-recaptcha";


const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Please enter a valid email address'),
  first_name: yup
    .string('Enter your first name')
    .required('Please enter your first name'),
  last_name: yup
    .string('Enter your last name')
    .required('Please enter your last name'),
  password: yup
    .string('Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Password should contain at least 8 characters with 1 upper case letter and 1 number"
    )
    .required('Please enter your password')
});

const developKey = process.env.REACT_APP_SITE_KEY_DEV;
const testKey = process.env.REACT_APP_SITE_KEY_TEST;
const sandboxKey = process.env.REACT_APP_SITE_KEY_SANDBOX;
const stagingKey = process.env.REACT_APP_SITE_KEY_STAGING;
const productionKey = process.env.REACT_APP_SITE_KEY_PRODUCTION;

let siteKey;
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'develop':
    siteKey = developKey
    break
  case 'test':
    siteKey = testKey
    break
  case 'sandbox':
    siteKey = sandboxKey
    break
  case 'staging':
    siteKey = stagingKey
    break
  case 'production':
    siteKey = productionKey
    break
  default:
    siteKey = developKey
}


const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();


  const { signup, isLoading, regError } = useSelector(getAuthData)
  const data = useSelector(checkEmailExists)

  const [toggleIcon, setToggleIcon] = useState('show-password')
  const [errorMessage, setErrorMessage] = useState('');
  const [existsUser, setExistsUser] = useState(false);
  const [checkEmail, setCheckEmail] = useState('')

  const clearErrorsHandler = useCallback(() => {
    dispatch(clearErrors('regError'))
    dispatch(clearErrors('loginError'))
  }, [dispatch])

  useEffect(() => {
    clearErrorsHandler()
  }, [clearErrorsHandler])

  useEffect(() => {
    dispatch(clearState())
  }, [dispatch])


  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = await recaptchaRef.current.executeAsync();
      if (token) {
        dispatch(setReCaptchaToken(token))
        dispatch(postSignupPending(values))
      } else {
        setErrorMessage('Issue with reCaptcha')
      }

    },
  })

  const [resetForm] = useState(() => formik.resetForm)

  const handleChange = useCallback((event) => {
    formik.setValues(data => ({ ...data, [event.target.name]: event.target.value }))
    setErrorMessage('')
    if (event.target.name === 'email') {
      setExistsUser(false)
    }
  }, [formik])

  useEffect(() => {
    if (signup?.token) {
      dispatch(getCompanyDataPending())
      resetForm()
    }
  }, [signup, dispatch, navigate, resetForm])


  useEffect(() => {
    if (regError) {
      setErrorMessage('Invalid credentials. Please try again')
    }
  }, [regError])

  const toggleShowPassword = useCallback(() => {
    setToggleIcon(toggleIcon === 'show-password' ? 'hide-password' : 'show-password')
  }, [toggleIcon])

  useEffect(() => {
    setExistsUser(data?.exists)
  }, [data])

  const email = useDebounce(checkEmail, 2000);

  useEffect(() => {
    const regex = /.+@.+\.[A-Za-z]+$/
    const isValid = regex.test(email);
    if (email && isValid) {
      dispatch(postCheckEmailPending({ email }))
    }
  }, [checkEmail, email, dispatch])

  const navigateLogin = useCallback(() => {
    resetForm()
    setExistsUser(false)
    setCheckEmail('')
    setErrorMessage('')
    navigate('/login')
    clearErrorsHandler()
  }, [navigate, resetForm, clearErrorsHandler])

  return (
    <>
      <Background>
        <div className={cx(styles.auth)}>
          {isLoading && <Loading className={cx(styles.authLoading)} />}
          <div className={cx(styles.authForm)}>
            <h3 className={cx(styles.title)}>Create your Merge account</h3>
            <form className={cx(styles.form)} onSubmit={formik.handleSubmit}>
              <InputField
                name={'email'}
                label={'Email address'}
                value={formik.values.email}
                onChange={handleChange}
                onBlur={(event) => setCheckEmail(event.target.value)}
                error={(formik.touched.email && Boolean(formik.errors.email))}
                helperText={formik.touched.email && formik.errors.email}
              />
              {((existsUser || errorMessage) && formik.values.email) && <p className={cx(styles.existsUser)}>
                This address is already in use for a registered account. <span onClick={navigateLogin}>Sign in</span>
              </p>}
              <InputField
                name={'first_name'}
                label={'First name'}
                value={formik.values.first_name}
                onChange={handleChange}
                error={(formik.touched.first_name && Boolean(formik.errors.first_name))}
                helperText={formik.touched.first_name && formik.errors.first_name}
              />
              <InputField
                name={'last_name'}
                label={'Last name'}
                value={formik.values.last_name}
                onChange={handleChange}
                error={(formik.touched.last_name && Boolean(formik.errors.last_name))}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
              <InputField
                type={toggleIcon === 'show-password' ? 'password' : 'text'}
                name={'password'}
                label={'Set password'}
                value={formik.values.password}
                onChange={handleChange}
                handleIconClick={toggleShowPassword}
                hasIcon={true}
                iconPosition="end"
                iconName={toggleIcon}
                error={(formik.touched.password && Boolean(formik.errors.password))}
                helperText={formik.touched.password && formik.errors.password}
              />
              {formik.values.password && <PasswordStrength password={formik.values.password} />}
              <p className={cx(styles.passInfo)}>
                Password should contain at least 8 characters with 1 upper case letter and 1 number.
              </p>
              <p className={cx(styles.passInfo)}>
                By creating an account I agree to <a href={"/terms-of-use"}
                  style={{ color: '#00A09B', textDecoration: 'none' }}> Terms of
                  Use</a> and use of personal data as stated in <a href={"/privacy-policy"}
                    style={{ color: '#00A09B', textDecoration: 'none' }}>Privacy
                  Policy</a>.
              </p>
              <button className={cx(styles.submitBtn)} type="submit">
                <p>Create an account</p>
              </button>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={siteKey}
              />
            </form>
            <p className={cx(styles.haveAccount)}>Already have an account? <button onClick={navigateLogin}>Sign
              in</button></p>
          </div>
        </div>
      </Background>
      {regError && <Messages text={"Signup failed. Please retry."} variant={'error'} />}
    </>
  )
}

export default Registration;
import React, {useState} from "react";
import Snackbar from '@mui/material/Snackbar';
import {ThemeProvider} from "@emotion/react";
import {createTheme} from "@mui/material/styles";
import Icon from "./Icon";

const Messages = (props) => {
    const {text, variant} = props;
    const [state, setState] = useState({
        show: true,
        vertical: 'bottom',
        horizontal: 'left',
    });

    const color = variant === 'error' ? '#FF8888' : variant === 'success' ? '#57AD9E' : '#191C49';

    const handleClose = () => {
        setState({ ...state, open: false });
    };
    const materialTheme = createTheme({
        components: {
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        justifyContent: 'space-around',
                        padding: 0,
                        borderLeft: `8px solid ${color}`,
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                        borderRadius: '4px'
                    },
                    message: {
                        color: '#111326',
                        padding: '14px',
                        fontSize: '14px',
                        lineHeight: '143%',
                        letterSpacing: '0.15px',
                        textIndent: '8px',
                    },
                    action: {
                        padding: '18px 26px'
                    }
                }
            }
        }
    });

    const { vertical, horizontal, show } = state;

    return (
        <ThemeProvider theme={materialTheme}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={show}
                // autoHideDuration={1000}
                onClose={handleClose}
                message={<p>{text}</p>}
                action={<Icon name={'remove'} onClick={() => setState(prevState => ({...prevState, show: false}))}/>}
                key={vertical + horizontal}
            />
        </ThemeProvider>
    )
};

export default Messages;
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import {Sidebar} from "./components";

import pages from '../conditions/navigation';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import HamburgerMenu from "../../svg/HamburgerMenu";
import styled from 'styled-components';
import {useDispatch} from "react-redux";
import selectState from '../conditions/selectors';
import {actions} from '../conditions/slice'
// import {useInjectReducer, useInjectSaga} from "../../utils/redux-injectors";
// import {cont} from "../conditions/saga";
import Grid from "@mui/material/Grid";
import {useLocation} from "react-router-dom";
import { Icon} from "../common";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// const StyledMenu = styled.div`
//   margin: 50px 20px;
//   margin-bottom: 0;
// `;



const StyledButton = styled(Button)`
  font-family: 'Inter', sans-serif;
  font-style: normal !important;
  font-weight: 500;
  &:hover {
    background-color: rgba(0, 160, 155, 0.19) ;
  }
`;

// const StyledBackButton = styled.div`
//   /* pure-white-100 */
//   color: #00A09B;
//   font-family: 'Inter', sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 18px;
//   line-height: 26px;
//
//   background: #FFFFFF;
//   /* merge-turquiose-100 */
//
//   border: 1px solid #00A09B;
//   border-radius: 15px;
//   padding: 7px 40px;
//   text-align: center;
// `;

const ContentLayout = ({ children}) => {
    // useInjectReducer({ key: name, reducer });
    // useInjectSaga({ key: sliceKey, saga: homePageSaga });
    // const navigate = useNavigate();
    const location = useLocation()
    const [routes, setRoutes] = useState(pages.termsPages)

    const dispatch = useDispatch();
    const {isDrawerOpen,} = selectState();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
            defaultMatches: true,
    });


    useEffect(() => {
        dispatch(actions.drawerClose())

        return () => {
            dispatch(actions.drawerClose())
        }
    }, [dispatch])

    useEffect(() => {
        if(location?.pathname === "/terms-and-conditions") {
            setRoutes(pages.termsPages)
        }
        if(location?.pathname === "/terms-and-conditions-modulr-uk") {
            setRoutes(pages.termsPagesModulrUk)
        }
        if(location?.pathname === "/terms-of-use") {
            setRoutes(pages.termsOfUsePages)
        }
        if(location?.pathname === "/privacy-policy") {
            setRoutes(pages.policyPages)
        }
    }, [location.pathname])

    const [activeLink, setActiveLink] = useState('');
    const {
        currentSection
    } = selectState();
    useEffect(() => {
        setActiveLink(window && window.location ? window.location.pathname : '');
    }, []);

    // const hasActiveLink = () => routes.find((i) => i.href === activeLink);


    return (
        <Box sx={{backgroundColor: "#fff", display: 'flex'}}>
            <Drawer
                anchor="left"
                onClose={() => dispatch(actions.drawerClose())}
                open={isDrawerOpen}
                variant={isMd ? "permanent" : "temporary"}
                sx={{
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: 280,
                        position: 'relative',
                        border: '1px solid #FFFFFF',
                        boxShadow: '0px 20px 10px rgba(0, 0, 0, 0.5)',
                        overflowX: 'hidden'
                    },
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent'
                    }
                }}
            >
                <Box sx={{height: '100%', padding: 1}}>
                {/*<SidebarNav pages={pages} />*/}
                    <Box>
                        <Box width={1} paddingX={2} paddingY={1}>
                            <Box
                                display={'flex'}
                                component="a"
                                href="/"
                                title="Merge"
                                width={{ xs: 200, md: 300 }}
                                sx={{
                                    paddingLeft: '30px',
                                    paddingTop: '70px',
                                    marginBottom: '30px'
                                }}
                            >
                                <Icon name={'logo'}/>
                            </Box>
                        </Box>
                        <Box paddingX={2} paddingY={2}>
                            <Box>
                                {/*<NavItem items={getCurrentLink()} />    */}
                                <Box>
                                <Grid container spacing={1}>
                                    {routes?.map((p, i) => (
                                        <Grid item key={i} xs={12}>
                                            <a href={p.href} style={{color: '#00A09B', textDecoration: 'none'}}>
                                                <StyledButton
                                                    size={'large'}
                                                    component={'a'}
                                                    href={p.href}
                                                    fullWidth
                                                    sx={{
                                                        justifyContent: 'flex-start',
                                                        color:
                                                            currentSection === p.href
                                                                ? '#00A09B'
                                                                : '#111326',
                                                        backgroundColor:
                                                            currentSection === p.href
                                                                ? 'rgba(0, 160, 155, 0.19)'
                                                                : 'transparent',
                                                        borderRadius: '10px',
                                                        fontWeight: activeLink === p.href ? 700 : 500,
                                                        fontSize: '16px',
                                                        lineHeight: '24px',
                                                        padding: '4px 30px',
                                                        textTransform: 'none'

                                                    }}
                                                    onClick={() => {
                                                        // dispatch(actions.drawerClose())
                                                        dispatch(actions.sectionSet(p.href));
                                                    }}
                                                >
                                                    {p.title}
                                                    {p.isNew && (
                                                        <Box
                                                            padding={0.5}
                                                            display={'inline-flex'}
                                                            borderRadius={1}
                                                            bgcolor={'primary.main'}
                                                            marginLeft={2}
                                                        >
                                                            <Typography
                                                                variant={'caption'}
                                                                sx={{ color: 'common.white', lineHeight: 1 }}
                                                            >
                                                                new
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </StyledButton>
                                            </a>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Drawer>
      <main
        style={{
            width: '100%'
        }}
      >
          {/*{!isMd &&*/}
          {/*    <Grid container mb={5}>*/}
          {/*        <Grid item xs={2} sm={8}>*/}
          {/*            <StyledMenu onClick={() => dispatch(actions.drawerOpen())}>*/}
          {/*                /!*<HamburgerMenu />*!/*/}
          {/*                <Icon name={'close'}/>*/}
          {/*            </StyledMenu>*/}
          {/*        </Grid>*/}
          {/*        <Grid item xs={10} sm={4}*/}
          {/*           sx={{*/}
          {/*               margin: '40px 0px',*/}
          {/*               marginBottom: 0,*/}
          {/*               paddingRight: '32px'*/}
          {/*           }}*/}
          {/*        >*/}
          {/*            <StyledBackButton onClick={() => navigate("/")}>*/}
          {/*                Go back*/}
          {/*            </StyledBackButton>*/}
          {/*        </Grid>*/}
          {/*    </Grid>*/}


          {/*}*/}
        {children}
          <Box
              position={'relative'}
              sx={{
                  marginTop: '60px',
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  maxWidth: '100%',
                  backgroundColor: '#111326',
              }}
          >
          </Box>
      </main>
    </Box>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node,
  // colorInvert: PropTypes.bool,
  // bgcolor: PropTypes.string,
};

export default ContentLayout;
import {
    GET_COMPANIES_PENDING, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILURE,
    CHECK_REGISTRATION_NUMBER_SUCCESS,
    CHECK_REGISTRATION_NUMBER_PENDING,
    CHECK_REGISTRATION_NUMBER_FAILURE, CLEAR_COMPANY_NUMBER, CLEAR_COMPANIES
} from '../actionTypes';

const initialState = {
    companies: [],
    number: '',
    isLoading: false,
    error: null
}

const companies = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANIES_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case CLEAR_COMPANIES:
            return {
                ...state,
                error: null,
                companies: [],
                number: ''
            };

        case CHECK_REGISTRATION_NUMBER_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
                number: ''
            };

        case CLEAR_COMPANY_NUMBER:
            return {
                ...state,
                number: ''
            }

        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                companies: action.data
            };

        case CHECK_REGISTRATION_NUMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                number: action.data.message
            }

        case GET_COMPANIES_FAILURE:
        case CHECK_REGISTRATION_NUMBER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                number: ''
            };

        default:
            return state;
    }
};

export default companies;

import React, {useCallback, useEffect, useState} from 'react';
import {DateRangePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Box, TextField} from "@mui/material";
import cx from "classnames";
import styles from "../../../assets/styles/common.module.scss";
import {Icon} from "../index";
import InputAdornment from "@mui/material/InputAdornment";

const RangePicker = ({values, onChange}) => {
    const [value, setValue] = useState([null, null]);

    useEffect(() => {
        setValue(values);
    }, [values]);


    const startAdornment = {
        startAdornment: <InputAdornment position={"start"} className={cx(styles['start-adornment'])}>
            <Icon name={'calendar'} />
        </InputAdornment>
    }

    // useEffect(() => {
    //     if(reset){
    //         setValue([null, null]);
    //     }
    // }, [reset]);

    const handleChange = useCallback((date) => {
        setValue(date);
        onChange(date);
    }, [onChange]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/*<div className={cx(styles.rangeLabel)}>*/}
            {/*    <p>From (Date)</p>*/}
            {/*    <p>To (Date)</p>*/}
            {/*</div>*/}
            <DateRangePicker
                date={value}
                calendars={1}
                value={value}
                onChange={handleChange}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <Box className={styles.fromDate}>
                            <p>From (Date)</p>
                            <TextField
                                {...startProps}
                                label={''}
                                className={cx(styles.pickerRange)}
                                InputProps={startAdornment}
                                // sx={{width: '100%', marginLeft: '6px'}}
                            />
                        </Box>
                        <Box className={styles.toDate}>
                            <p>To (Date)</p>
                            <TextField
                                {...endProps}
                                label={''}
                                className={cx(styles.pickerRange)}
                                InputProps={startAdornment}
                                // sx={{maxWidth: '146px', marginRight: '8px'}}
                            />
                        </Box>
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    );
};

export default RangePicker;
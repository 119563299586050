import { createSlice } from '@reduxjs/toolkit'

/*
 *  @desc The accounts slice manages the state of the accounts
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: {
        status: false,
        message: ''
    },
    selectedAccount: null,
    accounts: {
        items: [],
        total: null,
        page: null,
        size: null
    },
    subAccounts : {
        items: [],
        total: null,
        page: null,
        size: null
    },
    transactions: []
}

export const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        getAccounts(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        getAccountsSuccess(state, action) {
            state.loading = false;
            if (action.payload.type === 'main_account') {
                state.accounts = action.payload.data;
            } else if (action.payload.type === 'customer_account') {
                state.subAccounts = action.payload.data;
            }
            state.error = {
                status: false,
            };
        },
        getAccountsError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true
            };
        },
        getSubAccountTransactions: (state, action) => {
            state.transactions = []
                // action.payload ? action.payload : state.transactions;
        },
        getSubAccountTransactionsSuccess: (state, action) => {
            state.transactions = action.payload
                // ? action.payload : state.account
        },
        getSubAccountTransactionsError: (state) => {
            state.transactions = []
        },
        getSelectedAccount: (state, action) => {
            state.loading = true;
            state.selectedAccount = null;
            state.error = {
                status: false,
            };
        },
        getSelectedAccountSuccess: (state, action) => {
            state.loading = false;
            state.selectedAccount = action.payload.account;
            state.error = {
                status: false,
            };
        },
        getSelectedAccountError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true
            };
        },
        removeTransactions: (state) => {
            state.transactions = []
        }
    }
})

export const { actions, reducer, name } = accountsSlice;

import { createSelector } from '@reduxjs/toolkit';
import { initialState as accountState } from './slice';
import { useSelector } from 'react-redux';

export const selectDomain = (state) => {
    return state.accounts || accountState;
}

export const select = () => createSelector(selectDomain, state => state);

/* eslint-disable */
export default () => useSelector(select());

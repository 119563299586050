import React from 'react';
import { ResendOTP } from "otp-input-react";
import {
    StyledOtpWrapper,
    StyledOtpInput,
    StyledOtpError,
    StyledOtpButtonWrapper
} from "./styles";
import PropTypes from "prop-types";
import {
    Button
} from 'components/common';


const renderRemainingTime = (buttonProps) => {
    const {
        onClick,
    } = buttonProps;
    return (
        <div className="otp-resend">
            {buttonProps.remainingTime !== 0 ?
                <>
                    Didn't get code? <span className='resend-button'>Resend</span> in: {buttonProps.remainingTime}
                </>
           :
                <div className='resend-button' onClick={() => onClick()} style={{cursor: 'pointer'}}>Resend Code</div>
            }
        </div>
    );
};


const Otp = ({
    otp,
    onChange,
    disabled,
    length,
    heading,
    onSubmit,
    onResend,
    error,
    otpCount,
    maxTime,
    buttonText,
    isSecured = false,
}) => {
  const renderTime = () => React.Fragment;
  return (
    <StyledOtpWrapper>
        <h2>
            {heading}
        </h2>
        <StyledOtpInput
            value={otp}
            onChange={onChange}
            autoFocus
            OTPLength={length}
            otpType="number"
            className={'otp-input'}
            error={error}
            maxTime={maxTime}
            secure={isSecured}
            id={"outlined-basic_otp"}
        />
        <StyledOtpError>
            {((error.message === 'Invalid OTP' || error === 'OTP missmatch') && otpCount !== 3) && 'Wrong code. Please try again or use resend code'}
            {(error.message === 'Invalid OTP' && otpCount === 3) &&  'The maximum number of attempts have been made. Please resend the code'}
        </StyledOtpError>
        <StyledOtpButtonWrapper>
            <Button
                className={'otp-submit'}
                disabled={disabled}
                onClick={onSubmit}
                size="lg"
                variant="gradient"
                text={buttonText ? buttonText : "Submit"}
            />
        </StyledOtpButtonWrapper>
        <ResendOTP renderButton={renderRemainingTime} onResendClick={onResend} renderTime={renderTime} maxTime={maxTime} />
    </StyledOtpWrapper>
  );
};

export default Otp;

Otp.propTypes = {
    otp: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    length: PropTypes.number,
    heading: PropTypes.string,
    onSubmit: PropTypes.func,
    onResend: PropTypes.func,
    otpCount: PropTypes.number,
};
export const transactionTypes = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Funds out',
        value: 'funds_out'
    },
    {
        label: 'Funds in',
        value: 'funds_in'
    }
];

export const transactionStatuses = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Processing',
        value: 'processing'
    },
    {
        label: 'Successful',
        value: 'successful'
    }
]

export const tabs =[
    {id: '1', title: 'All', value: 'all'},
    {id: '2', title: 'Funds in', value: 'funds_in'},
    {id: '3', title: 'Funds out', value: 'funds_out'}
]

export const periods =[
    {id: 1, title: '1 day'},
    {id: 2, title: '1 month'},
    {id: 3, title: '1 year'},
    {id: 4, title: 'All time'}
]

export const subAccounts = [
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4497",
        account_holder_name: "Freddie Mercury",
        summary: "Credit payment",
        sort_code: "20-00-00",
        account_number: "123456789",
        nickname: "Freddie",
        created_at: "2022-06-16T07:39:33.780Z",
        status: "active",
        transaction: '£1, 000.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "100.00",
                created_at: "2022-07-02T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Wincy306 Thomas",
                destination_sort_code: "000000",
                destination_summary: "GBP Account",
                reference: "blehpayout",
                source_account_number: "71033687",
                source_sort_code: "000000",
                status: "successful",
                transaction_id: "fa99ec91-5625-41b3-9612-cdcbdc38d409",
                type: "funds_out",
            },
            {
                amount: "10000.00",
                created_at: "2022-07-04T13:47:27.997785+00:00",
                currency: "GBP",
                destination_account_number: "",
                destination_name: "",
                destination_sort_code: "",
                reference: "TEST PAYMENT",
                source_account_name: "Wincy Thomas",
                source_account_number: "70848909",
                source_sort_code: "323232",
                status: "successful",
                transaction_id: "922172b8-0b1c-4c86-b75c-29c88f430453",
                type: "funds_in",
            }
        ]
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361eed8b4497",
        account_holder_name: "John Deacon",
        summary: "Credit payment",
        sort_code: "80-80-43",
        account_number: "578908087",
        nickname: "John",
        created_at: "2022-06-29T07:39:33.780Z",
        status: "processing",
        transaction: '£400, 000',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-30T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny Cash",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec91-5624-41b3-9612-cdcbew38d409",
                type: "funds_out",
            },
            {
                amount: "100.00",
                created_at: "2022-07-01T13:47:27.997785+00:00",
                currency: "GBP",
                destination_account_number: "",
                destination_name: "",
                destination_sort_code: "",
                reference: "TEST",
                source_account_name: "Tommy Dude",
                source_account_number: "70988909",
                source_sort_code: "678765",
                status: "successful",
                transaction_id: "922172b8-89uh-4c86-b75c-29c88f430453",
                type: "funds_in",
            }
        ]
    },
    {
        id: "56a0ef40-7a22-6e68-9612-361e5f8b4497",
        account_holder_name: "Brian May",
        summary: "Credit payment",
        sort_code: "23-32-00",
        account_number: "769902112",
        nickname: "Brian",
        created_at: "2022-06-16T07:39:33.780Z",
        status: "blocked",
        transaction: '£7, 000.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "7, 000.00",
                created_at: "2022-06-02T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12885678",
                destination_name: "Johnny Lil",
                destination_sort_code: "277880",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec91-5624-41b3-7809-cdcbew38d409",
                type: "funds_out",
            }
        ]
    },
    {
        id: "56a0ef40-7782-4e68-0612-361e5f8b4497",
        account_holder_name: "Roger Taylor",
        summary: "Credit payment",
        sort_code: "43-32-30",
        account_number: "67899222",
        nickname: "JLO",
        created_at: "2022-05-28T07:39:33.780Z",
        status: "active",
        transaction: '£4, 000.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "7, 000.00",
                created_at: "2022-06-27T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12885678",
                destination_name: "Johnny Lil",
                destination_sort_code: "277980",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec91-5624-41b3-7809-edcbei38d409",
                type: "funds_out",
            }
        ]
    },
    {
        id: "58g0ef40-7782-4e68-9612-361e5f8b4497",
        account_holder_name: "John Lennon",
        summary: "Credit payment",
        sort_code: "77-32-30",
        account_number: "67899222",
        nickname: "John",
        created_at: "2022-07-03T07:39:33.780Z",
        status: "active",
        transaction: '£130, 000.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-07-03T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny Cash",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec32-5624-41b3-9612-cdcbew38d409",
                type: "funds_out",
            },
            {
                amount: "100.00",
                created_at: "2022-06-08T13:47:27.997785+00:00",
                currency: "GBP",
                destination_account_number: "",
                destination_name: "",
                destination_sort_code: "",
                reference: "TEST",
                source_account_name: "Tommy Dude",
                source_account_number: "70678909",
                source_sort_code: "238765",
                status: "successful",
                transaction_id: "922782b8-89uh-4c86-b75c-29c88f430453",
                type: "funds_in",
            }
        ]
    },
    {
        id: "56a0ef40-7782-7e68-9612-361e5f8b4497",
        account_holder_name: "Paul McCartney",
        summary: "Credit payment",
        sort_code: "56-32-30",
        account_number: "99899222",
        nickname: "Paul",
        created_at: "2022-06-16T07:39:33.780Z",
        status: "active",
        transaction: '£67, 670.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-04T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny Cash",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec32-7890-41b3-9612-cdcbew38d409",
                type: "funds_out",
            },
            {
                amount: "100.00",
                created_at: "2022-06-04T13:47:27.997785+00:00",
                currency: "GBP",
                destination_account_number: "",
                destination_name: "",
                destination_sort_code: "",
                reference: "TEST",
                source_account_name: "Tommy Dude",
                source_account_number: "70678909",
                source_sort_code: "238765",
                status: "processing",
                transaction_id: "922782b8-67gh-4c86-b75c-29c88f430453",
                type: "funds_in",
            },
            {
                amount: "300.00",
                created_at: "2022-06-09T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny mike",
                destination_sort_code: "677812",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec33-7890-41b3-9612-cdcbew38d409",
                type: "funds_out",
            }
        ]
    },
    {
        id: "56a0ef40-7782-4e68-9902-361e5f8b4497",
        account_holder_name: "Ringo Starr",
        summary: "Credit payment",
        sort_code: "57-00-30",
        account_number: "699478222",
        nickname: "Ringo",
        created_at: "2022-06-16T07:39:33.780Z",
        status: "processing",
        transaction: '£0',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: []
    },
    {
        id: "56a0ef40-7900-4e68-9612-361e5f8b4497",
        account_holder_name: "George Harrison",
        summary: "Credit payment",
        sort_code: "00-10-30",
        account_number: "89078222",
        nickname: "George",
        created_at: "2022-07-05T07:39:33.780Z",
        status: "blocked",
        transaction: '£700.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-05T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny Cash",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec32-7890-41b3-8912-cdcbew38d409",
                type: "funds_out",
            }
        ]
    },
    {
        id: "56a0ef40-7782-4898-9612-361e5f5b4497",
        account_holder_name: " David Gilmour",
        summary: "Credit payment",
        sort_code: "50-10-30",
        account_number: "85478222",
        nickname: " David",
        created_at: "2022-06-16T07:39:33.780Z",
        status: "active",
        transaction: '£1, 700.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-05T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Nike Cash",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec32-7890-41b3-9613-cdcbew38d409",
                type: "funds_out",
            },
            {
                amount: "100.00",
                created_at: "2022-07-01T13:47:27.997785+00:00",
                currency: "GBP",
                destination_account_number: "",
                destination_name: "",
                destination_sort_code: "",
                reference: "TEST",
                source_account_name: "Tommy Dude",
                source_account_number: "78978909",
                source_sort_code: "238765",
                status: "processing",
                transaction_id: "922782b8-67gh-4c86-b75r-29c88f430453",
                type: "funds_in",
            },
            {
                amount: "300.00",
                created_at: "2022-07-04T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny look",
                destination_sort_code: "977812",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec33-7890-45b3-9612-cdcbew38d409",
                type: "funds_out",
            }
        ]
    },
    {
        id: "56a0ef40-7782-4e68-9612-361e7898b449",
        account_holder_name: "Nick Mason",
        summary: "Credit payment",
        sort_code: "22-10-33",
        account_number: "89078222",
        nickname: "Nick Mason",
        created_at: "2022-06-16T07:39:33.780Z",
        status: "blocked",
        transaction: '£2, 700.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-05T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny Cash",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "de99ec32-5624-41b3-9612-cdcbew38d409",
                type: "funds_out",
            },
            {
                amount: "100.00",
                created_at: "2022-07-02T13:47:27.997785+00:00",
                currency: "GBP",
                destination_account_number: "",
                destination_name: "",
                destination_sort_code: "",
                reference: "TEST",
                source_account_name: "Tommy Dude",
                source_account_number: "70678909",
                source_sort_code: "238765",
                status: "successful",
                transaction_id: "522782b8-89uh-4c86-b75c-29c88f430453",
                type: "funds_in",
            }
        ]
    },
    {
        id: "56a0ef40-7782-4e68-9611-361e5f8b4497",
        account_holder_name: "Syd Barrett",
        summary: "Credit payment",
        sort_code: "00-11-20",
        account_number: "91078232",
        nickname: "Syd",
        created_at: "2022-06-08T07:39:33.780Z",
        status: "active",
        transaction: '£700.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-30T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny Deep",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec67-5624-41b3-9612-cdcbew38d409",
                type: "funds_out",
            }
        ]
    },
    {
        id: "56a0ef40-7782-7e68-9611-361e5f8b4497",
        account_holder_name: "Roger Waters ",
        summary: "Credit payment",
        sort_code: "32-11-20",
        account_number: "91078232",
        nickname: "Roger",
        created_at: "2022-02-16T07:39:33.780Z",
        status: "active",
        transaction: '£70, 000.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-05T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Nike Cash",
                destination_sort_code: "677887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec32-7890-41b3-9613-cdcbew38d409",
                type: "funds_out",
            },
            {
                amount: "100.00",
                created_at: "2022-07-01T13:47:27.997785+00:00",
                currency: "GBP",
                destination_account_number: "",
                destination_name: "",
                destination_sort_code: "",
                reference: "TEST",
                source_account_name: "Tommy Dude",
                source_account_number: "78978909",
                source_sort_code: "238765",
                status: "processing",
                transaction_id: "922782b8-67gh-4c86-jy67-29c88f430453",
                type: "funds_in",
            },
            {
                amount: "300.00",
                created_at: "2022-06-05T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny look",
                destination_sort_code: "977812",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec33-7890-45b3-9612-cdcbew38d409",
                type: "funds_out",
            },
            {
                amount: "300.00",
                created_at: "2022-06-30T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny legal",
                destination_sort_code: "977812",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "successful",
                transaction_id: "fa99ec33-6750-45b3-9612-cdcbew38d409",
                type: "funds_out",
            }
        ]
    },
    {
        id: "76a0ef40-7782-4e68-9611-361e5f8b4497",
        account_holder_name: "Richard Wright",
        summary: "Credit payment",
        sort_code: "11-11-20",
        account_number: "91008292",
        nickname: "Richard",
        created_at: "2022-06-30T07:39:33.780Z",
        status: "active",
        transaction: '£20, 600.00',
        company_id: "c8a35972-ac0b-43b0-a5cf-55c4ea42c556",
        transactions: [
            {
                amount: "300.00",
                created_at: "2022-06-05T18:40:35.838971+00:00",
                currency: "GBP",
                destination_account_number: "12345678",
                destination_name: "Johnny Rock",
                destination_sort_code: "681887",
                destination_summary: "GBP Account",
                reference: "cash",
                source_account_number: "",
                source_sort_code: "",
                status: "processing",
                transaction_id: "fa99ec67-5624-67gt-9612-cdcbew38d409",
                type: "funds_out",
            }
        ]
    }
];

export const accountStatuses = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Active',
        value: 'active'
    },
    {
        label: 'Processing',
        value: 'pending'
    },
    {
        label: 'Blocked',
        value: 'blocked'
    }
]
export const PRIVACY_POLICY_SECTIONS = {
    PRIVACY_POLICY_SECTION: 'privacy-policy',
    PERSONAL_INFORMATION_SECTION: 'personal-information-overview',
    SENSITIVE_DATA_SECTION: 'sensitive-data',
    COOKIES_SECTION: 'cookies-and-other-identifiers',
    SHARE_PERSONAL_INFORMATION_SECTION: 'does-merge-share-my-personal-information',
    INFORMATION_TRANSFER_SECTION: 'international-transfers-of-personal-data',
    SECURE_INFORMATION_SECTION: 'how-secure-is-my-information',
    ABOUT_ADVERTISING: 'what-about-advertising',
    INFORMATION_ACCESS_SECTION: 'what-information-can-i-access',
    RIGHTS_SECTION: 'what-rights-do-i-have',
    MY_PERSONAL_INFORMATION_SECTION: 'how-long-does-merge-keep-my-personal-information',
    CONTACT_INFORMATION: 'contact-information',
    CONDITIONS_OF_USE: 'conditions-of-use-notices-and-revisions',
}

export const TERMS_AND_CONDITIONS_SECTIONS = {
    TERMS_AND_CONDITIONS: "terms-and-conditions",
    MERGE_DISTRIBUTOR_AND_CONTACT_INFORMATION: "merge-distributor-and-contact-information",
    INTRODUCTION: "introduction",
    DEFINITIONS: "definitions",
    YOUR_E_WALLET_AND_THE_SERVICES: "your-e-wallet-and-the-services",
    MAINTAINING_YOUR_WALLET: "maintaining-your-wallet",
    PROTECTING_YOUR_E_WALLET: "protecting-your-e-wallet",
    CLOSING_YOUR_WALLET: "closing-your-wallet",
    DEPOSITING_OR_WITHDRAWING_FUNDS: "depositing-or-withdrawing-funds",
    MAKING_PAYMENTS: "making-payments",
    PARTNERS: "partners",
    END_USER_ACCOUNTS: "end-user-accounts",
    COMPLIANCE_WITH_LAWS_AND_PROHIBITED_ACTIVITY: "compliance-with-laws-and-prohibited-activity",
    FEES_AND_SET_OFF: "fees-and-set-off",
    TERM_SUSPENSION_AND_TERMINATION: "term-suspension-and-termination",
    LIMITATION_OF_LIABILITY: "limitation-of-liability",
    CONFIDENTIALITY_AND_DATA_PROTECTION: "confidentiality-and-data-protection",
    INTELLECTUAL_PROPERTY: "intellectual-property",
    CHANGES_TO_THESE_TERMS: "changes-to-these-terms",
    COMMUNICATION_AND_COMPLAINTS: "communication-and-complaints",
    GENERAL: "general"
}

export const TERMS_AND_CONDITIONS_MODULR_UK_SECTIONS = {
    BACKGROUND: "background",
    INTREPERTATION: "interpretation",
    MODULR_PRODUCTS: "modulr-products",
    AUTHORISED_USERS: "authorised-users",
    ACCESSING_MODULR_PRODUCTS: "accessing-modulr-products-through-a-partner-platform",
    CUSTOMER_SERVICE: "customer-service",
    TERM_AND_TERMINATION: "term-and-termination",
    INTELLECTUAL_PROPERTY: "intellectual-property",
    FORCE_MAJUER: "force-majeur",
    ASSIGNMENT_TRANSFER: "assignment-transfer-and-subcontracting",
    LIABILITY: "liability",
    REPORTS: "reports",
    DATA_PRIVACY: "data-privacy",
    CHANGES_TO_AGREEMENT: "changes-to-the-agreement",
    GENERAL: "general"
}

export const TERMS_OF_USE_SECTIONS = {
    TERMS_OF_USE: "terms-of-use",
    OTHER_APPLICABLE_TERMS: "other-applicable-terms",
    CHANGES_TO_THESE_TERMS: "changes-to-these-terms",
    CHANGES_TO_MERGE_WEBSITE: "changes-to-merge-website",
    ACCESSING_MERGE_WEBSITE: "accessing-merge-website",
    ACCOUNT_AND_PASSWORD: "account-and-password",
    INTELLECTUAL_PROPERTY: "intellectual-property",
    NO_RELIANCE_INFO: "no-reliance-on-information",
    PROHIBITED_USE: "prohibited-use",
    BREACH: "breach",
    LIMITATION_OF_LIABILITY: "limitation-of-liability",
    LINKING_TO_MERGE_WEBSITE: "linking-to-merge-website",
    THIRD_PARTY_LINKS: "third-party-links",
    OUR_RELATIONSHIP: "our-relationship",
    COMPLAINTS: "complaints",
    GOVERNING_LAW: "governing-law",
}
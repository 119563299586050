import { createSlice } from '@reduxjs/toolkit'


/*
 *  @desc The funds slice manages the state of Send Funds
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    transaction_uuid: null,
    loading: false,
    error: null,
    selectedRecipient: null,
    fromAccount: null,
    account: null,
    currentFundStep: 0,
    disableStep: true,
    transaction_fee: {
        fee_amount: 0,
        fee_currency: ""
    },
    transaction_details: {},
    isTransactionEdit: false,
    transactionStatus: null,
    otp: false,
    otpCount: 0,
    referenceInfo: '',
    token: ''
}

export const fundsSlice = createSlice({
    name: 'funds',
    initialState,
    reducers: {
        setTransactionUuid: (state, action) => {
            state.transaction_uuid = action.payload;
        },
        setCurrentFundStep: (state, action) => {
            if (action.payload === 0) {
                state.transactionStatus = null;
            }
            state.currentFundStep = action.payload;
        },
        setSelectedRecipient: (state, action) => {
            state.selectedRecipient = action.payload;
        },
        resetSelectRecipient: (state, action) => {
            state.selectedRecipient = null
        },
        resetTransactionStatus: (state, action) => {
            state.transactionStatus = null
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = {...action.payload}
        },
        clearError: (state) => {
            state.error = null
        },
        getAccount: (state, action) => {
            state.account = action.payload
        },
        getAccountSuccess: (state, action) => {
            state.account = action.payload.items[0];
        },
        getAccountError: (state, action) => {
            state.account = null
        },
        generateTransactionDetails: (state, action) => {
            state.transaction_details = {...action.payload};
        },
        getTransactionFee: () => {},
        getTransactionFeeSuccess: (state, action) => {
            state.transaction_fee = action.payload
        },
        resetTransactionFee: (state, action) => {
            state.transaction_fee.fee_amount = 0
        },
        getTransactionFeeError: (state, action) => {
            state.transaction_fee.fee_amount = 0
        },
        getOtp: (state, action) => {
            state.otp = false;
            state.token = '';
            state.loading = true;
        },
        getOtpSuccess: (state, action) => {
            state.otp = true;
            state.token = action.payload?.token;
            state.loading = false;
        },
        getOtpError: (state, action) => {
            state.otp = false;
            state.token = ';'
            state.error = {...action.payload}
            state.loading = false;
        },
        resetOtp: (state, action) => {
            state.otp = false;
            state.error = {
                message: '',
                status: null
            }
        },
        fetchFromAccount: (state, action) => {
            state.fromAccount = null;
        },
        fetchFromAccountSuccess: (state, action) => {
            state.fromAccount = action.payload;
        },
        fetchFromAccountFailure: (state, action) => {
            state.error = action.error;
        },
        createTransaction: (state, action) => {
            state.transactionStatus = "pending";
        },
        createTransactionSuccess: (state, action) => {
            state.transactionStatus = "success";
            state.otp = false;
            state.currentFundStep = 3;
        },
        createTransactionError: (state, action) => {
            state.error = {...action.payload}
            switch (action.payload.status) {
                case 422:
                    state.transactionStatus = "error";
                    break;
                case 403:
                    if(state.error.message.indexOf("Invalid OTP") !== -1 && state.otpCount !== 3) {
                       state.otpCount = state.otpCount+1;
                    }
                    state.transactionStatus = null;
                    break;
                case 500:
                    state.transactionStatus = "error";
                    state.currentFundStep = 3;
                    break;
                default:
                    state.transactionStatus = "error";
                    state.currentFundStep = 3;
                    break;
            }
            state.otp = false;
        },
        disableStep: (state, action) => {
            state.disableStep = true;
        },
        resetDisabledStep: (state, action) => {
            state.disableStep = false;
        },
        setIsEditTransaction: (state, action) => {
            state.isTransactionEdit = action.payload;
        },
        resetFunds: () => initialState,
        setReferenceInfo: (state, action) => {
            state.referenceInfo = action.payload
        }
    },
})

export const { actions, reducer, name } = fundsSlice;
import React  from 'react';
import Box from '@mui/material/Box';
// import DownloadFile from 'svg/Download'
// import Container from 'components/Container';
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TermsPdf from 'pdfs/TermsOfUse.pdf';
import { useNavigate } from "react-router-dom";
import { TERMS_OF_USE_SECTIONS } from './Types';
import selectState from './selectors';
// import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
// import { homePageSaga } from './saga';

import {
    StyledGrid,
    StyledHeaderPage,
    StyledSubHeading,
    StyledBackButton,
    StyledHighlightedPanel,
    StyledParagrapgh,
} from './styles';
// import ContentLayout from "../layouts/ContentLayout";
import {Container, Icon} from "../common";

const TermsAndConditions = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    // useInjectReducer({ key: sliceKey, reducer: reducer });
    // useInjectSaga({ key: sliceKey, saga: homePageSaga });
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });

    const {
        currentSection
    } = selectState()


    return  (
        <Box id={"top"}>
            <Container
                sx={{
                    margin: 'unset',
                    paddingLeft: ['16px', '16px', '45px'],
                    paddingTop: ['0','70px','80px']
                }}
            >
                <Grid container alignItems={'center'}>
                    <Grid item md={7}>
                        <StyledHeaderPage>
                            Merge Terms of Use
                        </StyledHeaderPage>
                    </Grid>
                    {isMd &&
                        <Grid item md={5} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            {isLg &&
                                <div style={{marginRight: '20px'}}>
                                    <p style={{color: '#00A09B', marginTop: '10px'}}>
                                        <a href={TermsPdf} target="_blank" rel="noreferrer" style={{color:'#00A09B',textDecoration: 'none'}}>
                                            Download PDF
                                            {' '}
                                            <span style={{margin: '0px 10px', verticalAlign: 'text-top'}}>
                                                <Icon name={'file-download'} />
                                            </span>
                                        </a>
                                    </p>
                                </div>

                            }
                            <div>
                                <StyledBackButton onClick={() => navigate(-1)}>
                                    Go back
                                </StyledBackButton>
                            </div>
                        </Grid>
                    }
                </Grid>
                <Grid  container>
                    <Grid item md={12} sx={{margin: ['0 auto']}}>
                        <Box sx={{textAlign: 'center'}}>
                            <StyledSubHeading>
                                Terms of Use
                            </StyledSubHeading>
                        </Box>
                    </Grid>
                </Grid>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.TERMS_OF_USE} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.TERMS_OF_USE)}>
                    <Grid item>
                        <StyledParagrapgh
                            style={{
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '36px',
                                color: '#777777',
                        }}
                        >
                                Last updated: 21 April 2022
                        </StyledParagrapgh>
                        <StyledParagrapgh>
                            <i>Please note that this cookie policy will regularly be updated to reflect any changes in the way we handle data or any changes in applicable laws.</i>
                        </StyledParagrapgh>

                        <StyledParagrapgh>
                            Merge is the trading name of Merge Holdings Ltd., its subsidiaries and affiliate companies (“we”,“us”, “our”, “ourselves”). Merge Holdings Ltd. is incorporated in the United Kingdom with Companies House number 13803948 and registered office at 7 Bell Yard, London W2CA 2JR, United Kingdom.
                            These Terms of Use, together with the documents referred to in it, inform you of the Terms of Use on which you may make use of our website www.mergedup.com . Use of the Merge website includes accessing, browsing, or registering to use the Merge website.
                            Please read these Terms of Use carefully before you start to use the Merge website, as these will apply to your use of the Merge website. We recommend that you print a copy of this for future reference.
                            By using the Merge website, you confirm that you accept these Terms of Use and that you agree to comply with them. If you do not agree to these Terms of Use, you must not use the Merge website.
                        </StyledParagrapgh>
                    </Grid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.OTHER_APPLICABLE_TERMS} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.OTHER_APPLICABLE_TERMS)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Other applicable terms
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                These Terms of Use refer to the following additional terms, which also apply to your use of the Merge website:

                                <ul>
                                    <li style={{marginBottom: 0}}>
                                        Our Privacy Policy which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using the Merge website, you consent to such processing and you warrant that all data provided by you is accurate.
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        Our Cookies Policy which sets out information about the cookies on the Merge website.
                                    </li>
                                </ul>
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.CHANGES_TO_THESE_TERMS} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.CHANGES_TO_THESE_TERMS)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Changes to these terms of use
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                You are expected to check this page on the Merge website from time to time to take notice of any changes we make, as they are legally binding on you. Some of the provisions contained in this document may also be superseded by provisions or notices published elsewhere on our website.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.CHANGES_TO_MERGE_WEBSITE} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.CHANGES_TO_MERGE_WEBSITE)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Changes to the Merge website
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We may update our website from time to time and change the content at any time. Any of the content on our website may be out of date at any given time and we are under no obligation to update it. We do not guarantee that our website, or any content on it, will be free from errors or omissions.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.ACCESSING_MERGE_WEBSITE} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.ACCESSING_MERGE_WEBSITE)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Accessing the Merge website
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We do not guarantee that our website, or any content on it, will always be available or be uninterrupted. We may suspend, withdraw, discontinue or change all or any part of our website without notice. We will not be liable to you if for any reason our website is unavailable at any time or for any period. You are responsible for making all arrangements necessary for you to have access to our website. You are also responsible for ensuring that all persons who access our website through your internet connection are aware of these Terms of Use and other applicable terms and conditions, and that they comply with them.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.ACCOUNT_AND_PASSWORD} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.ACCOUNT_AND_PASSWORD)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Account and password
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                Where a user identification code, password or other piece of information is required in order to use the website, such information is confidential. Merge has the right to disable any user identification code or password if, in our reasonable opinion, you have failed to comply with any of the provisions of the terms and conditions. If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href={"mailto:support@mergedup.com"}>support@mergedup.com</a>.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.INTELLECTUAL_PROPERTY} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.INTELLECTUAL_PROPERTY)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Intellectual property rights
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                Merge is the owner or the licensees of all intellectual property rights in the Merge website. All such rights are reserved. You may print or download extracts of any page(s) from the Merge website for your use and you may draw the attention of others within your organisation to content posted on our website. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. Merge’s status and that of any identified contributors as the authors of content on our website must always be acknowledged. You must not use any part of the content on our website for commercial purposes without obtaining a licence to do so from us or our licensors. If you print off, copy or download any part of our website in breach of these Terms of Use, your right to use our website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.NO_RELIANCE_INFO} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.NO_RELIANCE_INFO)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    No reliance on information
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                The content on our website is provided for general information only. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our website. Although we make reasonable efforts to update the information on our website, we make no representations, warranties or guarantees, whether expressed or implied, that the content on our website is accurate, complete or up to date.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.PROHIBITED_USE} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.PROHIBITED_USE)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Prohibited uses
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                You may use our website only for lawful purposes. You may not use our website:
                                <ul>
                                    <li style={{marginBottom: 0}}>
                                         In any way that breaches any applicable local, national or international law or regulation;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        For the purpose of harming or attempting to harm minors in any way;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
                                    </li>
                                </ul>

                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We will make reasonable efforts to assess any possible risks for users from third parties when they use any interactive service provided on our website, and we will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks.
                                We are under no obligation to oversee, monitor or moderate any interactive service we provide on our website, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards, whether the service is moderated or not.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We monitor user activity and content on a random basis. Any user found to have used the site in a manner which contravenes this document will be denied access. All reports of misuse will be investigated, and an appropriate response provided within three days of receipt.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.BREACH} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.BREACH)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Breach
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We will determine, at our discretion, whether there has been a breach of these Terms of Use through your use of our website. When a breach has occurred, we may take such action as we deem appropriate. Failure to constitutes a material breach of the Terms of Use upon which you are permitted to use our website, and may result in our taking all or any of the following actions:
                                <ul>
                                    <li style={{marginBottom: 0}}>
                                        Immediate, temporary or permanent withdrawal of your right to use our website;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        Immediate, temporary or permanent removal of any posting or material uploaded by you to our website;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        Issue of a warning to you;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        Further legal action against you;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        The responses described here are not limited and we may take any other action we reasonably deem appropriate.
                                    </li>
                                </ul>
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.LIMITATION_OF_LIABILITY} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.LIMITATION_OF_LIABILITY)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Limitation of liability
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                Nothing in these Terms of Use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our website or any content on it, whether express or implied.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                <ul>
                                    <li style={{marginBottom: 0}}>
                                        use of, or inability to use, our website;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        use of or reliance on any content displayed on our website;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        loss of profits, sales, business, or revenue;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        business interruption;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        loss of anticipated savings;
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        loss of business opportunity, goodwill or reputation; or
                                    </li>
                                    <li style={{marginBottom: 0}}>
                                        any indirect or consequential loss or damage.
                                    </li>
                                </ul>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our website or to your downloading of any content on it, or on any Merge website linked to it.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We assume no responsibility for the content of Merge sites linked on our website. Such links should not be interpreted as an endorsement by us of those linked Merge sites. We will not be liable for any loss or damage that may arise from your use of them.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.LINKING_TO_MERGE_WEBSITE} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.LINKING_TO_MERGE_WEBSITE)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Linking to Merge website
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
                            <StyledParagrapgh>
                                You must not establish a link to our website in any website that is not owned by you. The Merge website must not be framed on any other site, nor may you create a link to any part of the Merge website other than the home page. Merge reserves the right to withdraw linking permission without notice.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                Our website in which you are linking must comply in all respects with the content standards set out in our Terms of Use. If you wish to make any use of content on our website other than that set out above, please contact <a href={"mailto:support@mergedup.com"}>support@mergedup.com</a>.
                            </StyledParagrapgh>
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.THIRD_PARTY_LINKS} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.THIRD_PARTY_LINKS)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Third party links and resources on the Merge website
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                Subject to the limitations of liability set out herein, where our website contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.OUR_RELATIONSHIP} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.OUR_RELATIONSHIP)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Our relationship
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                You acknowledge and agree that no joint venture, partnership, employment, or agency relationship exists between you and Merge as a result of these Terms of use or your use of this Merge website. You agree that you may not and will not claim to be a representative, agent, or employee of Merge, and we shall not be liable for any representation, act, or omission on your part.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.COMPLAINTS} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.COMPLAINTS)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Complaints
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                We operate a complaints handling procedure which we will use to try to resolve disputes when they first arise. Complaints relating to any violations of these Terms of Use should be submitted in writing via <a href={"mailto:support@mergedup.com"}>support@mergedup.com</a>. Complaints must unambiguously state the nature of the problem, and its connection to Merge.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel container id={TERMS_OF_USE_SECTIONS.GOVERNING_LAW} isSelected={currentSection && currentSection.includes(TERMS_OF_USE_SECTIONS.GOVERNING_LAW)}>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    Governing law
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                These Terms of Use shall be governed by and construed in accordance with the laws of England.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
            </Container>
        </Box>
    )
}

export default TermsAndConditions;
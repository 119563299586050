

export const recipientsData = [
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4497",
        account_holder_name: "Barclays",
        summary: "Credit payment",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Barclays",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4498",
        account_holder_name: "Merge Money Ltd",
        summary: "GBP account ending 1823",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4499",
        account_holder_name: "Electrical Engeneering London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4459",
        account_holder_name: "Electrical Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4439",
        account_holder_name: "Electrical London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68s-9612-361e5f8b439",
        account_holder_name: "Electrical London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68s-9612-361e5f8b479",
        account_holder_name: "London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    }
]

/**
 * @description handles the tab change
 * @param {number} event
 * @param {number} newValue
 */
export const fundsType = [
    {
        label: 'Person',
        value: 'person',
        type: 'type',
    },
    {
        label: 'Business',
        value: 'business',
        type: 'type',
    }
];

export const bankDetailsType = [
    {
        label: 'Account number',
        value: 'account',
        type: 'type',
    },
    {
        label: 'IBAN',
        value: 'iban',
        type: 'type',
    }
];

export const supportedCountries = [
    {
      value: "AT",
      label: "Austria",
      type: "country"
    },
    {
      value: "BE",
      label: "Belgium",
      type: "country"
    },
    {
      value: "BG",
      label: "Bulgaria",
      type: "country"
    },
    {
      value: "HR",
      label: "Croatia",
      type: "country"
    },
    {
      value: "CY",
      label: "Cyprus",
      type: "country"
    },
    {
      value: "CZ",
      label: "Czech Republic",
      type: "country"
    },
    {
      value: "DK",
      label: "Denmark",
      type: "country"
    },
    {
      value: "EE",
      label: "Estonia",
      type: "country"
    },
    {
      value: "FI",
      label: "Finland",
      type: "country"
    },
    {
      value: "FR",
      label: "France",
      type: "country"
    },
    {
      value: "GF",
      label: "French Guiana",
      type: "country"
    },
    {
      value: "PF",
      label: "French Polynesia",
      type: "country"
    },
    {
      value: "DE",
      label: "Germany",
      type: "country"
    },
    {
      value: "GI",
      label: "Gibraltar",
      type: "country"
    },
    {
      value: "GB",
      label: "Great Britain",
      type: "country"
    },
    {
      value: "GR",
      label: "Greece",
      type: "country"
    },
    {
      value: "GP",
      label: "Guadeloupe",
      type: "country"
    },
    {
      value: "GG",
      label: "Guernsey",
      type: "country"
    },
    {
      value: "HU",
      label: "Hungary",
      type: "country"
    },
    {
      value: "IS",
      label: "Iceland",
      type: "country"
    },
    {
      value: "IE",
      label: "Ireland",
      type: "country"
    },
    {
      value: "IT",
      label: "Italy",
      type: "country"
    },
    {
      value: "LV",
      label: "Latvia",
      type: "country"
    },
    {
      value: "LI",
      label: "Lichtenstein",
      type: "country"
    },
    {
      value: "LT",
      label: "Lithuania",
      type: "country"
    },
    {
      value: "LU",
      label: "Luxembourg",
      type: "country"
    },
    {
      value: "MT",
      label: "Malta",
      type: "country"
    },
    {
      value: "MQ",
      label: "Martinique",
      type: "country"
    },
    {
      value: "YT",
      label: "Mayotte",
      type: "country"
    },
    {
      value: "NC",
      label: "New Caledonia",
      type: "country"
    },
    {
      value: "NO",
      label: "Norway",
      type: "country"
    },
    {
      value: "PL",
      label: "Poland",
      type: "country"
    },
    {
      value: "PT",
      label: "Portugal",
      type: "country"
    },
    {
      value: "RE",
      label: "Reunion",
      type: "country"
    },
    {
      value: "RO",
      label: "Romania",
      type: "country"
    },
    {
      value: "BL",
      label: "Saint Barthelemy",
      type: "country"
    },
    {
      value: "MF",
      label: "Saint Martin",
      type: "country"
    },
    {
      value: "PM",
      label: "Saint Pierre and Miquelon",
      type: "country"
    },
    {
      value: "SK",
      label: "Slovakia",
      type: "country"
    },
    {
      value: "SI",
      label: "Slovenia",
      type: "country"
    },
    {
      value: "ES",
      label: "Spain",
      type: "country"
    },
    {
      value: "SE",
      label: "Sweden",
      type: "country"
    },
    {
      value: "NL",
      label: "The Netherlands",
      type: "country"
    },
    {
      value: "WF",
      label: "Wallis and Futuna",
      type: "country"
    }
  ];
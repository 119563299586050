import {
    POST_LOGIN_PENDING,
    POST_LOGIN_SUCCESS,
    POST_LOGIN_FAILURE,
    POST_LOGIN_OTP_PENDING,
    POST_LOGIN_OTP_SUCCESS,
    POST_LOGIN_OTP_FAILURE,
    LOGOUT,
    POST_SIGNUP_PENDING,
    POST_SIGNUP_SUCCESS,
    POST_SIGNUP_FAILURE,
    CLEAR_STATE,
    CLEAR_ERRORS,
    SET_RE_CAPTCHA_TOKEN
} from '../actionTypes';

const initialState = {
    login: {},
    signup: {},
    isLoading: false,
    loginError: null,
    regError: null
}

const auth = (state = initialState, action) => {
    switch (action.type) {

        case SET_RE_CAPTCHA_TOKEN:
            return {
                login: {
                    x_recaptcha_token: action.data
                },
            }
        case POST_LOGIN_PENDING:
        case POST_LOGIN_OTP_PENDING:
        case POST_SIGNUP_PENDING:
            return {
                ...state,
                loginError: null,
                regError: null,
                isLoading: true,
            };

        case POST_LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    ...action.data,
                    isOtpVerified: false
                },
                isLoading: false,
            };
        case POST_LOGIN_OTP_SUCCESS:
            return {
                ...state,
                login: {
                    ...action.data,
                    isOtpVerified: true
                },
                isLoading: false,
            };

        case POST_SIGNUP_SUCCESS:
            return {
                ...state,
                login: {
                    ...action.data,
                    isOtpVerified: true
                },
                signup: action.data,
                isLoading: false,
            };

        case POST_LOGIN_FAILURE:
        case POST_LOGIN_OTP_FAILURE:
            return {
                ...state,
                isLoading: false,
                loginError: action.message,
            };

        case POST_SIGNUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                regError: action.message,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                isLoading: false,
                [action.option]: null,
            };

        case LOGOUT:
        case CLEAR_STATE:
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('companyId')
            localStorage.removeItem('userId')
            localStorage.removeItem('email')
            localStorage.removeItem('userName')
            localStorage.removeItem('envName')
            // localStorage.removeItem('authorizeToken')
            return {
                ...state,
                login: {},
                signup: {},
                loginError: null,
                regError: null,
                isLoading: false
            }

        default:
            return state;
    }
};

export default auth;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import './index.scss';
import { BrowserRouter  } from 'react-router-dom';
import { createBrowserHistory } from 'history';


let persist = persistStore(store);
const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
        <BrowserRouter>
            <PersistGate loading={null} persistor={persist}>
                <React.Suspense fallback={<p />}>
                    <App />
                </React.Suspense>
            </PersistGate>
        </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

import {
    CLEAR_MESSAGE,
    GET_COMPANY_DATA_FAILURE, GET_COMPANY_DATA_PENDING, GET_COMPANY_DATA_SUCCESS,
    UPDATE_COMPANY_FAILURE, UPDATE_COMPANY_PENDING, UPDATE_COMPANY_SUCCESS
} from '../actionTypes';

const initialState = {
    data: {},
    isLoading: false,
    error: null,
    message: ''
}

const companyData = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_DATA_PENDING:
        case UPDATE_COMPANY_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
                message: ''
            };

        case CLEAR_MESSAGE:
            return {
                ...state,
                isLoading: false,
                error: false,
                message: ''
            }

        case GET_COMPANY_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data.item,
            };

        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                message: action.data.message,
                data: action.data.item
            };

        case GET_COMPANY_DATA_FAILURE:
        case UPDATE_COMPANY_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: '',
                error: action.error
            };

        default:
            return state;
    }
};

export default companyData;

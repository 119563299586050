import {
    POST_AUTHORIZE_FAILURE, POST_AUTHORIZE_PENDING, POST_AUTHORIZE_SUCCESS
} from "../actionTypes";

const initialState ={
    authorizeToken: '',
    isLoading: false,
    error: null
}

const authorize = (state=initialState, action) => {
    switch (action.type) {
        case POST_AUTHORIZE_PENDING:
            return {
                ...state,
                isLoading: true,
            }

        case POST_AUTHORIZE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                authorizeToken: action.data?.token
            }

        case POST_AUTHORIZE_FAILURE:
            return {
                ...state,
                authorizeToken: '',
                isLoading: false,
                error: action.error
            }

        default:
            return state;
    }
}

export default authorize;
import React, {useCallback, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {Button, Icon, InputField, SelectField} from "../../../common";
import {transactionStatuses, transactionTypes} from "../mockData";
import {useFormik} from "formik";
import * as yup from "yup";
import cx from "classnames";
import styles from "../transaction.module.scss";
import RangePicker from "../../../common/Picker/RangePicker";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getQueryParams} from "../../../../utils/helpers";
import moment from "moment/moment";

const validationSchema = yup.object({
    type: yup.string('Enter transaction type'),
    status: yup.string('Enter transaction status'),
    from_date: yup.date().nullable(),
    to_date: yup.date().nullable(),
});

const TransactionFilter = ({loading, onFilter}) => {
    const [search, setSearch] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const addQuery = useCallback((values) => {
        let pathname = location.pathname;
        const params = getQueryParams(values)
        navigate({
            pathname: pathname,
            search: params.toLocaleLowerCase()
        });
    }, [location, navigate]);


    const formik = useFormik({
        initialValues: {
            type: 'all',
            status: 'all',
            from_date: searchParams.get('from_date') || moment().subtract(7,'d').format('YYYY-MM-DD'),
            to_date: searchParams.get('to_date') || moment().format('YYYY-MM-DD'),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(values && !loading) {
                addQuery(values)
            }
        },
        onReset: () => {
            searchParams.delete('type');
            searchParams.delete('status');
            searchParams.set('from_date',  moment().subtract(7, 'd').format('YYYY-MM-DD'));
            searchParams.set('to_date',  moment().format('YYYY-MM-DD'));
            navigate({
                pathname: location.pathname,
                search: searchParams.toString().toLocaleLowerCase()
            })
        }
    })

    useEffect(() => {
        addQuery({...formik.values, search: searchParams.get('search')})
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const data = Object.fromEntries([...searchParams]);
        if(Object.keys(data).length) {
            formik.setValues(prev => ({...prev, ...data}));
            onFilter(searchParams.toString())
        }
        if(searchParams.get('search')){
            setSearch(searchParams.get('search'));
        }
        // eslint-disable-next-line
    }, [searchParams]);

    const handleSelect = useCallback((name, value) => {
        formik.setValues(prev => ({...prev, [name]: value}))
    }, [formik])

    const handlePickerChange = useCallback((date) => {
        formik.setValues(prev => ({
            ...prev,
            from_date: date[0] || prev.from_date,
            to_date: date[1] || prev.to_date,
        }))
    }, [formik])

    const handleSearch = useCallback(() => {
        if(search && !loading){
            addQuery({...formik.values, search})
        }
    }, [addQuery, search, loading, formik]);

    const handleClear = useCallback(() => {
        setSearch('')
        if(searchParams.has('search')){
            searchParams.delete('search')
            navigate({
                pathname: location.pathname,
                search: searchParams.toString().toLocaleLowerCase()
            })
        }
    }, [searchParams, location, navigate]);


    return (
        <Grid container className={styles.filter}>
                <div className={styles.filterContainer}>
                    <SelectField
                        name={'type'}
                        label={'Transaction type'}
                        options={transactionTypes}
                        onChange={handleSelect}
                        value={formik.values.type}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helperText={formik.touched.type && formik.errors.type}
                        className={'searchField'}
                    />
                </div>
                <div className={styles.filterContainer}>
                    <SelectField
                        name={'status'}
                        label={'Transaction status'}
                        options={transactionStatuses}
                        onChange={handleSelect}
                        value={formik.values.status}
                        error={formik.touched.status && Boolean(formik.errors.status)}
                        helperText={formik.touched.status && formik.errors.status}
                        className={'searchField'}
                    />
                </div>
                <div className={styles.filterPicker}>
                    <RangePicker
                        values={[
                            new Date(formik.values.from_date),
                            new Date(formik.values.to_date)
                        ]}
                        onChange={handlePickerChange}
                        // reset={resetDates}
                    />
                </div>
                <div className={cx(styles.filterButtons)}>
                    <button type={'button'} onClick={formik.handleSubmit}>Apply</button>
                    <button type={'button'} onClick={formik.handleReset}>Clear</button>
                </div>
            <div className={styles.filterSearch}>
                <InputField
                    placeholder="Search by reference"
                    hasIcon
                    fullWidth
                    iconName="search"
                    iconPosition="start"
                    value={search}
                    className={'search'}
                    onChange={(e) => setSearch(e.target.value)}
                />
                {search && <Icon name={'close'} className={cx(styles.clear)} onClick={handleClear} />}
                <Button
                    text="Search"
                    disabled={!search?.length}
                    className={cx(styles.searchButton)}
                    variant="outlined"
                    size="sm"
                    onClick={handleSearch}
                />
            </div>
        </Grid>
    );
};

export default TransactionFilter;
export const transactionsData = [
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4497",
        account_holder_name: "Barclays",
        summary: "Credit payment",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Barclays",
        created_at: "2022-02-16T07:39:33.780Z",
        status: "success",
        transaction: '+ £13, 000.00'
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4498",
        account_holder_name: "Merge Money Ltd",
        summary: "GBP account ending 1823",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "",
        created_at: "2022-02-16T07:39:33.780Z",
        status: "success",
        transaction: '- £12, 080.00'
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4499",
        account_holder_name: "Electrical Engeneering London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z",
        status: "pending",
        transaction: '- £3, 000.00'
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4459",
        account_holder_name: "Electrical Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-17T07:39:33.780Z",
        status: "success",
        transaction: '+ £78, 000.00'
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4439",
        account_holder_name: "Electrical London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-17T07:39:33.780Z",
        status: "pending",
        transaction: '+ £8, 070.00'
    },
    {
        id: "63a0ef40-7a22-4e68s-9612-361e5f8b439",
        account_holder_name: "Electrical London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-17T07:39:33.780Z",
        status: "success",
        transaction: '- £1, 000.00'
    },
    {
        id: "63a0ef40-7a22-4e68s-9612-361e5f8b479",
        account_holder_name: "Legal Ltd",
        summary: "Payment bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z",
        status: "pending",
        transaction: '- £2, 500.00'
    },
    {
        id: "63a0ef40-7a22-4e68s-9618-361e5f8b479",
        account_holder_name: "London PLC",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z",
        status: "pending",
        transaction: '+ £67, 500.00'
    },
    {
        id: "63a08f40-7a22-4e68s-9612-361e5f8b479",
        account_holder_name: "Carma Ltd",
        summary: "Credit bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-18T07:39:33.780Z",
        status: "pending",
        transaction: '+ £22, 500.00'
    },
    {
        id: "63788ef40-722-4e68s-9612-361e5f8b479",
        account_holder_name: "Marvel",
        summary: "bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-18T07:39:33.780Z",
        status: "success",
        transaction: '- £2, 500.00'
    },
    {
        id: "63a0e8u0-7a22-4e68s-9612-361e5f8b479",
        account_holder_name: "INgo Ltd",
        summary: "Charger bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z",
        status: "success",
        transaction: '+ £29, 500.00'
    },
    {
        id: "63a0ef40-7a22-4e68s-9h62-361e5f8b479",
        account_holder_name: "Magnat Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-17T07:39:33.780Z",
        status: "pending",
        transaction: '- £12, 000.00'
    }
];

export const transactionTypes = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Funds out',
        value: 'funds_out'
    },
    {
        label: 'Funds in',
        value: 'funds_in'
    }
];

export const transactionStatuses = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Processing',
        value: 'processing'
    },
    {
        label: 'Successful',
        value: 'successful'
    },
    {
        label: 'Failed',
        value: 'failed'
    }
]
import * as yup from "yup";

export const renderValidationSchema = (currency) => {
    return yup.lazy(values => {
        const validationSchema = {
            email: yup
                .string('Enter your email')
                .required('Email address is a required field')
                .email('Enter a valid email'),
            account_holder_name: yup
                .string('Full name of the recipient')
                .required('Recipient name is a required field')
                .uppercase(),
            nickname: yup
                .string('Nickname'),
            type: yup
                .string('Type of recipient')
                .required('Please select a type of recipient'),
            country: yup
                .string('Country')
                .required('Please select the country'),
        };

        const validationSchemaWithType = {
            ...validationSchema,
            ...validationSchemaForType(values.type),
        }

        const validationSchemaWithTypeAndCurrency = {
            ...validationSchemaWithType,
            ...validationSchemaForCurrency(currency),
        }

        return yup.object(validationSchemaWithTypeAndCurrency);
    });
}

const validationSchemaForType = (type) => {
    if (type === 'person') {
        return {
            first_name: yup
                .string('Enter your first name')
                .required('Please enter the first name'),
            last_name: yup
                .string('Enter your last name')
                .required('Please enter the last name'),
        };
    } else if (type === 'business') {
        return {
            company_name: yup
                .string('Enter your company name')
                .required('Please enter the company name'),
        };
    }
    return {};
}

const validationSchemaForCurrency = (currency) => {
    if (currency === 'GBP') {
        return {
            sort_code: yup
                .string('Sort code')
                .max(6, 'Sort code must be 6 digits')
                .required('Please enter the Sort code'),
            account_number: yup
                .string('Account number (8 digits)')
                .max(8, 'Account number must be 8 digits')
                .required('Please enter the account number'),
        };
    } else if (currency === 'EUR') {
        return {
            iban: yup
                .string('IBAN number')
                .required('Please enter the iban number')
                .min(8, 'IBAN must be 8 characters'),
            bic: yup
                .string('BIC code')
                .min(8, 'BIC code must be 8 characters')
        };
    }
    return {};
}
import React, {useState, useEffect, useCallback} from 'react';
import {Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {Button, CustomList, Dialog, InputField} from "components/common";
import cx from "classnames";
import Icon from "components/common/Icon";
import CollapsibleContent from "../CollapsibleContent";
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../slice';
import selectState from '../../selectors';
import {actions as fundActions,} from 'components/dashboard/funds/slice';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Tooltip from '@mui/material/Tooltip';
import Pagination from "../../../../common/Pagination";
import styles from "../../recipients.module.scss";
import Skeleton from '@mui/material/Skeleton';
import { isProd } from "utils/helpers";
import { getCurrencyData } from 'store/selectors/currency';


/**
 * @description RecipientsList component is used to display the list of recipients
 * @param {object} props
 * @returns {JSX}
 */
const StyledGrid = styled(Grid)`
    position: relative;
`;

const StyledIcon = styled(Icon)`
    position: absolute;
    top: 13px;
    right: 60px;
    @media (max-width: 1260px) and (min-width: 1250px) {
      right: 54px;
    }
    @media (max-width: 1249px) and (min-width: 1220px) {
      right: 48px;
    }   
    @media (max-width: 1219px) and (min-width: 1201px) {
      right: 26px;
    }
    @media (max-width: 1200px) {
      right: 16px;
    }
  `;

const StyledToolTip = styled(Tooltip)`
    background: #fff;
    color: #000;
`;

const RecipientsList = (props) => {
    const {
        recipients: {
            items,
            total
        },
        isEmpty,
        handleAddRecipient,
        isError,
        isLoading
    } = props;
    const {currency} = useSelector(getCurrencyData);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    // const itemsPerPage = 10;
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); //size
    const [selectedRecipient, setSelectedRecipient] = useState(null);
    const [nickname, setNickname] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [recipientsResults, setRecipientsResults] = useState(items);
    // const [noOfPages, setNoOfPages] = useState(0);

    const {
        isNickNameEdit,
    } = selectState();

    const handleChange = value => {
        setSearchTerm(value);
    };
    useEffect(() => {
        if(searchTerm.length > 2) {
            const results = items.filter(person =>
                person.account_holder_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setRecipientsResults(results);
        } else {
            setRecipientsResults(items);
        }
        return () => {
            setRecipientsResults(items);
        };
    }, [searchTerm, items]);

   useEffect(() => {
       if(items.length > 0 && searchTerm.length === 0) {
           setRecipientsResults(items);
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [items]);

    const handleBeneficiaryClick = (index) => {
        setSelectedIndex(index);
    };

    const handleDelete = (id) => {
        dispatch(actions.resetEditNickName());
        setIsModalOpen(true);
        setSelectedRecipient(id);
    };

   const handleDeleteRecipient = () => {
       dispatch(actions.deleteRecipients({recipient_id: selectedRecipient, nickname: nickname, status: 'inactive'}));
       setSelectedIndex(null)
       setIsModalOpen(false);
       dispatch(actions.resetEditNickName());
    };

    const handleEdit = (id) => {
        dispatch(actions.editNickName());
        setIsModalOpen(true);
        setSelectedRecipient(id);

    };

    const handleEditRecipient = () => {
        dispatch(actions.updateRecipients({recipient_id: selectedRecipient, nickname: nickname, status: 'active'}));
        setIsModalOpen(false);
        setSelectedIndex(null)
        dispatch(actions.resetEditNickName());
    };

    const handleSelectRecipient = (recipient) => {
        dispatch(fundActions.setSelectedRecipient(recipient));
        dispatch(fundActions.setCurrentFundStep(1));
        navigate("/dashboard/funds", { replace: true });
    };

    useEffect(() => {
        setNickname('');
    }, [selectedIndex]);

    const handlePerPageChange = useCallback(value => {
        setRowsPerPage(value);
        setPage(0);
    }, []);

    const handlePageChange= useCallback((page) => {
            setPage(page - 1);
    }, []);

    return (
        <>
            <Grid item md={12} xs={12} mt={3}>
                <Grid container className={cx(styles.filter)}>
                    <StyledGrid item sm={7} xs={12} mb={2} position={'relative'}>
                        <InputField
                            name={'search'}
                            className={'search-recipient'}
                            placeholder="Search"
                            hasIcon
                            iconName="search"
                            iconPosition="start"
                            value={searchTerm}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                        {searchTerm && <StyledIcon name={'close'} onClick={() => setSearchTerm('')} />}
                    </StyledGrid>
                    <Grid item sm={5} xs={12} className={styles['button-wrapper']}>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item className={cx(styles.add)}>
                                <Button
                                    text="+ Add recipient"
                                    variant="outlined"
                                    onClick={handleAddRecipient}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12} className={cx(styles.recipients)}>
                <h2 className={cx(styles.title)}>
                    My recipients
                </h2>
                <Grid item md={12} xs={12} className={cx(styles.list)}>
                    {isLoading &&
                        <div className={cx(styles.empty)}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Skeleton  variant="text" />
                                        <Skeleton  variant="text" />
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Skeleton  variant="text" />
                                        <Skeleton  variant="text" />
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Skeleton  variant="text" />
                                        <Skeleton  variant="text" />
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </div>
                    }

                    {isEmpty && !isError && !isLoading &&
                        <div className={cx(styles.empty)}>
                            <p>Click “Add Recipient” button to start sending payments</p>
                            <Icon name="user-add" />
                        </div>
                    }
                    {!isEmpty && recipientsResults.length > 0 && !isError && !isLoading &&
                        <List>
                            {recipientsResults
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((recipient, index) => (
                                <CustomList
                                    key={recipient.id}
                                    index={index}
                                    title={recipient.account_holder_name}
                                    subtitle={recipient.summary}
                                    avatar={"true"}
                                    customClass={cx(styles.item)}
                                    avatarClass={cx(styles.avatar)}
                                    disabled = {recipient.currency !== currency}
                                    actions={[
                                        <span key={"send_key"}>
                                        {!isProd &&
                                            <StyledToolTip
                                                title="Make a payment"
                                                placement="top"
                                                arrow
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            backgroundColor: "#fff",
                                                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                                                            borderRadius: "5px",
                                                            color: "#000",
                                                            '& .MuiTooltip-arrow': {
                                                                color: '#fff',
                                                            },
                                                        }
                                                    }
                                                }}
                                            >
                                                <span className={cx(styles.action)}>
                                                    <Icon name="send" onClick={() =>  handleSelectRecipient(recipient)}  />
                                                </span>
                                            </StyledToolTip>
                                        }
                                        </span>
                                        ,
                                        <span className={cx(styles.action)} key={"expand_icon"}>
                                                <Icon name={`${index === selectedIndex ? 'caret-up' : 'caret-down'}`} color="#C4C4C4" onClick={() => {
                                                   if (index === selectedIndex) {
                                                       setSelectedIndex(null)
                                                   } else (
                                                       handleBeneficiaryClick(index)
                                                   )

                                                }} />
                                        </span>
                                    ]}
                                    collapsibleContent={
                                        <CollapsibleContent
                                            key={recipient.id}
                                            recipient={recipient}
                                            isOpen={index === selectedIndex}
                                            index={index}
                                            onDelete={(id) => handleDelete(id)}
                                            onEdit={(id) => {
                                                handleEdit(id);
                                                setNickname(recipient.nickname);
                                            }}
                                            onSendFunds={() => handleSelectRecipient(recipient)}
                                        />
                                    }
                                />
                            ))}
                        </List>
                    }
                    {!isEmpty && recipientsResults.length === 0 && searchTerm.length > 2 && !isError && !isLoading &&
                        <div className={cx(styles.empty)}>
                            <p>No results found</p>
                        </div>
                    }
                    {isError && !isLoading &&
                        <div className={cx(styles.empty, styles.error)}>
                            <p>Something went wrong. Please try again later.</p>
                        </div>
                    }
                </Grid>
                {!isEmpty && recipientsResults.length > 0 && !isError && !isLoading && <Pagination
                    data={{items, page: page + 1, size: 10, total }}
                    pageChange={handlePageChange}
                    perPageChange={handlePerPageChange}
                />}

                <Dialog
                    open={isModalOpen}
                    setOpen={() => setIsModalOpen(false)}
                    fullWidth
                    maxWidth={'md'}
                    isSingleButton={!isNickNameEdit}
                    cancelButton={`Cancel`}
                    submitButton={`${isNickNameEdit ? 'Save' : 'Delete'}`}
                    submitAction={isNickNameEdit ?  handleEditRecipient : handleDeleteRecipient}
                    cancelAction={() => setIsModalOpen(false)}
                    className={'recipients-modal-nickname'}
                >
                    <div  className={cx(styles['modal-content'])}>
                    {isNickNameEdit &&
                        <div>
                            <p>Give this account a memorable nickname so you can find it easily.</p>
                            <InputField
                                label="Account nickname"
                                hasIcon
                                onChange={(e) => setNickname(e.target.value)}
                                value={nickname}
                                additionalInputProps={{
                                    maxLength: 30,
                                }}
                                helperText={(nickname && nickname.length > 30) && `Nickname value too long`}
                                error={nickname && nickname.length > 30}
                            />
                        </div>
                    }
                    {!isNickNameEdit &&
                        <p>
                            Are you sure you want to delete recipient?
                        </p>
                    }
                    </div>
                </Dialog>
            </Grid>
        </>
    )
}

export default RecipientsList;
import {
    PATCH_KYC_STATUS_FAILURE,
    PATCH_KYC_STATUS_PENDING,
    PATCH_KYC_STATUS_SUCCESS,
    POST_GENERATE_OTP_FAILURE,
    POST_GENERATE_OTP_PENDING,
    POST_GENERATE_OTP_SUCCESS,
    RESET_PHONE_NUMBER_STATE
} from "../actionTypes";

const initialState ={
    kycData: {},
    phoneNumberSubmitted: false,
    isLoading: false,
    error: null
}

const kyc = (state=initialState, action) => {
    switch (action.type) {
        case PATCH_KYC_STATUS_PENDING:
        case POST_GENERATE_OTP_PENDING:
            return {
                ...state,
                isLoading: true,
            }

        case PATCH_KYC_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                kycData: action.data
            }

        case PATCH_KYC_STATUS_FAILURE:
            return {
                ...state,
                kycData: {},
                isLoading: false,
                error: action.error
            }

        case POST_GENERATE_OTP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                phoneNumberSubmitted: true
            }

        case POST_GENERATE_OTP_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }

        case RESET_PHONE_NUMBER_STATE:
            return {
                ...state,
                phoneNumberSubmitted: false
            }

        default:
            return state;
    }
}

export default kyc;
export const GET_COMPANY_DATA_PENDING = '@COMPANY/GET_COMPANY_DATA_PENDING';
export const GET_COMPANY_DATA_SUCCESS = '@COMPANY/GET_COMPANY_DATA_SUCCESS';
export const GET_COMPANY_DATA_FAILURE = '@COMPANY/GET_COMPANY_DATA_FAILURE';

export const GET_COMPANIES_PENDING = '@COMPANY/GET_COMPANIES_PENDING';
export const GET_COMPANIES_SUCCESS = '@COMPANY/GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = '@COMPANY/GET_COMPANIES_FAILURE';

export const POST_MEMBERS_PENDING = '@COMPANY/POST_MEMBERS_PENDING';
export const POST_MEMBERS_SUCCESS = '@COMPANY/POST_MEMBERS_SUCCESS';
export const POST_MEMBERS_FAILURE = '@COMPANY/POST_MEMBERS_FAILURE';

export const GET_COMPANY_TYPES_PENDING = '@COMPANY/GET_COMPANY_TYPES_PENDING';
export const GET_COMPANY_TYPES_SUCCESS = '@COMPANY/GET_COMPANY_TYPES_SUCCESS';
export const GET_COMPANY_TYPES_FAILURE = '@COMPANY/GET_COMPANY_TYPES_FAILURE';

export const UPDATE_COMPANY_PENDING = '@COMPANY/UPDATE_COMPANY_PENDING';
export const UPDATE_COMPANY_SUCCESS = '@COMPANY/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = '@COMPANY/UPDATE_COMPANY_FAILURE';

export const GET_INDUSTRY_TYPE_PENDING = '@COMPANY/GET_INDUSTRY_TYPE_PENDING';
export const GET_INDUSTRY_TYPE_SUCCESS = '@COMPANY/GET_INDUSTRY_TYPE_SUCCESS';
export const GET_INDUSTRY_TYPE_FAILURE = '@COMPANY/GET_INDUSTRY_TYPE_FAILURE';

export const GET_FUND_SOURCES_PENDING = '@COMPANY/GET_FUND_SOURCES_PENDING';
export const GET_FUND_SOURCES_SUCCESS = '@COMPANY/GET_FUND_SOURCES_SUCCESS';
export const GET_FUND_SOURCES_FAILURE = '@COMPANY/GET_FUND_SOURCES_FAILURE';

export const CHECK_REGISTRATION_NUMBER_PENDING = '@COMPANY/CHECK_REGISTRATION_NUMBER_PENDING';
export const CHECK_REGISTRATION_NUMBER_SUCCESS = '@COMPANY/CHECK_REGISTRATION_NUMBER_SUCCESS';
export const CHECK_REGISTRATION_NUMBER_FAILURE = '@COMPANY/CHECK_REGISTRATION_NUMBER_FAILURE';

export const CLEAR_MESSAGE = '@COMPANY/CLEAR_MESSAGE'
export const CLEAR_COMPANY_NUMBER = '@COMPANY/CLEAR_COMPANY_NUMBER'
export const CLEAR_COMPANIES = '@COMPANY/CLEAR_COMPANIES'

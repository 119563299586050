import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {getCompanyDataPending} from "store/actions";
import {getCompanyData} from "store/selectors";
import {actions} from "./account/slice";
import selectAccountsState from './account/selectors';
import {Icon, Button} from "../common";

import styles from "assets/styles/dashboard.module.scss";
import cx from "classnames";

import Skeleton from '@mui/material/Skeleton';
import styled from "styled-components/macro";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { isProd } from "utils/helpers";
import { getCurrencyData } from 'store/selectors/currency';

export const StyledAccountInfo = styled('div')`
  width: 90%;
  height: auto;
  padding: 25px 16px;
  
  @media (min-width: 840px) {
    width: 275px;
    height: 105px;
  }
  background: ${props => props.type === 'warning' ? 'rgba(255, 217, 100, 0.05)' : 'rgba(0, 160, 155, 0.05)'};

  border: 1px solid ${props => props.type === 'warning' ? '#EBB102' : '#00A09B'};
  border-radius: 15px;
  h5, p {
    font: 14px 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${props => props.type === 'warning' ? '#EBB102' : '#00A09B'};
    margin-bottom: 8px;
  }
  p {
    font-weight: 400;
    margin-bottom: 8px;
  }
  .icon-holder {
    margin-bottom: 16px;
  }
`;

export const GBPAccount = ({accounts}) => {
    if (!accounts?.length) {
        return <></>;
    }
    const gbpAccount = accounts?.filter(account => account?.currency === 'GBP')?.[0];
    return (
        <>
            <p>Sort code: {gbpAccount?.sort_code?.replace(/\B(?=(\d{2})+(?!\d))/g, "-") || 'N/A'}</p>
            <p>Account number: {gbpAccount?.account_number || 'N/A'}</p>
        </>
    );
};

export const EURAccount = ({accounts}) => {
    if (!accounts?.length) {
        return <></>;
    }
    const eurAccount = accounts?.filter(account => account?.currency === 'EUR')?.[0];
    return (
        <>
            <p>IBAN: {eurAccount?.iban || 'N/A'}</p>
            <p>BIC: {eurAccount?.bic || 'N/A'}</p>
        </>
    );
};

/**
 * Dashboard component.
 */
const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {accounts} = selectAccountsState();

    const [copy, setCopy] = useState({
        value:  '',
        copied: false,
    });

    const account_activate = true; //TODO: change after backend will be ready
    const companyData = useSelector(getCompanyData);
    const {currency} = useSelector(getCurrencyData);
    const [isRegistered, setIsRegistered] = useState(false);
    const [isApplying, setIsApplying] = useState(false);
    const [isCompanySubmitted, setIsCompanySubmitted] = useState(false);
    const [defaultAccount, setDefaultAccount] = useState(null);

    useEffect(() => {
        setDefaultAccount(accounts?.items?.filter(account => account.currency === currency)?.[0]);
    }, [accounts, currency]);

    useEffect(() => {
        if(!defaultAccount) {
            return;
        }
        switch(currency) {
            case 'GBP':
                setCopy(val => ({...val, value: `Sort code: ${defaultAccount?.sort_code || 'N/A'} Account number: ${defaultAccount?.account_number}`}));
                return;
            case 'EUR':
                setCopy(val => ({...val, value: `IBAN: ${defaultAccount?.iban || 'N/A'} BIC: ${defaultAccount?.bic || 'N/A'}`}));
                return;
            default:
        }
    }, [defaultAccount, currency]);

    useEffect(() => {
        const timer = () => setTimeout(() => setCopy(val => ({...val, copied: false})), 3000);
        const timerId = timer();
        return () => {
            clearTimeout(timerId);
        };
    }, [copy.copied]);

    useEffect(() => {
        dispatch(actions.getAccounts({type: 'main_account', allCurrencies: true}));
    }, [dispatch, currency]);

    useEffect(() => {
        dispatch(getCompanyDataPending());
    }, [dispatch]);

    useEffect(() => {
        if(companyData.data) {
            if(companyData.data.internal_state) {
                setIsRegistered(companyData.data.internal_state.includes('registered') || companyData.data.internal_state.includes('applying') );
                setIsCompanySubmitted(companyData.data.internal_state.includes('verifying_authorization_started') || companyData.data.internal_state.includes('verifying_kyb_started'));
                setIsApplying(defaultAccount);
            }
        }
        return () => {
            setIsRegistered(false);
            setIsApplying(false);
            setIsCompanySubmitted(false);
        }
    }, [companyData.data, defaultAccount])

    const getIcon = () => {
        return currency === 'GBP' ? 'gb': 'eur';
    }

    const getCurrencySymbol = () => {
        return currency === 'GBP' ? '£' : '€';
    }

    const getBalance = () => {
        return defaultAccount?.balance ?? '-';
    }

    return !defaultAccount ? (
        <div className={cx(styles.dashboard)}>
            <p className={cx(styles.enableAccount)}>
                To enable the new currency account please reach out to the Merge customer support at&nbsp; 
                    <a href="mailto:cs@mergedup.com">cs@mergedup.com</a>.
                </p>
        </div>
    ) : (
        <div className={cx(styles.dashboard)}>
            <div className={cx(styles.content)}>
                <div className={cx(styles.actions)}>
                    <div className={cx(styles.balance)} id={"main-account-dashboard"}>
                        {(isRegistered || isCompanySubmitted) ?
                           <>
                               <h3>Main account balance</h3>
                               <h2>{getCurrencySymbol()}{getBalance()}</h2>
                               <h5><Icon name={getIcon()} width="20px" /> Total balance in {currency} </h5>
                           </>
                            :
                           <>
                               <h2><Skeleton style={{width: '95%'}}  />
                                   <Skeleton style={{width: '95%'}}  />
                                   <Skeleton style={{width: '95%'}}  />
                               </h2>
                           </>
                        }

                    </div>
                    <div className={cx(styles.subBalanceSections)}>
                        <div className={cx(styles.subBalance)}>
                            {(isRegistered || isCompanySubmitted) ?
                                <>
                                    <h3>Main account</h3>
                                    {/*<h2>£{account?.[0]?.balance ? account?.[0]?.balance : '-'}</h2>*/}
                                    <h5 onClick={() => navigate("/dashboard/transactions")}>Go to transactions -&gt;</h5>
                                </>
                                :
                                <>
                                    <h3><Skeleton style={{width: '90%'}} /></h3>
                                    <h3><Skeleton style={{width: '90%'}} /></h3>
                                    <h3><Skeleton style={{width: '90%'}} /></h3>
                                </>
                            }
                        </div>
                        <div className={cx(styles.subBalance, styles.second)}>
                            {(isRegistered || isCompanySubmitted) ?
                                <>
                                    <h3>Sub accounts</h3>
                                    <h5 onClick={() => navigate("/dashboard/sub-accounts")}>Go to accounts -&gt;</h5>
                                </>
                                :
                                <>
                                    <h3><Skeleton style={{width: '90%'}} /></h3>
                                    <h3><Skeleton style={{width: '90%'}} /></h3>
                                    <h3><Skeleton style={{width: '90%'}} /></h3>
                                </>
                            }
                        </div>
                    </div>
                    {!isProd &&
                        <Button
                            text="+ Send funds"
                            variant="outlined"
                            margin={{top: '48px'}}
                            disabled={isRegistered || isCompanySubmitted}
                            onClick={() => {
                                navigate("/dashboard/funds", { replace: true });
                            }}
                        />
                    }
                </div>
                <div className={cx(styles.transactions)}>
                    {(isRegistered || isCompanySubmitted) && !isApplying &&
                        <StyledAccountInfo type="warning">
                                <div style={{
                                    textAlign: 'center'
                                }}>
                                    {(isRegistered && !isApplying) &&
                                       <>
                                           <div className={'icon-holder'}>
                                               <Icon name={'alert-info'}/>
                                           </div>
                                           <p>
                                               <strong>
                                                   Please activate your account to start using Merge
                                               </strong>
                                           </p>
                                           <p>
                                               Click on the Activate your account button above
                                           </p>
                                       </>
                                    }
                                    {(isCompanySubmitted && !isApplying) &&
                                       <>
                                           <div className={'icon-holder'}>
                                               <Icon name={'hour-glass-empty'}/>
                                           </div>
                                           <p>
                                               <strong>
                                                   Account verification is in progress
                                               </strong>
                                           </p>
                                           <p>
                                               Your account will be active soon. We will let you know
                                           </p>
                                       </>
                                    }
                                </div>
                        </StyledAccountInfo>
                    }
                    {((!isRegistered && !isCompanySubmitted) || isApplying) &&
                        <StyledAccountInfo>
                            <div>
                                <CopyToClipboard text={copy.value}>
                                    <Icon name={'colored-copy'} style={{float: 'right'}} onClick={() => setCopy(val => ({...val, copied: true}))} className={cx(styles.copyIcon)}/>
                                </CopyToClipboard>
                                <h5>Add funds via bank transfer:</h5>

                                { currency === 'GBP' && <GBPAccount accounts={accounts?.items}/>}
                                { currency === 'EUR' && <EURAccount accounts={accounts?.items}/>}
                                {copy.copied && <span className={cx(styles.copied)}>Copied!</span>}
                            </div>
                        </StyledAccountInfo>
                    }
                </div>
            </div>

            {!account_activate && <>
                <h1 className={cx(styles.title)}>Test the features now</h1>
                <p className={cx(styles.info)}>Something more about Merge that can help you get started. The full dashboard will be available after your account is active, checked and approved.</p>
            </>}
        </div>
    )
};

export default Dashboard;

import {
    GET_COMPANY_TYPES_FAILURE,
    GET_COMPANY_TYPES_PENDING,
    GET_COMPANY_TYPES_SUCCESS
} from '../actionTypes';

const initialState = {
    data: [],
    isLoading: false,
    error: null
}

const companyType = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_TYPES_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case GET_COMPANY_TYPES_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data
            };

        case GET_COMPANY_TYPES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default companyType;

import {
    CLEAR_OTP_CODE,
    PATCH_KYC_STATUS_FAILURE,
    PATCH_KYC_STATUS_PENDING,
    PATCH_KYC_STATUS_SUCCESS,
    POST_GENERATE_OTP_FAILURE,
    POST_GENERATE_OTP_PENDING,
    POST_GENERATE_OTP_SUCCESS, RESET_PHONE_NUMBER_STATE, VERIFY_OTP_FAILURE,
    VERIFY_OTP_PENDING, VERIFY_OTP_SUCCESS
} from "../actionTypes";

export const updateKycStatusPending = (option) => ({
    type: PATCH_KYC_STATUS_PENDING,
    option
})

export const updateKycStatusSuccess = (data) => ({
    type: PATCH_KYC_STATUS_SUCCESS,
    data
})

export const updateKycStatusFailure = (error) => ({
    type: PATCH_KYC_STATUS_FAILURE,
    error
})


export const postGenerateOtpPending = (option) => ({
    type: POST_GENERATE_OTP_PENDING,
    option
})

export const postGenerateOtpSuccess = (data) => ({
    type: POST_GENERATE_OTP_SUCCESS,
    data
})

export const postGenerateOtpFailure = (error) => ({
    type: POST_GENERATE_OTP_FAILURE,
    error
})


export const verifyOtpPending = (option) => ({
    type: VERIFY_OTP_PENDING,
    option
})

export const verifyOtpSuccess = (data) => ({
    type: VERIFY_OTP_SUCCESS,
    data
})

export const verifyOtpFailure = (error) => ({
    type: VERIFY_OTP_FAILURE,
    error
})

export const clearOtpCode = () => ({
    type: CLEAR_OTP_CODE
})

export const resetPhoneNumberState = () => ({
    type: RESET_PHONE_NUMBER_STATE
})
export const POST_LOGIN_PENDING = '@AUTH/POST_LOGIN_PENDING';
export const POST_LOGIN_SUCCESS = '@AUTH/POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILURE = '@AUTH/POST_LOGIN_FAILURE';

export const POST_LOGIN_OTP_PENDING = '@AUTH/POST_LOGIN_OTP_PENDING';
export const POST_LOGIN_OTP_SUCCESS = '@AUTH/POST_LOGIN_OTP_SUCCESS';
export const POST_LOGIN_OTP_FAILURE = '@AUTH/POST_LOGIN_OTP_FAILURE';

export const POST_SIGNUP_PENDING = '@AUTH/POST_SIGNUP_PENDING';
export const POST_SIGNUP_SUCCESS = '@AUTH/POST_SIGNUP_SUCCESS';
export const POST_SIGNUP_FAILURE = '@AUTH/POST_SIGNUP_FAILURE';

export const CHANGE_PASSWORD_PENDING = '@AUTH/CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_SUCCESS = '@AUTH/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = '@AUTH/CHANGE_PASSWORD_FAILURE';

export const POST_AUTHORIZE_PENDING = '@AUTH/POST_AUTHORIZE_PENDING';
export const POST_AUTHORIZE_SUCCESS = '@AUTH/POST_AUTHORIZE_SUCCESS';
export const POST_AUTHORIZE_FAILURE = '@AUTH/POST_AUTHORIZE_FAILURE';

export const LOGOUT = '@AUTH/LOGOUT';
export const CLEAR_STATE = '@AUTH/CLEAR_STATE';
export const CLEAR_ERRORS = '@AUTH/CLEAR_ERRORS';
export const CLEAR_PASSWORD_MESSAGE = '@AUTH/CLEAR_PASSWORD_MESSAGE';
export const CLEAR_SET_PASSWORD = '@AUTH/CLEAR_SET_PASSWORD';

export const POST_FORGOT_PASSWORD_PENDING = '@AUTH/POST_FORGOT_PASSWORD_PENDING';
export const POST_FORGOT_PASSWORD_SUCCESS = '@AUTH/POST_FORGOT_PASSWORD_SUCCESS';
export const POST_FORGOT_PASSWORD_FAILURE = '@AUTH/POST_FORGOT_PASSWORD_FAILURE';

export const POST_CHECK_EMAIL_PENDING = '@AUTH/POST_CHECK_EMAIL_PENDING';
export const POST_CHECK_EMAIL_SUCCESS = '@AUTH/POST_CHECK_EMAIL_SUCCESS';
export const POST_CHECK_EMAIL_FAILURE = '@AUTH/POST_CHECK_EMAIL_FAILURE';

export const SET_RE_CAPTCHA_TOKEN = '@AUTH/SET_RE_CAPTCHA_TOKEN';


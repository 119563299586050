import React, {useCallback, useEffect, useState} from "react";
import { CustomList, InputField} from "components/common";
import {Grid} from "@mui/material";
import cx from "classnames";
import styles from "../send-funds.module.scss";
import TextField from '@mui/material/TextField';
import styled from "styled-components";
import selectState from '../selectors';
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../slice";
import NumberFormat from "react-number-format";
import {actions as recipientsActions} from "components/dashboard/recipients/slice";
import recipientState from "components/dashboard/recipients/selectors";
import {clean, validateReference} from "../../../../utils/helpers";
import { getCurrencyData } from "store/selectors/currency";
import NumberInput from "components/common/NumberInput";

const StyledInput = styled(TextField)`
  .MuiInput-root {
    margin-top: 6px;
    font-size: 18px;
  }
  .MuiInputLabel-root {
    display: none;
  }
  .Mui-focused {
    color: #00A09B !important;
    
    &:after, &:hover {
      border-bottom-color: #00A09B;
    }
  }
  .MuiInput-underline {
    width: 100%;
  }
  .MuiInput-underline:hover:before {
    border-bottom-color: #00A09B;
  }
  input {
    font-size: 24px;
  }
`;

const StyledInfo = styled('p')`
  margin-top: 8px;
  font: 14px Inter;
  color: #777777;
`

const EnterAmount = () => {
  const dispatch = useDispatch();
  const [ reference, setReference ] = useState('');
  const [ touched, setTouched ] = useState(false);
  const [ sendAmount, setSendAmount ] = useState('0');
  const [insufficientBalance, setInsufficientBalance] = React.useState(false);
  const [countryCode, setCountryCode] = useState('GB');
  const CurrencyFlagComponent = () =>  <span style={{marginRight: '10px'}}><ReactCountryFlag countryCode={countryCode} svg /></span>;

  const {
        selectedRecipient,
        fromAccount,
        transaction_fee: {
            fee_amount,
        },
        transaction_details,
        isTransactionEdit,
        referenceInfo
    } = selectState();

    const {currency} = useSelector(getCurrencyData);

    const {
        isFromSendFundsPage,
    } = recipientState();

    useEffect(() => {
        setCountryCode(currency === 'GBP' ? 'GB': 'EU');
    }, [currency]);

    useEffect(() => {
        if(
            (selectedRecipient !== null &&
            !insufficientBalance)
            && fromAccount
            && parseFloat(sendAmount) !== 0
            && sendAmount !== ''
        ){
            dispatch(actions.resetDisabledStep());
        } else {
            dispatch(actions.disableStep());
        }
    }, [dispatch, fromAccount, fee_amount, selectedRecipient, insufficientBalance, sendAmount]);

    useEffect(() => {
        if(isFromSendFundsPage) {
            dispatch(recipientsActions.resetRecipients());
            dispatch(recipientsActions.getRecipients());
        }
    } , [dispatch, isFromSendFundsPage]);


    useEffect(() => {
        if (sendAmount && fromAccount) {
            let amount = parseFloat(fromAccount.balance - sendAmount );
            setInsufficientBalance(amount < 0);
        }
    }, [sendAmount, fromAccount]);

    useEffect(() => {
         if(isTransactionEdit) {
             setSendAmount(transaction_details.amount);
             setReference(transaction_details.reference)
         }
    },[isTransactionEdit,transaction_details])

     const handleReferenceChange = () => {
         dispatch(actions.generateTransactionDetails(clean({
             amount: sendAmount,
             currency: currency,
             recipient_id: selectedRecipient.id,
             fee: 0,
             reference,
         })));
     }

    useEffect(() => {
        if(touched){
            if(reference.length < 6) {
                dispatch(actions.setReferenceInfo('Reference must contain at least 6 letters or numbers'))
            } else if(reference.length > 18) {
                dispatch(actions.setReferenceInfo('Limit the characters that can be entered on FE for reference field to 18'))
            } else if(!validateReference(reference)){
                dispatch(actions.setReferenceInfo('Reference can only have a-z, A-Z, 0-9, spaces, ampersand (&), hyphen (-), full stop (.), solidus (/) characters'))
            } else if(/^(.)\1+$/.test(reference?.replace(/ /g,''))) {
                dispatch(actions.setReferenceInfo('All the alphanumeric characters cannot be the same'))
            } else {
                dispatch(actions.setReferenceInfo(''))
            }
        } else {
            dispatch(actions.setReferenceInfo(''))
        }
    }, [reference, touched, dispatch])

     const handleChange = useCallback((event) => {
         const {value} = event?.target;
         setReference(value)
         setTouched(!!value?.length)
     }, [])

    return (
        <Grid item xs={12} md={12} lg={12} sm={12}>
            <Grid container mb={3}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                    <h2 className={cx(styles['stepper-title'])}>
                        Sending to
                    </h2>
                </Grid>
            </Grid>
            <Grid container mb={4}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                    {selectedRecipient && selectedRecipient.id &&
                        <CustomList
                            key={selectedRecipient.id}
                            title={selectedRecipient.account_holder_name}
                            subtitle={selectedRecipient.summary}
                            avatar={"true"}
                            customClass={cx(styles['list'], styles['selected'])}
                            avatarClass={cx(styles['avatar'])}
                            disabled={true}
                        />
                    }
                </Grid>
            </Grid>
            <Grid container mb={2}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                    <h2 className={cx(styles['stepper-title'])}>
                        Enter the amount to transfer
                    </h2>
                </Grid>
            </Grid>
            <Grid container mb={2}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                    <p className={cx(styles['available-amount'])}>
                        Available balance:
                        {fromAccount ? <span className={cx(styles['amount'], insufficientBalance && styles['error'])} style={{color: `${insufficientBalance ? '#FF8888' : '#00A09B'}`}}>{' '}  <NumberFormat value={fromAccount.balance} thousandSeparator={true} displayType={'text'} /> {fromAccount.currency}</span> : ' -'}
                    </p>
                </Grid>
            </Grid>
            {(insufficientBalance) &&
                <Grid container mb={2}>
                    <Grid item md={12}>
                        <p className={cx(styles['error'])} style={{ fontSize: '14px' }}>
                            Insufficient funds. Please recheck the amount to send.
                        </p>
                    </Grid>
                </Grid>
            }
            <Grid container mb={2}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                <NumberInput
                        name={'send_amount'}
                        label={'You send:'}
                        className={cx(styles['amount-field'])}
                        // placeholder={'xxxxxx.xx'}
                        decimalScale={2}
                        value={sendAmount}

                        customInput={StyledInput}
                        variant="standard"

                        isAllowed={(values) => {
                            const { formattedValue, value, floatValue } = values;
                            if ( value.length > 1 && /^0*$/.test(value)) {
                                return false;
                            }
                            return formattedValue === ""
                                    || value === "." 
                                    || (floatValue >= 0 && floatValue <= 2147483647);
                        }} 
                        onBlur={() => {
                            if (sendAmount === '') {
                                setSendAmount('0');
                            }
                            handleReferenceChange();
                        }}
                        onFocus={() => {
                            if (sendAmount === '0') {
                                setSendAmount('');
                            }
                        }}                    
                        onValueChange={(amount) => setSendAmount(amount.value)}
                        thousandSeparator=","
                        required={true}
                        InputProps={{
                            endAdornment: <>{CurrencyFlagComponent()}{' '} {currency}</>
                        }}
                    />
                    <p className={cx(styles['fee-information'])}>
                        Should arrive: <span className={cx(styles['amount'])}>Instantly</span>
                    </p>
                </Grid>
            </Grid>
            {(/^0[0-9].*$/).test(sendAmount) &&
                <Grid container mb={2}>
                    <Grid item md={12}>
                        <p className={cx(styles['error'])} style={{fontSize: '14px' }}>
                            Please enter an amount that is larger than 0
                        </p>
                    </Grid>
                </Grid>
            }
            <Grid container mb={4}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                    <InputField
                        placeholder="Reference"
                        hasIcon
                        onBlur={handleReferenceChange}
                        onChange={handleChange}
                        label="Reference:"
                        value={reference}
                    />
                    <StyledInfo>{referenceInfo}</StyledInfo>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EnterAmount;

import {ReactComponent as Logo} from "./logo.svg";
import {ReactComponent as Background} from "./background.svg";
import {ReactComponent as BackgroundMD} from "./background-md.svg";
import {ReactComponent as BackgroundSM} from "./background-sm.svg";
import {ReactComponent as AccountBalance} from "./account-balance.svg";
import {ReactComponent as Language} from "./language.svg";
import {ReactComponent as GppGood} from "./gpp-good.svg";
import {ReactComponent as HidePassword} from "./hide-password.svg";
import {ReactComponent as ShowPassword} from "./show-password.svg";
import {ReactComponent as Payments} from "./payments.svg";
import {ReactComponent as Accounts} from "./accounts.svg";
import {ReactComponent as Transactions} from "./transactions.svg";
import {ReactComponent as Balance} from "./balance.svg";
import {ReactComponent as Settings} from "./settings.svg";
import {ReactComponent as Profile} from "./profile.svg";
import {ReactComponent as Logout} from "./logout.svg";
import {ReactComponent as CaretDown} from "./caret-down.svg";
import {ReactComponent as CaretRight} from "./caret-right.svg";
import {ReactComponent as WWW} from "./www.svg";
import {ReactComponent as Checkmark} from "./checkmark.svg";
import {ReactComponent as Ellipse} from "./ellipse.svg";
import {ReactComponent as EllipseActive} from "./ellipse-active.svg";
import {ReactComponent as EllipseCompleted} from "./ellipse-copleted.svg";
import {ReactComponent as DottedLine} from "./dotted-line.svg";
import {ReactComponent as Remove} from "./remove.svg";
import {ReactComponent as Edit} from "./edit.svg";
import {ReactComponent as Close} from "./close.svg";
import {ReactComponent as Loading} from "./loading.svg";
import {ReactComponent as Passed} from "./passed.svg";
import {ReactComponent as LastStep} from "./last-step.svg";
import {ReactComponent as HorizontalDashes} from "./horizontal-dashes.svg";
import {ReactComponent as Info} from "./info.svg";
import {ReactComponent as TooltipArrow} from "./tooltip-arrow.svg";
import {ReactComponent as Dashboard} from "./dashboard.svg";
import {ReactComponent as Completed} from "./completed.svg";
import {ReactComponent as DashboardInfo} from "./dashboard-info.svg";
import {ReactComponent as GB} from "./flags/GB.svg";
import {ReactComponent as EUR} from './flags/euro.svg';
import {ReactComponent as Refresh} from "./refresh.svg";
import {ReactComponent as BlueGradientDot} from "./blue-gradient-dot.svg";
import {ReactComponent as GreenGradientDot} from "./green-gradient-dot.svg";
import {ReactComponent as TransparentDot} from "./transparent-dot.svg";
import {ReactComponent as Menu} from "./menu.svg";
import {ReactComponent as DottedHorizontal} from "./dotted-line-horizontal.svg";
import {ReactComponent as Warning} from "./warning.svg";
import {ReactComponent as UsersMultiple} from "./users-multiple.svg";
import {ReactComponent as Search } from "./search.svg";
import { ReactComponent as UserAdd } from "./users-add.svg";
import { ReactComponent as Send } from "./send.svg";
import { ReactComponent as CaretUp } from "./caret-up.svg";
import { ReactComponent as Globe } from "./world.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as NoFunds } from "./insufficient-funds.svg";
import { ReactComponent as SubAccounts } from "./sub-accounts.svg";
import { ReactComponent as UserFilled } from "./user-filled.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as FailedCircle } from "./failed-circle.svg";
import { ReactComponent as SuccessCircle } from "./success-circle.svg";
import { ReactComponent as PendingCircle } from "./pending-circle.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as CircleGradient } from "./circle-gradient.svg";
import { ReactComponent as ErrorClose } from "./error-close.svg";
import { ReactComponent as StepDefault} from "./step-default.svg";
import { ReactComponent as StepActive } from "./step-active.svg";
import { ReactComponent as Upload } from "./upload.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as Bin } from "./bin.svg";
import { ReactComponent as SubAccountCircle } from "./sub-account-circle.svg";
import { ReactComponent as SubAccount } from "./sub-account.svg";
import { ReactComponent as DisableAccount } from "./disable-account.svg";
import { ReactComponent as CanNotDisable } from "./cannot-disable.svg";
import { ReactComponent as ModalImage } from "./modal-image.svg";
import { ReactComponent as FundsSuccess } from "./funds-success.svg";
import { ReactComponent as FundsError } from "./funds-error.svg";
import { ReactComponent as ColoredCopy } from "./colored-copy.svg";
import { ReactComponent as InfoAlert } from "./alert-info.svg";
import { ReactComponent as HourGlassEmpty } from "./hour-glass-empty.svg";
import { ReactComponent as Verified } from "./verified.svg";
import { ReactComponent as FileDownload } from "./file-download.svg";

const Icons = {
    "logo": Logo,
    "background-lg": Background,
    "background-md": BackgroundMD,
    "background-sm": BackgroundSM,
    "account-balance": AccountBalance,
    "language": Language,
    "gpp-good": GppGood,
    "hide-password": HidePassword,
    "show-password": ShowPassword,
    "payments": Payments,
    "accounts": Accounts,
    "transactions": Transactions,
    "balance": Balance,
    "settings": Settings,
    "profile": Profile,
    "logout": Logout,
    "caret-down": CaretDown,
    "caret-right": CaretRight,
    "www": WWW,
    "checkmark": Checkmark,
    "ellipse": Ellipse,
    "ellipse-active": EllipseActive,
    "ellipse-completed": EllipseCompleted,
    "dotted-line": DottedLine,
    "remove": Remove,
    "edit": Edit,
    "close": Close,
    "loading": Loading,
    "passed": Passed,
    "last-step": LastStep,
    "horizontal-dashes": HorizontalDashes,
    "info": Info,
    "tooltip-arrow": TooltipArrow,
    "dashboard": Dashboard,
    "completed": Completed,
    "dashboard-info": DashboardInfo,
    "gb": GB,
    "eur": EUR,
    "refresh": Refresh,
    "blue-gradient-dot": BlueGradientDot,
    "green-gradient-dot": GreenGradientDot,
    "beneficiaries": UsersMultiple,
    "search": Search,
    "user-add": UserAdd,
    "send": Send,
    "caret-up": CaretUp,
    "globe": Globe,
    "transparent-dot": TransparentDot,
    "dotted-horizontal": DottedHorizontal,
    "warning": Warning,
    "menu": Menu,
    "delete": Delete,
    "arrow-left": ArrowLeft,
    "arrow-right": ArrowRight,
    "insufficient-funds": NoFunds,
    "sub-accounts": SubAccounts,
    "user-filled": UserFilled,
    "copy": Copy,
    "failed-circle": FailedCircle,
    "success-circle": SuccessCircle,
    "pending-circle": PendingCircle,
    "calendar": Calendar,
    "circle-gradient": CircleGradient,
    "error-close": ErrorClose,
    "step-active": StepActive,
    "step-default": StepDefault,
    "upload": Upload,
    "file": File,
    "bin": Bin,
    "sub-account": SubAccount,
    "sub-account-circle": SubAccountCircle,
    "disable-account": DisableAccount,
    "cannot-disable": CanNotDisable,
    "modal-image": ModalImage,
    "funds-success": FundsSuccess,
    "funds-error": FundsError,
    "colored-copy": ColoredCopy,
    "alert-info": InfoAlert,
    "hour-glass-empty": HourGlassEmpty,
    "verified": Verified,
    "file-download": FileDownload
}
 export default Icons;
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ThemeProvider} from "@mui/material";
import countriesData from "country-telephone-data";
import {createTheme} from "@mui/material/styles";
import ReactCountryFlag from "react-country-flag";


import {Icon, InputField} from "../../common";

import styles from "../../../assets/styles/business.module.scss";
import cx from "classnames";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const PhoneNumber = (props) => {
    const ref = useRef(null);
    const {phoneNumber, value, onChange: parentChange, onNumberChange} = props;
    const [open, setOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const [isoCode, setIsoCode] = useState('');
    const [inputValue, setInputValue] = useState('');

    useOnClickOutside(ref, () => {
        setTimeout(() => {
            setOpen(false)
            setFocus(false)
        }, 100)
    });

    useEffect(() => {
        setInputValue(value)
    }, [value])

    const filtered = countriesData.allCountries
        .filter(({name}) => name !== 'France' && name !== 'United Kingdom')
        .map((item) => ({...item, dialCode: `+${item.dialCode}`}));

    const countries = useMemo(() => [
        {dialCode: "+44", format: "+.. .... ......", iso2: "gb", name: "United Kingdom", priority: 0},
        {dialCode: "+33", format: "+.. . .. .. .. ..", iso2: "fr", name: "France", priority: 0},
        ...filtered
    ], [filtered])

    const [options, setOptions] = useState(countries)

    const materialTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: value.length === 3 ? '106px' :value.length === 4 ? '120px': value.length === 5 ? '126px' : '114px',
                        border: `1px solid ${focus ? '#016C87' : '#c4c4c4'}`,
                        borderRadius: '15px',
                        maxHeight: '56px',
                        paddingLeft: '20px',
                        marginRight: '10px',
                        boxShadow: focus &&'0 0 5px #016C87',
                    },
                    input: {
                        marginLeft: isoCode ? '20px' : '10px',
                        display: 'flex',
                    }
                }
            }
        }
    });

     const handleChange  = useCallback((value) => {
         parentChange(value.dialCode, value);
         setOpen(false)
         onNumberChange()
    }, [parentChange, onNumberChange]);

     const onChange = useCallback((event) => {
         setInputValue(event.target.value)
         const data = countries.filter(({dialCode}) => dialCode.includes(event.target.value))
         setOptions(data)
         setOpen(true)
         onNumberChange()
     }, [countries, onNumberChange]);

     useEffect(() => {
         const code = options.find(({dialCode}) => dialCode === value)?.iso2
         setIsoCode(code)
     } , [value, options]);

     const handleClear = useCallback(() => {
         setInputValue('')
         parentChange('', {})
         setOptions(countries)
         onNumberChange()
     },[onNumberChange, parentChange, countries])

    const adornment = {
        startAdornment: <InputAdornment position={"start"} style={{position: 'absolute', left: '12px'}}>
            <ReactCountryFlag countryCode={isoCode} svg className={cx(styles.flag)} />
        </InputAdornment>,
        endAdornment: <InputAdornment position={"end"} style={{position: 'absolute', right: '12px', zIndex: 999, display: value ? 'inherit' : 'none'}}>
            <Icon name={'close'} height={'12px'} width={'12px'} onClick={handleClear}/>
        </InputAdornment>,
    }

    const handleClick = useCallback(() => {
        // const data = countries.filter(({dialCode}) => dialCode.includes(value))
        // setOptions(data)
        setOpen(true)
    }, [])

    return (
        <div style={{display: 'flex', position: 'relative'}}>
            <ThemeProvider theme={materialTheme}>
                <TextField
                    name={'phone'}
                    variant="outlined"
                    id={`outlined-basic_phone`}
                    value={inputValue}
                    onClick={handleClick}
                    InputProps={adornment}
                    // onBlur={() => {}
                    onChange={(event) => onChange(event)}
                />
                {open && <div className={cx(styles.autocompleteMenuList)} ref={ref}>
                    {options?.length > 0 ? options.map(option =>
                        <div
                            style={option.iso2 === 'fr'
                                ? { paddingBottom: '15px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '1px solid #C4C4C4', }
                                : {}
                            }
                            key={Math.random()}
                            className={cx(styles.autocompleteMenuItem)}
                            onClick={() => handleChange(option)}
                        >
                            <ReactCountryFlag countryCode={option.iso2} svg className={cx(styles.flag)} />

                            <p>{option.dialCode} {option.name}</p>
                        </div>
                    ): <div className={cx(styles.autocompleteMenuItem)}>No data</div>}
                    </div>}
                {/*<Autocomplete*/}
                {/*    id="free-solo-demo"*/}
                {/*    freeSolo*/}
                {/*    ref={ref}*/}
                {/*    open={open}*/}
                {/*    value={value || ''}*/}
                {/*    name={'phone'}*/}
                {/*    disablePortal*/}
                {/*    onFocus={() => setFocus(true)}*/}
                {/*    autoComplete={true}*/}
                {/*    isOptionEqualToValue={(option, value) => option.dialCode === value }*/}
                {/*    getOptionLabel={(option) => option.dialCode || value}*/}
                {/*    onInputChange={(event, value) => handleChange(value)}*/}
                {/*    onChange={() => onChange()}*/}
                {/*    options={options}*/}
                {/*    selectOnFocus={true}*/}
                {/*    autoSelect={true}*/}
                {/*    renderOption={(props, option) => (*/}
                {/*        <Box component="li" {...props} key={Math.random()} style={option.iso2 === 'fr' ? { paddingBottom: '15px',*/}
                {/*            borderBottomLeftRadius: 0,*/}
                {/*            borderBottomRightRadius: 0,*/}
                {/*            borderBottom: '1px solid #C4C4C4', }: {}}>*/}
                {/*            <Flag code={option.iso2} className={cx(styles.flag)}/>*/}
                {/*            <p>{option.dialCode} {option.name}</p>*/}
                {/*        </Box>*/}
                {/*    )}*/}
                {/*    renderInput={(params) => <TextField*/}
                {/*        {...params}*/}
                {/*        InputProps={{ ...params.InputProps, ...startAdornment }}*/}
                {/*        onClick={() => setOpen(prevState => !prevState)}*/}
                {/*    />*/}
                {/*    }*/}
                {/*/>*/}
            </ThemeProvider>
            <InputField
                name={'number'}
                type={'number'}
                value={phoneNumber}
                onChange={onNumberChange}
                placeholder={'Mobile number'}
                className={'phoneNumber'}
            />
        </div>
    );
};

export default PhoneNumber;
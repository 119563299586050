import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import countryList from "react-select-country-list";
import {useFormik} from "formik";
import moment from "moment";

import {getCompanyMembers} from "../../../store/selectors";
import {
    checkIsFormDirty, clearForms, clearMembersData, getCompanyMemberDataPending,
    postCompanyMemberPending, updateCompanyMemberPending
} from "../../../store/actions";

import {createMemberValidation} from "../validation";
import {SelectField, DatePicker, InputField, Loading, Button} from "../../common";
import styles from "../../../assets/styles/business.module.scss";
import cx from 'classnames';

const CreateMember = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {memberId} = useParams();
    const location = useLocation();
    const [role, setRole] = useState('')
    const [allowShowModal, setAllowShowModal] = useState(false)
    const options = useMemo(() => countryList().getData(),[]);
    const {member, isLoading} = useSelector(getCompanyMembers)
    const [day, setDay] = useState(null)
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)

    useEffect(() => {
        if (memberId) {
            dispatch(getCompanyMemberDataPending(memberId))
            dispatch(clearForms())
        }
        return () => {
            dispatch(clearMembersData())
        }
    }, [dispatch, memberId])

    useEffect(() => {
        setRole(location.pathname.includes('owners')
            ? 'ubo' : location.pathname.includes('directors')
            ? 'director' : ''
        )
    },[location?.pathname, dispatch])

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            country: '',
            date_of_birth: '',
            city:'',
            // pep: false,
            address_line_1: '',
            address_line_2: '',
            post_code: '',
        },
        validationSchema: createMemberValidation,
        onSubmit: (values) => {
            dispatch(clearForms())
            const data = moment(formik.values.date_of_birth)
            const birth_day = data ? data.date(): null;
            const birth_month = data ? data.month(): null;
            const birth_year = data? data.year(): null;
            const date_of_birth = values?.date_of_birth ? moment(values.date_of_birth).format('YYYY-MM-DDThh:mm'): null
            if(memberId) {
                delete values.residential_address;
                delete values.roles;
                dispatch(updateCompanyMemberPending({...values, birth_month, birth_year, birth_day, date_of_birth, role}))
            }else {
                dispatch(postCompanyMemberPending({...values, date_of_birth, role}))
            }
            navigate(-1, { replace: true })
        },
    })

    useEffect(() => {
        if(member?.id){
            if(member.birth_day) setDay(member.birth_day);
            if(member.birth_month) setMonth(member.birth_month);
            if(member.birth_year) setYear(member.birth_year);

            const country  = member.country?.length === 2
                ? member.country
                : options.find(item => item.label === member.country)?.value;

            formik.setValues({
                ...member,
                country,
                date_of_birth: member?.date_of_birth || null
            })
        }
        // eslint-disable-next-line
    }, [member, options])

    const handleChange = useCallback((event) => {
        setAllowShowModal(true)
        formik.setValues(prevValue => ({...prevValue, [event.target.name]: event.target.value}))
    }, [formik])

    const handlePickerChange = useCallback((value) => {
        setAllowShowModal(true)
        formik.setValues(prevValue => ({...prevValue, date_of_birth: value}))
    }, [formik])

    const handleSelect = useCallback((name, value) => {
        setAllowShowModal(true)
        formik.setValues(prev => ({...prev, [name]: value}))
    }, [formik])

    useEffect(() => {
        if(formik.dirty && allowShowModal){
            dispatch(checkIsFormDirty(`business${role === 'ubo' ? 'Owner' : 'Director'}`))
        }
        // eslint-disable-next-line
    },[dispatch, role, allowShowModal])

    return (
        <div className={cx(styles.owner, styles.create)}>
            {isLoading && <Loading className={cx(styles.businessLoading)} />}
            <h3>{`${memberId ? 'Edit':'Add new'} business ${role === 'ubo' ? 'owner' : 'director'}`}</h3>
            <form onSubmit={formik.handleSubmit}>
                <InputField
                    name={'first_name'}
                    label={'First name'}
                    required={true}
                    value={formik.values.first_name}
                    onChange={handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                />
                <InputField
                    name={'last_name'}
                    label={'Last name'}
                    required={true}
                    value={formik.values.last_name}
                    onChange={handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                />
                <DatePicker
                    name={'date_of_birth'}
                    label={'Date of birth'}
                    onChange={handlePickerChange}
                    day={day}
                    month={month}
                    year={year}
                    // day={null}
                    // month={2}
                    // year={2000}
                    value={formik.values.date_of_birth}
                    error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                    helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                />
                <InputField
                    name={'address_line_1'}
                    label={'Address line 1'}
                    required={true}
                    value={formik.values.address_line_1}
                    onChange={handleChange}
                    error={formik.touched.address_line_1 && Boolean(formik.errors.address_line_1)}
                    helperText={formik.touched.address_line_1 && formik.errors.address_line_1}
                />
                <InputField
                    name={'address_line_2'}
                    label={'Address line 2'}
                    value={formik.values.address_line_2}
                    onChange={handleChange}
                    error={formik.touched.address_line_2 && Boolean(formik.errors.address_line_2)}
                    helperText={formik.touched.address_line_2 && formik.errors.address_line_2}
                />
                <InputField
                    name={'city'}
                    label={'City'}
                    required={true}
                    onChange={handleChange}
                    value={formik.values.city}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                />
                <InputField
                    name={'post_code'}
                    label={'Post code'}
                    required={true}
                    value={formik.values.post_code}
                    onChange={handleChange}
                    error={formik.touched.post_code && Boolean(formik.errors.post_code)}
                    helperText={formik.touched.post_code && formik.errors.post_code}
                />
                <SelectField
                    name={'country'}
                    label={'Country'}
                    value={formik.values.country}
                    onChange={handleSelect}
                    options={options}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                    className={'selectField'}
                />
                <div className={cx(styles.buttonGroups)}>
                    {/*<button className={cx(styles.outlinedButton)} onClick={() => navigate(-1)} type={'button'}>*/}
                    {/*    <p>Cancel</p>*/}
                    {/*</button>*/}
                    {/*<button className={cx(styles.button)} type="submit">*/}
                    {/*    <p>Save</p>*/}
                    {/*</button>*/}
                    <Button
                        text={'Cancel'}
                        variant={'outlined'}
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        text={'Save'}
                        onClick={formik.handleSubmit}
                    />
                </div>
            </form>
            
        </div>
    );
};

export default CreateMember;
import React  from "react";
import {Collapse, Grid} from "@mui/material";
import {Button, Icon} from "components/common";
import cx from "classnames";
import styles from "./collapsible-content.module.scss";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import ReactCountryFlag from "react-country-flag";
import { isProd } from "utils/helpers";
import { useSelector } from "react-redux";
import { getCurrencyData } from "store/selectors/currency";


/**
 * CollapsibleContent
 * @description Collapsible content component, used to display a collapsible content for Recipients Lists
 * @param {object} props
 * @returns {React.Component}
 */
const CollapsibleContent = (props) => {
    const { recipient, index, isOpen, onDelete, onEdit, onSendFunds } = props;

    const { currency } = useSelector(getCurrencyData);

    const getCountryCode = () => {
        return currency === 'GBP' ? 'GB': 'EU';
    }

    return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit key={index} className={cx(styles.collapsible)}>
            <Grid container className={'summary-list'} position={'relative'}>
                <Grid item sm={3} xs={12}>
                    <p className={cx(styles.label)}>Summary</p>
                    <p className={cx(styles.subtitle)}>
                        {recipient.summary}
                        <span style={{marginLeft: '10px'}}>
                            <ReactCountryFlag countryCode={getCountryCode()} svg />
                        </span>
                    </p>
                </Grid>
                {recipient.sort_code && <Grid item sm={3} xs={12}>
                    <p className={cx(styles.label)}>Sort code</p>
                    <p className={cx(styles.subtitle)}>
                        <NumberFormat value={recipient.sort_code} format="##-##-##" displayType={'text'} />
                    </p>
                </Grid>}
                {recipient.iban && <Grid item sm={3} xs={12}>
                    <p className={cx(styles.label)}>IBAN</p>
                    <p className={cx(styles.subtitle)} style={{textTransform: "uppercase"}}>
                        {recipient.iban}
                    </p>
                </Grid>}
                <Grid item sm={4} xs={12}>
                    <p className={cx(styles.label)}>Account holder name</p>
                    <p className={cx(styles.subtitle)}>{recipient.account_holder_name}</p>
                </Grid>
                <Grid item sm={2} xs={12} className={cx(styles['delete-button'])}>
                    <Icon name={'delete'} onClick={() => onDelete(recipient.id)}/>
                </Grid>
            </Grid>
            <Grid container className={'summary-list'}>
                <Grid item md={3} xs={12}>
                    <p className={cx(styles.label)}>Nickname</p>
                    <div>{ recipient.nickname
                        ? <div className={cx(styles.subtitle)}>
                            {recipient.nickname}
                            <Icon  name={'edit'} onClick={() => onEdit(recipient.id)} style={{ paddingLeft: '10px', }} />
                        </div>
                        : <p className={cx(styles.link)} onClick={() => onEdit(recipient.id)}>Add nickname</p>}
                    </div>
                </Grid>
                {recipient.account_number && <Grid item md={3} xs={12}>
                    <p className={cx(styles.label)}>Account number</p>
                    <p className={cx(styles.subtitle)} style={{textTransform: "uppercase"}}>{recipient.account_number}</p>
                </Grid>}
                {recipient.bic && <Grid item md={3} xs={12}>
                    <p className={cx(styles.label)}>BIC</p>
                    <p className={cx(styles.subtitle)} style={{textTransform: "uppercase"}}>{recipient.bic}</p>
                </Grid>}
                {!isProd &&
                    <Grid item md={3} xs={12}>
                        <div className={cx(styles.wrapper)}>
                            <Button
                                text="Send funds"
                                variant="outlined"
                                onClick={onSendFunds}
                            />
                        </div>
                    </Grid>
                }
            </Grid>
        </Collapse>
    )
}

export default CollapsibleContent;

CollapsibleContent.propTypes = {
    recipient: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired
}
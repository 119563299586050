import {CLEAR_USER_DATA, GET_USER_DATA_FAILURE, GET_USER_DATA_PENDING, GET_USER_DATA_SUCCESS} from "../actionTypes";

const initialState ={
    data:{},
    isLoading: false,
    error: null
}

const user = (state=initialState, action) => {
    switch (action.type) {
        case GET_USER_DATA_PENDING:
            return {
                ...state,
                isLoading: true,
            }

        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data
            }

        case GET_USER_DATA_FAILURE:
            return {
                ...state,
                data: {},
                isLoading: false,
                error: action.error
            }
        case CLEAR_USER_DATA:
            return {
                ...state,
                data: {},
                isLoading: false,
                error: null
            }

        default:
            return state;
    }
}

export default user;
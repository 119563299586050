export const addresses = [
    {
        label: 'It’s the same as the Registered address',
        value: 'registered',
        type: 'address_line',
    },
    {
        label: 'Enter a new trading address',
        value: 'traded',
        type: 'address_line',
    }
];

export const monthlyValues = [
    {label: 'GBP 0 - GBP 100,000', value: 'low'},
    {label: 'GBP 100,001 - GBP 500,000', value: 'mid'},
    {label: 'GBP 500,001 - GBP 1,000,000', value: 'high'},
    {label: 'GBP 1,000,000 or over', value: 'max'}
]

export const permissions = [
    {label: 'No', value: 'no'},
    {label: 'Yes', value: 'yes'},
    {label: 'In process of authorisation', value: 'in_process_of_authorisation'}
]
import {
    POST_MEMBERS_FAILURE,
    POST_MEMBERS_PENDING, POST_MEMBERS_SUCCESS

} from '../actionTypes';

const initialState = {
    data: [],
    isLoading: false,
    error: null
}

const members = (state = initialState, action) => {
    switch (action.type) {
        case POST_MEMBERS_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case POST_MEMBERS_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data
            };

        case POST_MEMBERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                data: [],
                error: action.message
            };

        default:
            return state;
    }
};

export default members;

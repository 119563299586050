import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "../../../assets/styles/business.module.scss";
import cx from 'classnames'
import useWindowSize from "../../../hooks/useWindowSize";

const SmsCode = ({setOtp, otpCode, startCount, message='', onChange, resend: parenResend, phoneNumber}) => {
    const input = useRef(null);
    const ref = useRef(null);

    const CODE_LENGTH = new Array(6).fill(0);
    const [value, setValue] = useState("")
    const values = value.split("")

    const [focus, setFocus] = useState(false)
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [counter, setCounter] = useState(30);

    const handleClick = useCallback(() => {
        if(input.current) {
            input.current.focus()
        }
    }, [input]);

    useEffect(() => {
        setValue(otpCode.slice(0, CODE_LENGTH.length));
    }, [otpCode, CODE_LENGTH.length])


    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = () => {
        setFocus(false);
    };

    const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;


    const handleChange = useCallback((e) => {
        const val = e.target.value;

        setValue((prevState) => {
            if (prevState.length > CODE_LENGTH.length) return null;
            return (prevState + val).slice(0, CODE_LENGTH.length)
        });
    }, [CODE_LENGTH]);

    const handleKeyUp = (e) => {
        if (e.key === "Backspace") {
            onChange(e.key)
            setValue(prevState => prevState.slice(0, prevState.length - 1))
        }
    };

    const hideInput = !(values.length < CODE_LENGTH.length);

    useEffect(() => {
        if(counter > 0 && startCount) {
            setTimeout(() =>
                setCounter(state => state - 1),
                1000);
        }
    }, [counter, startCount]);

    const size = useWindowSize();
    useEffect( () => {
        if(ref.current && size){
            setHeight(ref.current.offsetHeight)
            setWidth((ref.current.offsetWidth - 6*16) /6)
        }
    }, [ref, size]);

    useEffect(() => {setOtp(value)}, [value, setOtp]);

    const resend = useCallback(() => {
        parenResend()
        onChange()
        setValue('')
        if(phoneNumber === '12345678'){
            setTimeout(() => {
                setValue('123456')
            }, 1000)
        } else {
            setValue("")
        }
    }, [parenResend, onChange, phoneNumber])

    const handleResendCode = useCallback(() => {
        if(counter === 0) {
            setCounter(30)
            resend()
        }
    }, [counter, resend])


    return (
        <div className={cx(styles.app)}>
            <p>Enter the code you received via SMS</p>
            <div className={cx(styles.wrap)} onClick={handleClick} ref={ref}>
                <input
                    value=""
                    type={'number'}
                    ref={input}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={cx(styles.input)}
                    onKeyUp={handleKeyUp}
                    style={{
                        width,
                        height,
                        top: "0px",
                        bottom: "0px",
                        left: `${selectedIndex * (width + 16)}px`,
                        opacity: hideInput ? 0 : 1,
                        marginRight: '16px'
                    }}
                />
                {CODE_LENGTH.map((v, index) => {
                    const selected = values.length === index;
                    const filled =
                        values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                    return (
                        <div className={cx(styles.display, message === 'otp_not_verified' && styles.errorBorder,  (selected || filled) && focus && styles.border)} key={index}>
                            {values[index]}
                        </div>
                    );
                })}
            </div>
            {message === 'otp_not_verified' && <p className={styles.errorMessage}>Wrong code. Please try again or use resend code</p>}
            {message === 'otp_not_verified' && <h6><span style={{cursor: 'pointer'}} onClick={resend}> Resend</span></h6>}
            {(message === 'otp_verified' || message === '') && <h6>Didn't get the code?<span onClick={handleResendCode} style={{cursor: counter === 0 ? 'pointer' : 'not-allowed'}}>Resend</span> in: {counter}</h6>}
        </div>
    );
};

export default SmsCode;
import React from 'react';
import { getAccessToken, getOtp } from "../../utils/auth";
import {Navigate, Outlet} from 'react-router-dom'


const  PublicRoutes=(props) =>{
    const isOtpVerified = getOtp();
    const auth = getAccessToken();
    return (auth && isOtpVerified) ?
        <Navigate replace to="/dashboard/home" />
        :
        <Outlet/>
}

export default PublicRoutes;
import React, {useCallback, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import cx from "classnames";
import styles from "../transaction.module.scss";
import {Icon} from "../../../common";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {actions} from "../slice";
import {useDispatch, useSelector} from "react-redux";
import selectState from "../selectors";
import Skeleton from "@mui/material/Skeleton";
import { getCurrencyData } from 'store/selectors/currency';
import { EURAccount, GBPAccount, StyledAccountInfo } from 'components/dashboard/Dashboard';
import {useSearchParams} from "react-router-dom";

const TransactionInfo = ({loading, getTransactions}) => {
    const dispatch = useDispatch();
    const {account} = selectState();
    const {currency} = useSelector(getCurrencyData);
    const [searchParams] = useSearchParams();

    const [copy, setCopy] = useState({
        value:  '',
        copied: false,
    });
    const [balanceMessage, setBalanceMessage] = useState('');


    useEffect(() => {
        const timer = () => setTimeout(() => setCopy(val => ({...val, copied: false})), 4000);
        const timerId = timer();
        return () => {
            clearTimeout(timerId);
        };
    }, [copy.copied]);

    const handleRefresh = useCallback(() => {
        if(!loading) {
            dispatch(actions.getAccount({type:'main_account'}));
            getTransactions(searchParams.toString());
        }
    }, [dispatch, loading, getTransactions, searchParams]);

    useEffect(() => {
        dispatch(actions.getAccount({type:'main_account'}));
    }, [dispatch])

    useEffect(() => {
        if(account?.length === 0) {
            return;
        }
        switch(currency) {
            case 'GBP':
                setCopy(val => ({...val, value: `Sort code: ${account?.[0]?.sort_code || 'N/A'} Account number: ${account?.[0]?.account_number || 'N/A'}`}));
                setBalanceMessage(`The GBP balance as of now for account ${account?.[0]?.account_number || '-'}`);
                return;
            case 'EUR':
                setCopy(val => ({...val, value: `IBAN: ${account?.[0]?.iban || 'N/A'} BIC: ${account?.[0]?.bic || 'N/A'}`}));
                setBalanceMessage(`The EUR balance as of now for IBAN ${account?.[0]?.iban || '-'}`);
                return;
            default:
        }
    }, [account, currency]);

    const getIcon = () => {
        return currency === 'GBP' ? 'gb': 'eur';
    }

    const getCurrencySymbol = () => {
        return currency === 'GBP' ? '£' : '€';
    }

    return (
        <Grid container>
            <Grid item sm={6} className={cx(styles.balance)}>
                <div className={styles.refresh}>
                    <h3>Total balance</h3>
                    <Icon name={'refresh'} onClick={handleRefresh}/>
                </div>
                {account?.[0]
                    ? <h2>{getCurrencySymbol()}{account?.[0]?.balance}</h2>
                    : loading
                        ? <h2><Skeleton animation={"pulse"} width={'50%'} height={'100%'}/></h2>
                        : '0.00'
                }
                <h5><Icon name={getIcon()} width="20px"/> {balanceMessage} </h5>
            </Grid>
            <Grid item sm={5} className={cx(styles.copy)}>
                <StyledAccountInfo style={{'height': '60px'}}>
                    <div>
                        <CopyToClipboard text={copy.value}>
                            <Icon name={'colored-copy'} style={{float: 'right'}} onClick={() => setCopy(val => ({...val, copied: true}))} className={cx(styles.copyIcon)}/>
                        </CopyToClipboard>

                        { currency === 'GBP' && <GBPAccount accounts={account}/>}
                        { currency === 'EUR' && <EURAccount accounts={account}/>}
                        {copy.copied && <span className={cx(styles.copied)}>Copied!</span>}
                    </div>
                </StyledAccountInfo>
            </Grid>
        </Grid>
    );
};

export default TransactionInfo;
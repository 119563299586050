import {GET_USER_DATA_FAILURE, GET_USER_DATA_PENDING, GET_USER_DATA_SUCCESS, CLEAR_USER_DATA} from "../actionTypes";

export const getUserDataPending = (id) => ({
    type: GET_USER_DATA_PENDING,
    id
})

export const getUserDataSuccess = (data) => ({
    type: GET_USER_DATA_SUCCESS,
    data
})

export const getUserDataFailure = (error) => ({
    type: GET_USER_DATA_FAILURE,
    error
})

export const clearUserdata = () => ({
    type: CLEAR_USER_DATA
})

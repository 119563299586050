import styled from "styled-components";
import OTPInput from "otp-input-react";


export const StyledOtpWrapper = styled.div`
  text-align: center;
  h2 {
    font-family: Inter , 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin: 20px 0;
    @media screen and (max-width: 480px) {
      font-size: 18px !important;
      line-height: 24px !important;
      padding: 0 !important;
    }
  }
  div {
    margin: 0 auto;
    display: block !important;
    &.otp-input {
      margin-bottom: 20px;
      input {
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 24px;
        color: #111326;
        width: 40px !important;
        height: 40px !important;
        margin-right: 10px !important;
        
        @media (min-width: 644px) {
          width: 68px !important;
          height: 68px !important;
          margin-right: 20px !important;
        }
        
        @media screen and (max-width: 480px) {
          width: 62px !important;
          height: 62px !important;
          margin: 10px 9px !important;
        }
        
        &:focus {
          border: 1px solid #00A09B;
          outline: #00A09B;
        }
      }
    }
  } 
  .otp-resend {
    font-family: Inter , 'Open Sans', sans-serif;
    font-size: 16px;
    margin: 20px 0;
    
    .resend-button {
      color: #00A09B;
    }
  }`
;

export const StyledOtpInput = styled(OTPInput)(({
     error,
}) => ({
    ...error.status === 403 && {
        input: {
            border: "1px solid #FF8888 !important",
            outline: "#FF8888 !important",
            color: "#FF8888 !important",
        }
    },
}));

export const StyledOtpError = styled.div`
  font-family: Inter , 'Open Sans', sans-serif;
  font-size: 16px;
  color: #FF8888;
  margin-bottom: 16px !important;
`;

export const StyledOtpButtonWrapper = styled.div`
  padding: 0 10px;
  @media screen and (max-width: 480px) {
    padding: 0;
  }

  @media (min-width: 992px) {
    padding: 0 100px;
  }
  .otp-submit {
    @media screen and (max-width: 480px) {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
`;
import {call,  put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import {actions} from "./AppSlice";


export function* getFeatureFlags() {
    try {
        const response = yield call(request().get, Endpoints.featureFlags.feature.url);
        yield put(actions.getFeatureFlagsSuccess(response.data));
    } catch (error) {
        yield put(actions.getFeatureFlagsError(error));
    }
}

export function* getFeatureFlagsSaga() {
    yield takeLatest(actions.getFeatureFlag.type, getFeatureFlags);
}

import {
    CREATE_DOCUMENT_FAILURE,
    CREATE_DOCUMENT_PENDING,
    CREATE_DOCUMENT_SUCCESS
} from '../actionTypes';

const initialState = {
    data: [],
    isLoading: false,
    error: null
}

const documents = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_DOCUMENT_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case CREATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data
            };

        case CREATE_DOCUMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default documents;

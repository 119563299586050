import {call,  put, select, takeLatest } from 'redux-saga/effects';
import {
    actions
} from "./slice";
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import { getCurrencyData } from 'store/selectors/currency';

const { url } = Endpoints.recipients.recipient;
const { getRecipient } = Endpoints.recipients;

export function* getRecipients() {
    try {
      const {currency} = yield select(getCurrencyData);
      const getRecipients = yield call(request().get, getRecipient.url(currency));
       if(getRecipients) {
        yield put(actions.getRecipientsSuccess(getRecipients.data));
       }
    } catch (err) {
        yield put(
            actions.getRecipientsError(err.message
            ));
    }
}
/*
@payload {
    email: "user@example.com",
    account_holder_name: "string",
    nickname: "string",
    type: "string",
    account_number: "string",
    sort_code: "string"
}
 */
export function* addRecipient(action) {
    const { payload } = action;
    try {
        const createRecipient = yield call(request().post, url, payload);
        if(createRecipient) {
            yield put(actions.createRecipientsSuccess(createRecipient.data));
        }

    } catch (err) {
        yield put(
            actions.createRecipientsError(err.message
            ));
    }
}

export function* updateRecipient(action) {
    const { payload } = action;
    try {
        const deleteRecipient = yield call(request().patch, `${url}/${payload.recipient_id}`, {
           nickname: payload.nickname ? payload.nickname : '',
        });


        if(deleteRecipient) {
            yield put(actions.updateRecipientsSuccess(deleteRecipient.data));
            yield call(getRecipients);
        }

    } catch (err) {
        yield put(
            actions.updateRecipientsError(err.message
            ));
    }
}

export function* deleteRecipient(action) {
    const { payload } = action;
    try {
        const deleteRecipient = yield call(request().delete, `${url}/${payload.recipient_id}`);
        if(deleteRecipient) {
            yield put(actions.deleteRecipientsSuccess(deleteRecipient.data));
            yield call(getRecipients);
        }

    } catch (err) {
        yield put(
            actions.deleteRecipientsError(err.message)
        );
    }
}

export function* recipientsSaga() {
    yield takeLatest(actions.getRecipients.type, getRecipients);
    yield takeLatest(actions.createRecipients.type, addRecipient);
    yield takeLatest(actions.deleteRecipients.type, deleteRecipient);
    yield takeLatest(actions.updateRecipients.type, updateRecipient);
}



import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';

import Background from "../layouts/Background";
import { InputField, Loading, Messages } from "../common"

import { clearErrors, postLoginPending, postLoginOtp, setReCaptchaToken } from "../../store/actions";
import { getAuthData } from "../../store/selectors";
// import {  isLogged } from "../../utils/auth";
import { getOtpToken } from "../../utils/auth";

import styles from "../../assets/styles/auth.module.scss";
import cx from 'classnames';
import Otp from "../common/Otp";
import styled from 'styled-components';
import { clearState } from "../../store/reducers";
import ReCAPTCHA from "react-google-recaptcha";


const StyledOtpWrapper = styled.div`
  padding: 61px 38px;
  text-align: center;
  
  h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #111326;
  }
  p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #191C49;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  
`;


const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Please enter a valid email address'),
  password: yup
    .string('Enter your password')
    .required('Please enter your password')
});

const developKey = process.env.REACT_APP_SITE_KEY_DEV;
const testKey = process.env.REACT_APP_SITE_KEY_TEST;
const sandboxKey = process.env.REACT_APP_SITE_KEY_SANDBOX;
const stagingKey = process.env.REACT_APP_SITE_KEY_STAGING;
const productionKey = process.env.REACT_APP_SITE_KEY_PRODUCTION

let siteKey;
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'develop':
    siteKey = developKey
    break
  case 'test':
    siteKey = testKey
    break
  case 'sandbox':
    siteKey = sandboxKey
    break
  case 'staging':
    siteKey = stagingKey
    break
  case 'production':
    siteKey = productionKey
    break
  default:
    siteKey = developKey
}

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const loggedIn = isLogged();
  // const isGetOtp = getOtp()

  const recaptchaRef = React.useRef();

  const { isLoading, loginError } = useSelector(getAuthData)
  const otpToken = getOtpToken();

  const [toggleIcon, setToggleIcon] = useState('show-password')
  const [errorMessage, setErrorMessage] = useState('');
  const [OTP, setOTP] = React.useState("");
  const [isOtpError, setIsOtpError] = useState({
    messaege: '',
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: ''
  });

  const clearErrorsHandler = useCallback(() => {
    dispatch(clearErrors('regError'))
    dispatch(clearErrors('loginError'))
  }, [dispatch])

  useEffect(() => {
    dispatch(clearState())
  }, [dispatch])


  useEffect(() => {
    clearErrorsHandler()
  }, [clearErrorsHandler])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = await recaptchaRef.current.executeAsync();
      if (token) {
        dispatch(setReCaptchaToken(token))
        dispatch(postLoginPending(values));
        setData(values);
      } else {
        setErrorMessage('There is a reCAPTCHA error. Please try again.')
      }

    },
  })

  const handleChange = useCallback((event) => {
    formik.setValues(values => ({ ...values, [event.target.name]: event.target.value }))
    setErrorMessage('')
  }, [formik])

  useEffect(() => {
    if (loginError) {
      setErrorMessage('Invalid credentials. Please try again')
    }
  }, [loginError])

  const toggleShowPassword = useCallback(() => {
    setToggleIcon(toggleIcon === 'show-password' ? 'hide-password' : 'show-password')
  }, [toggleIcon])

  useEffect(() => {
    if (otpToken) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, [otpToken])


  const submitOTP = () => {
    if (OTP.length === 6 && otpToken) {
      dispatch(postLoginOtp({
        code: OTP,
        session_token: otpToken
      }))
    } else {
      setIsOtpError(true);
    }
  };

  const handleForgotPassword = useCallback(() => {
    navigate('/forgot-password')
    clearErrorsHandler()
  }, [navigate, clearErrorsHandler])

  const handleSignup = useCallback(() => {
    navigate('/sign-up')
    clearErrorsHandler()
  }, [navigate, clearErrorsHandler])

  const handleResend = useCallback(() => {
    setOTP('');
    dispatch(postLoginPending(data));
  }, [data, dispatch])

  return (
    <>
      <Background>
        <div className={cx(styles.auth)}>
          {isLoading && <Loading className={cx(styles.authLoading)} />}
          {!isAuthenticated ?
            <div className={cx(styles.authForm)}>
              <h3 className={cx(styles.title)}>Welcome to Merge</h3>
              <form className={cx(styles.form)} onSubmit={formik.handleSubmit}>
                <InputField
                  name={'email'}
                  label={'Email address'}
                  value={formik.values.email}
                  onChange={handleChange}
                  error={(formik.touched.email && Boolean(formik.errors.email)) || errorMessage}
                  helperText={formik.touched.email && formik.errors.email}
                  id={'email'}
                />
                <InputField
                  type={toggleIcon === 'show-password' ? 'password' : 'text'}
                  name={'password'}
                  label={'Password'}
                  value={formik.values.password}
                  onChange={handleChange}
                  handleIconClick={toggleShowPassword}
                  hasIcon={true}
                  iconPosition="end"
                  iconName={toggleIcon}
                  error={(formik.touched.password && Boolean(formik.errors.password)) || errorMessage}
                  helperText={formik.touched.password && formik.errors.password}
                  id={'password'}
                />
                <button className={cx(styles.submitBtn)} type="submit">
                  <p>Sign in</p>
                </button>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={siteKey}
                />
              </form>
              <p className={cx(styles.haveAccount)}>
                <button onClick={handleForgotPassword}>Forgot password?</button>
              </p>
              <p className={cx(styles.haveAccount)}>Don’t have an account? <button onClick={handleSignup}>Sign
                up</button></p>
            </div> :
            <StyledOtpWrapper id={'otp'}
            >
              <h2>
                Authenticate
              </h2>
              <p>
                Enter one-time password sent to the registered email
              </p>
              <Otp
                otp={OTP}
                onChange={setOTP}
                disabled={OTP.length !== 6}
                length={6}
                onSubmit={() => submitOTP()}
                onResend={handleResend}
                error={loginError || isOtpError}
                maxTime={45}
                isSecured={true}
              />
            </StyledOtpWrapper>
          }
        </div>
      </Background>
      {loginError && <Messages id="sign-in-error" text={"Sign in failed. Please retry."} variant={'error'} />}
    </>
  )
};

export default Login;
import React from 'react';
import styled from 'styled-components';
import {Grid} from "@mui/material";
import {Button} from "../common";
import {useNavigate} from "react-router-dom";

const StyledGrid = styled(Grid)`
  text-align: center;
`;

const NoFoundComponent = () => {
    const navigate = useNavigate();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <StyledGrid item xs={12} sm={6} md={6} mt={5}>
                <h1>404</h1>
                <p>Page not found</p>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid xs={12} md={4} sm={4}>
                        <Button
                            text="Take me to the homepage"
                            varinat="outlined"
                            size="lg"
                            margin={{top: '20px'}}
                            onClick={() => {
                                navigate('/')
                            }}

                        />
                    </Grid>
                </Grid>
            </StyledGrid>
        </Grid>
    );
};

export default NoFoundComponent;
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import {getCompanyMembers} from "../../store/selectors";
import {
    clearForms, deleteCompanyMemberPending, getCompanyMembersPending,
    updateCompanyMemberPending, clearMessage
} from "../../store/actions";

import {Button, Dialog, Loading} from "../common";
import MemberCard from "./members/MemberCard";

import styles from "../../assets/styles/business.module.scss";
import cx from 'classnames';

const Directors = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data, isLoading, message} = useSelector(getCompanyMembers)
    const [deleteModal, setDeleteModal] = useState({open: false})
    const [addMember, setAddMember] = useState(false)

    useOnClickOutside(ref, () => setAddMember(false));

    useEffect(() => {
        dispatch(clearForms())
        dispatch(clearMessage())
    }, [dispatch]);

    useEffect(() => {
        if(data?.length === 0) {
            dispatch(getCompanyMembersPending())
        }
    }, [dispatch, data.length]);

    const addRole = useCallback((id) => {
        dispatch(updateCompanyMemberPending({company_member_id: id,role: 'director'}))
    }, [dispatch])

    const openDeleteModal = useCallback((id, roles) => {
        const role = roles === 2 ? {role: 'director'} : {};
        setDeleteModal(prevState => ({...prevState, id, role, open: !prevState.open}))
    }, [])

    const handleDeleteAction = useCallback(() => {
        if(deleteModal?.id){
            dispatch(deleteCompanyMemberPending(deleteModal.id, deleteModal.role))
        }
    }, [deleteModal, dispatch])

    useEffect(() => {
        if(message === "Company member deleted"){
            dispatch(getCompanyMembersPending())
            setDeleteModal({open:false})
        }
        if(message === 'Company member updated') {
            setAddMember(false)
        }
    }, [message, dispatch])

    return (
        <>
            {isLoading && <Loading className={cx(styles.businessLoading)} />}
            <div className={cx(styles.director)}>
                <h3>Business Director</h3>
                <p>Due to regulations, we’re required to collect information about a company’s directors. Please list all individuals acting as directors of the company.</p>
                <p>One director will need to be selected to complete an identity verification process after submitting this application, in order to activate the Merge account.</p>
                {data?.map(director => director.roles.find(item => item.role === 'director') &&
                    <MemberCard
                        id={director.id}
                        key={director.id}
                        name={`${director.first_name} ${director.last_name}`}
                        roles={director.roles}
                        param={'directors'}
                        onDelete={openDeleteModal}
                    />
                )}
                <div className={cx(styles.addItem)}>
                    <div className={cx(styles.buttons)}>
                        <Button
                            text={'+ Add business director'}
                            variant='outlined'
                            size={'lg'}
                            margin={{top: '40px'}}
                            onClick={() => setAddMember(prevState => !prevState)}
                        />
                    </div>
                    {addMember && <div ref={ref} className={cx(styles.content)} >
                        <button className={cx(styles.add)} onClick={() => navigate('/business/directors/create')}>Add someone new +</button>
                        <h3>Select someone</h3>
                        {data?.map(director => director.roles.find(item => item.role === 'ubo') && director.roles?.length === 1 &&
                            <button
                                key={director.id}
                                className={cx(styles.fullName)}
                                onClick={()=> addRole(director.id)}
                            >
                                {`${director.first_name} ${director.last_name}`}
                            </button>
                        )}
                    </div>}
                </div>
                <div className={cx(styles.buttonGroups)}>
                    <Button
                        text={'Go back'}
                        variant='outlined'
                        // margin={{top: '40px'}}
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        text={'Save & continue'}
                        disabled={data?.length === 0}
                        // margin={{top: '40px'}}
                        onClick={() =>navigate('/business/info') }
                    />
                    {/*<button className={cx(styles.outlinedButton)} onClick={() => navigate(-1)}>*/}
                    {/*    <p>Go back</p>*/}
                    {/*</button>*/}
                    {/*<button className={cx(styles.button)} disabled={data?.length === 0} onClick={() =>navigate('/business/info') }>*/}
                    {/*    <p>Save & continue</p>*/}
                    {/*</button>*/}
                </div>
            </div>
            <Dialog
                open={deleteModal.open}
                setOpen={(val) => setDeleteModal({open: val})}
                className={'deleteModal'}
                cancelAction={() => setDeleteModal({open:false})}
                submitAction={handleDeleteAction}
            >
                <p>Are you sure you want to delete business director?</p>
            </Dialog>
        </>
    );
}

export default Directors;
import jwt from 'jwt-decode';
import store from 'store';


const initialState = {
    login: {
        isOtpVerified: false,
        user_id: '',
        company_id: '',
    },
    signup: {},
    isLoading: false,
    loginError: null,
    regError: null
}

export const isLogged = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { X_TOKEN } = login;
            if (X_TOKEN) {
                const nowToSeconds = Math.floor(Date.now() / 1000);
                const jwtToken = jwt(X_TOKEN);
                const {
                    exp: jwtExpire,
                } = jwtToken;

                if (
                    jwtExpire >= nowToSeconds
                ) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const getOtp = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { isOtpVerified } = login;
            if (isOtpVerified) {
                return true;
            }
        }
    }
    return false;
}

export const getAccessToken = () => {
    const state = store.getState();
    const login = state.auth.login;
    if (login) {
        const { X_TOKEN } = login;
        return X_TOKEN;
    }
};

export const getCurrentUser = () => {
    const state = store ? store.getState() : initialState;
    const login = state.auth.login;
    if (login) {
        return login;
    }
};

export const getCompany = () => {
    const state = store ? store.getState() : initialState;
    const company = state?.companyData;
    if (company) {
        return company;
    }
};

export const getOtpToken = () => {
    const state = store.getState();
    const login = state.auth.login;
    if (login) {
        const { token } = login;
        return token;
    }
};

export const getReCaptchaToken = () => {
    const state = store.getState();
    const login = state.auth.login;

    if (login) {
        const { x_recaptcha_token } = login;
        return x_recaptcha_token;
    }
}
import React from 'react';
import PropTypes from 'prop-types';

const FeatureFlag = ({ children, enabled, name }) => {
  if (enabled) {
    return <>{children}</>;
  }
  return (<div style={{height: '100vh', textAlign: "center"}}>
            <h2>{name} is coming soon!</h2>
          </div>
        );
};

export default FeatureFlag;

FeatureFlag.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
import {
    GET_INDUSTRY_TYPE_FAILURE, GET_INDUSTRY_TYPE_PENDING, GET_INDUSTRY_TYPE_SUCCESS
} from '../actionTypes';

const initialState = {
    data: [],
    isLoading: false,
    error: null
}

const industryType = (state = initialState, action) => {
    switch (action.type) {
        case GET_INDUSTRY_TYPE_PENDING:
            return {
                ...state,
                error: null,
                isLoading: true,
            };

        case GET_INDUSTRY_TYPE_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                data: action.data
            };

        case GET_INDUSTRY_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default industryType;

import {call,  put, takeLatest } from 'redux-saga/effects';

import {getUserDataFailure, getUserDataSuccess} from '../actions';
import {GET_USER_DATA_PENDING} from '../actionTypes';
import { request } from '../../utils/api';

function* getUserDataWorker({id}) {
    try {
        const {
            data,
        } = yield call(
            request().get,
            `user-management/user-profile/${id}`,
        );
        yield put(getUserDataSuccess(data))
    } catch (error) {
        yield put(getUserDataFailure(error));
    }
}

export function* getUserData(){
    yield takeLatest(GET_USER_DATA_PENDING, getUserDataWorker);
}


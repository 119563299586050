import React, {useEffect, lazy } from "react";
import { Route, Routes} from "react-router-dom";
import FeatureFlag from "./components/common/FeatureFlag";
import selectState from "./AppSelectors";
import { useSelector } from "react-redux";

import NoFoundComponent from "components/NoFoundComponent";

import Registration from './components/auth/Registration';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import Dashboard from './components/dashboard/Dashboard';
import Recipients from './components/dashboard/recipients/Recipients';


// import Accounts from './components/dashboard/account/Accounts';
import Transactions from './components/dashboard/transactions/Transactions';
import Balance from './components/dashboard/balance/Balance';
import Settings from './components/dashboard/settings/Settings';
import Profile from './components/dashboard/profile/Profile';
import Funds from './components/dashboard/funds';
import LegalInfo from './components/business/LegalInfo';
import Summary from './components/business/Summary';

import Country from './components/dashboard/Country';
import Owners from './components/business/Owners';
import Directors from './components/business/Directors';
import Info from './components/business/BusinessInfo';
import CreateMember from './components/business/members/CreateMember';
import Identity from './components/business/kyc/Identity';

import ProtectedRoutes from "./routes/ProtectedRoutes";
import PublicRoutes from "./routes/PublicRoute";
import SubTransactions from "./components/dashboard/account/components/transactions/SubTransactions";
import SubAccounts from './components/dashboard/account/components/accounts/SubAccounts';
// import {isLogged} from "./utils/auth";
import TermsAndConditions from "./components/conditions/TermsAndConditions";
import TermsOfUse from "./components/conditions/TermsOfUse";
import PrivacyPolicyPage from "./components/conditions/PrivacyPolicyPage";
// import ContentLayout from "./components/layouts/content/Main/ContentLayout";
import ContentLayout from "./components/layouts/ContentLayout";
import TermsAndConditionsModulrUK from "./components/conditions/TermsAndConditionsModulrUK";
import { clearState } from "./store/reducers";
import { isLogged } from "./utils/auth";
import { useDispatch} from "react-redux";

const DashboardLayout = lazy(() => import("./components/layouts/DashboardLayout"));
const BusinessLayout = lazy(() => import("./components/layouts/BusinessLayout"));
const BrandLayout = lazy(() => import("./components/layouts/BrandLayout"));
const EmptyLayout = lazy(() => import("./components/layouts/EmptyLayout"));

const App = () => {
    const dispatch = useDispatch();
    const {
        featureFlags: {
            recipient_enabled,
            send_funds_enabled,
            transaction_enabled,
            main_account_enabled
        },
    } = useSelector(selectState);

    const { featureFlags } = useSelector(selectState);

    useEffect(() => {
        localStorage.setItem('envName', process.env.REACT_APP_ENVIRONMENT);
    }, []);

    useEffect(() => {
        if(!isLogged()) {
            dispatch(clearState());
        }
    },[dispatch])
    
    const publicRoutes = [
        {id:1, path: 'sign-up', component: <EmptyLayout><Registration/></EmptyLayout>, layoutComponentName: 'EmptyLayout' },
        {id:2, path: 'login', component: <EmptyLayout> <Login/></EmptyLayout>,  layoutComponentName: 'EmptyLayout' },
        {id:3, path: 'forgot-password', component: <EmptyLayout> <ForgotPassword/></EmptyLayout>, layoutComponentName: 'EmptyLayout'  },
        {id:29, path: 'terms-of-use', component: <ContentLayout><TermsOfUse /></ContentLayout>, layoutComponentName: 'ContentLayout' },
        {id:30, path: 'privacy-policy', component: <ContentLayout><PrivacyPolicyPage /></ContentLayout>, layoutComponentName: 'ContentLayout' },
    ];

    // @TODO: move to separate file
    const privateRoutes = [

        {id:4, path: 'home', component:<Dashboard/>, layoutComponentName: 'DashboardLayout' },
        // {id:5, path: 'sub-accounts/dashboard', component: <Accounts/>, layoutComponentName: 'DashboardLayout' },
        {id:6, path: 'transactions', component: <FeatureFlag enabled={transaction_enabled && transaction_enabled } name="Transactions"><Transactions/></FeatureFlag>, layoutComponentName: 'DashboardLayout' },
        {id:7, path: 'recipients', component: <FeatureFlag enabled={recipient_enabled && recipient_enabled} name="Recipients"><Recipients/></FeatureFlag>, layoutComponentName: 'DashboardLayout' },
        {id:8, path: 'balance', component: <Balance/>, layoutComponentName: 'DashboardLayout' },
        {id:9, path: 'funds', component: <FeatureFlag enabled={send_funds_enabled && send_funds_enabled} name="Send Funds"><Funds/></FeatureFlag>, layoutComponentName: 'DashboardLayout' },
        {id:10, path: 'settings', component: <Settings/>, layoutComponentName: 'DashboardLayout' },
        {id:11, path: 'profile', component: <Profile/>, layoutComponentName: 'DashboardLayout' },
        // {id:12, path: 'sub-accounts/dashboard', component: <SubDashboard/>, layoutComponentName: 'DashboardLayout' },
        {id:13, path: `sub-accounts/:accountId/transactions`, component: <FeatureFlag enabled={main_account_enabled && main_account_enabled} name="Sub accounts transactions"><SubTransactions/></FeatureFlag>, layoutComponentName: 'DashboardLayout' },
        {id:14, path: 'sub-accounts', component: <FeatureFlag enabled={main_account_enabled && main_account_enabled} name="Sub accounts list"><SubAccounts/></FeatureFlag>, layoutComponentName: 'DashboardLayout' },
    ];

    const businessRoutes = [
        {id:15, path: 'country', component: <BrandLayout><Country/></BrandLayout>, layoutComponentName: 'BrandLayout' },
        {id:16, path: 'legal-info', component: <BusinessLayout><LegalInfo/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:17, path: 'owners', component: <BusinessLayout><Owners/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:18, path: 'directors', component: <BusinessLayout><Directors/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:19, path: 'info', component: <BusinessLayout><Info/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:20, path: 'owners/create', component: <BusinessLayout><CreateMember/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:21, path: 'directors/create', component: <BusinessLayout><CreateMember/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:22, path: 'owners/:memberId', component: <BusinessLayout><CreateMember/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:23, path: 'directors/:memberId', component: <BusinessLayout><CreateMember/></BusinessLayout>, layoutComponentName: 'BusinessLayout' },
        {id:26, path: 'summary', component:<BusinessLayout><Summary/></BusinessLayout>, layoutComponentName: 'BusinessLayout' }

    ];

    const userRoutes = [
        {id:24, path: 'set-password/:userId/:token', component: <BrandLayout><ResetPassword/></BrandLayout>, layoutComponentName: 'BrandLayout' },
        {id:25, path: 'kyc/:userId/:token', component: <BrandLayout><Identity/></BrandLayout>, layoutComponentName: 'BrandLayout' },
        {id:27, path: 'terms-and-conditions', component: <ContentLayout><TermsAndConditions/></ContentLayout>, layoutComponentName: 'ContentLayout' },
        {id:28, path: 'terms-and-conditions-modulr-uk', component: <ContentLayout><TermsAndConditionsModulrUK/></ContentLayout>, layoutComponentName: 'ContentLayout' },
    ]

    return (
        <Routes>
            <Route path="/dashboard" element={<DashboardLayout featureFlags={featureFlags}><ProtectedRoutes /></DashboardLayout>}>
                {privateRoutes.map(({path, id, component, layoutComponentName}) => {
                    return <Route
                        exact
                        path={path}
                        key={id}
                        element={component}
                        layoutComponentName={layoutComponentName}
                    />
                })}
            </Route>
            <Route path="/business" element={<ProtectedRoutes />}>
                {businessRoutes.map(({path, id, component, layoutComponentName}) => {
                    return <Route
                        exact
                        path={path}
                        key={id}
                        element={component}
                        layoutComponentName={layoutComponentName}
                    />
                })}
            </Route>
            <Route path="/" element={<ProtectedRoutes />}>
                {userRoutes.map(({path, id, component, layoutComponentName}) => {
                    return <Route
                        exact
                        path={path}
                        key={id}
                        element={component}
                        layoutComponentName={layoutComponentName}
                    />
                })}
            </Route>
            <Route path="/" element={<PublicRoutes />}>
                {publicRoutes.map(({path, id, component, layoutComponentName}) => {
                    return <Route
                        exact
                        path={path}
                        key={id}
                        element={component}
                        layoutComponentName={layoutComponentName}
                    />
                })}
            </Route>

            <Route path='*' element={<NoFoundComponent />} />
        </Routes>
    );
}
export default App;

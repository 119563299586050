import {call,  put, takeLatest } from 'redux-saga/effects';
import {
    checkRegistrationNumberFailure,
    checkRegistrationNumberSuccess,
    getCompaniesFailure, getCompaniesSuccess,
    getCompanyDataFailure, getCompanyDataSuccess,
    getCompanyTypesFailure, getCompanyTypesSuccess,
    getFundSourcesFailure, getFundSourcesSuccess,
    getIndustryTypeFailure, getIndustryTypeSuccess,
    postMembersFailure, postMembersSuccess,
    updateCompanyFailure, updateCompanySuccess
} from '../actions';
import {
    CHECK_REGISTRATION_NUMBER_PENDING,
    GET_COMPANIES_PENDING, GET_COMPANY_DATA_PENDING,
    GET_COMPANY_TYPES_PENDING, GET_FUND_SOURCES_PENDING, GET_INDUSTRY_TYPE_PENDING,
    POST_MEMBERS_PENDING, UPDATE_COMPANY_PENDING
} from '../actionTypes';
import { request } from '../../utils/api';
import {getCompany, getCurrentUser} from "utils/auth";


function* postMembersWorker({company_number}) {
    // const currentUser = getCurrentUser();
    // const company_id = currentUser.company_id;
    const company = getCompany();
    const company_id = company?.data?.id;
    try {
        const {
            data,
        } = yield call(
            request().post,
            `kyb-vendor/get-directors`,
            {company_id, company_number}
        );
        yield put(postMembersSuccess(data))
    } catch (error) {
        const {data, status} = error.response;
        yield put(postMembersFailure(data?.message, status));
    }
}

export function* postMembers(){
    yield takeLatest(POST_MEMBERS_PENDING, postMembersWorker);
}


function* getCompanyTypesWorker() {
    try {
        const {
            data,
        } = yield call(
            request().get,
            `business-information/company-type`,
        );
        const companyTypes = data?.items.map(item => ({value: item.id, label: item.name, shortName: item.alias }) );
        yield put(getCompanyTypesSuccess(companyTypes))
    } catch (error) {
        yield put(getCompanyTypesFailure(error));
    }
}

export function* getCompanyTypes(){
    yield takeLatest(GET_COMPANY_TYPES_PENDING, getCompanyTypesWorker);
}


function* getCompanyDataWorker() {
    try {
        const {
            data
        } = yield call(
            request().get,
            'business-information/company',
        );
        yield put(getCompanyDataSuccess(data))
    } catch (error) {
        const {data, status } = error?.response;
        yield put(getCompanyDataFailure(data?.message, status));
    }
}

export function* getCompanyData(){
    yield takeLatest(GET_COMPANY_DATA_PENDING, getCompanyDataWorker);
}



function* updateCompanyWorker({option}) {
    const currentUser = getCurrentUser();
    const company = getCompany();
    const company_id = company?.data?.id;
    const user_id = currentUser?.user_id ? currentUser?.user_id : currentUser?.user.id;
    try {
        const {
            data,
        } = yield call(
            request().patch,
            `business-information/company/${user_id}/${company_id}`,
            {...option, company_id}
        );
        yield put(updateCompanySuccess(data))
    } catch (error) {
        const {data, status } = error.response;
        yield put(updateCompanyFailure(data?.message, status));
    }
}

export function* updateCompany(){
    yield takeLatest(UPDATE_COMPANY_PENDING, updateCompanyWorker);
}


function* getIndustryTypeWorker() {
    try {
        const {
            data,
        } = yield call(
            request().get,
            `business-information/industry`,
        );
        const industryTypes = data?.items.map(item => ({value: item.id, label: item.name}))
        yield put(getIndustryTypeSuccess(industryTypes))
    } catch (error) {
        yield put(getIndustryTypeFailure(error));
    }
}

export function* getIndustryType(){
    yield takeLatest(GET_INDUSTRY_TYPE_PENDING, getIndustryTypeWorker);
}


function* getFundSourcesWorker() {
    try {
        const {
            data,
        } = yield call(
            request().get,
            `business-information/fund-source`,
        );
        const sources = data?.items.map(item => ({value: item.id, label: item.name}))
        yield put(getFundSourcesSuccess(sources))
    } catch (error) {
        yield put(getFundSourcesFailure(error));
    }
}

export function* getFundSources(){
    yield takeLatest(GET_FUND_SOURCES_PENDING, getFundSourcesWorker);
}


function* getCompaniesWorker({option}) {
    let query = option?.query.replace(/\s/g, '+');
    // const lt = query.charAt(query.length - 1);
    // if(lt === '-'){
    //     query = query.slice(0, -1);
    // }
    try {
        const {
            data,
        } = yield call(
            request().get,
            `/kyb-vendor/companies?q=${query}&start_index=${option.start_index || 0}`,
        );
        yield put(getCompaniesSuccess(data))
    } catch (error) {
        yield put(getCompaniesFailure(error));
    }
}

export function* getCompanies(){
    yield takeLatest(GET_COMPANIES_PENDING, getCompaniesWorker);
}


function* checkRegistrationNumberWorker({option}) {
    try {
        const {
            data,
        } = yield call(
            request().post,
            `business-information/check-registration-number`,
            option
        );
        const message = data?.exists === true ? 'already_registered' : 'not_registered';
        yield put(checkRegistrationNumberSuccess({message}))
    } catch (error) {
        const {data, status } = error.response;
        yield put(checkRegistrationNumberFailure(data?.message, status));
    }
}

export function* checkRegistrationNumber(){
    yield takeLatest(CHECK_REGISTRATION_NUMBER_PENDING, checkRegistrationNumberWorker);
}

